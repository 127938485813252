<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-request-success-container">
      <div class="gp-logo"><img :src="gpLogo" /></div>
      <div class="info-container">
        <p>
          <strong>Payment for Reference No:</strong>
          {{ policyInfo.reference_no }}
        </p>
        <p><strong>Quotation No:</strong> {{ policyInfo.quotation_no }}</p>
      </div>

      <div class="info-container" v-if="documents.length < 1">
        <p><strong>Your motor insurance is under process.</strong></p>
        <p>
          Kindly contact our customer support at {{ customerSupportContactNo }}
        </p>
        <p>if policy did not receive after 15 minutes.</p>
      </div>

      <div class="info-container" v-else>
        <p>
          <strong>Your motor insurance has been successfully renewed.</strong>
        </p>
        <p>
          Kindly contact our customer support at {{ customerSupportContactNo }}
        </p>
        <p>if you have any enquiry.</p>
      </div>

      <!-- Document list -->
      <div class="align-self--center" v-if="documents.length > 0">
        <p class="text-bold text-g200">Documents</p>
        <div
          class="button-container"
          v-for="doc in documents"
          :key="`document-${doc.id}`"
        >
          <v-ons-button
            modifier="gp"
            class="button--gp secondary"
            @click="download(doc.id)"
          >
            {{ doc.filename }}
          </v-ons-button>
        </div>
        <p class="text-r100">
          * Document password will be first 6 digit of your
          NRIC/Passport/Company No.
        </p>
      </div>

      <!-- Quotation -->
      <div>
        <quote-board
          :vendor-premium="premium"
          :addons="policyInfo.addons"
          :total-payable="policyInfo.total_payable"
        />
        <insured-details
          :policyHolderInfo="policyHolderInfo"
          :policyInfo="policyInfo"
          :vehicleInfo="vehicleInfo"
        />
        <addon-details :addons="addons" />
      </div>
      <!-- End Quotation -->
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import QuoteBoard from '@/components/QuoteBoard.vue';
import InsuredDetails from '@/components/InsuredDetails.vue';
import AddonDetails from '@/components/AddonDetails.vue';

// Utilities and config
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  GONET_LOGO,
  GP_LOGO,
  TIMB_LOGO,
  ANDROID_LOGO_SRC,
  ANDROID_LOGO_CTA,
  IOS_LOGO_SRC,
  IOS_LOGO_CTA,
  CUSTOMER_SUPPORT_CONTACT_NO,
} from '@/lib/constants';

export default {
  props: ['pageStack'],
  components: {
    PageToolbar,
    QuoteBoard,
    InsuredDetails,
    AddonDetails,
  },
  data() {
    return {
      copyErrored: false,
      copySuccess: false,
      premium: {},
      policyId: null,
      gpLogo: GP_LOGO,
      underwrittenLogo: TIMB_LOGO,
      gonetLogo: GONET_LOGO,
      androidLogo: ANDROID_LOGO_SRC,
      androidCta: ANDROID_LOGO_CTA,
      iosLogo: IOS_LOGO_SRC,
      iosCta: IOS_LOGO_CTA,
      customerSupportContactNo: CUSTOMER_SUPPORT_CONTACT_NO,
    };
  },
  async mounted() {
    const { setPremium } = this;
    setPremium();
  },
  watch: {
    policyInfo() {
      this.setPremium();
      //this.setPaymentStatus();
    },
  },
  methods: {
    ...mapMutations({
      resetPolicy: 'policy/reset',
      commitPolicyInfo: 'policy/policyInfo',
    }),

    ...mapActions({
      loadPolicy: 'policy/loadData',
    }),

    async handlePageShow() {
      const { policyId, resetPolicy, commitPolicyInfo, loadPolicy } = this;

      // Load policy
      if (policyId) {
        await resetPolicy(); // reset data first
        await commitPolicyInfo({
          id: policyId,
        });
        await loadPolicy(); // then load policy
      }
    },

    setPremium() {
      const { policyInfo, premiumAmounts } = this;
      this.premium = premiumAmounts.find(
        pa => pa.provider_code === policyInfo.provider_code
      );
    },

    async retrieveDocuments() {
      const { $store, $ons } = this;
      if (this.documents.length <= 0) {
        await $store.dispatch('policy/retrieveDocuments');
        if (this.documents.length <= 0) {
          $ons.notification.toast(
            `Documents not ready yet, please try again later.`,
            { modifier: 'error', timeout: 3000 }
          );
        } else {
          $ons.notification.toast(
            `Documents retrieved successfully, please select a document to download`,
            { modifier: 'success', timeout: 3000 }
          );
        }
      }
    },

    async download(document_id) {
      const { $store } = this;
      let doc = this.documents.find(d => d.id === document_id);
      if (!doc.url) {
        await $store.dispatch('policy/download', { document_id });
      }
      doc = this.documents.find(d => d.id === document_id);
      window.open(doc.url, '_blank');
    },
  },
  computed: {
    ...mapState({
      addons: state => state.policy.addons,
      policyInfo: state => state.policy.policyInfo,
      policyHolderInfo: state => state.policy.policyHolderInfo,
      vehicleInfo: state => state.policy.vehicleInfo,
      premiumAmounts: state => state.policy.premiumAmounts,
      documents: state => state.policy.policyInfo.documents,
    }),
  },
};
</script>
