<template>
  <div class="gp-quoteboard">
    <div
      class="gp-quoteboard__category"
      v-if="motorExtraBenefits && motorExtraBenefits.length > 0"
    >
      <p class="gp-quoteboard__header">MOTOR &amp; EXTRA BENEFIT</p>
      <ul class="gp-quoteboard__list">
        <li
          class="gp-quoteboard__list-item list-item"
          v-for="(item, i) in motorExtraBenefits"
          :key="`motor-extra-benefits-${i}`"
        >
          <div class="list-item__inner">
            <div class="list-item__title">
              <div>
                {{ item.label }}
                <span v-if="item.amountPerc"
                  >({{ item.amountPerc.toFixed(2) }}%)</span
                >
              </div>
              <div>:</div>
            </div>
            <div class="list-item__figure">
              {{ item.amount.toFixed(2) }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="gp-quoteboard__category" v-if="mpa && mpa.length > 0">
      <p class="gp-quoteboard__header">MOTORIST PERSONAL ACCIDENT</p>
      <ul class="gp-quoteboard__list">
        <li
          class="gp-quoteboard__list-item list-item"
          v-for="(item, i) in mpa"
          :key="`motor-extra-benefits-${i}`"
        >
          <div class="list-item__inner">
            <div class="list-item__title">
              <div>
                {{ item.label }}
                <span v-if="item.amountPerc"
                  >({{ item.amountPerc.toFixed(2) }}%)</span
                >
              </div>
              <div>:</div>
            </div>
            <div class="list-item__figure">
              {{ item.amount.toFixed(2) }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="gp-quoteboard__category" v-if="addons && addons.length > 0">
      <p class="gp-quoteboard__header">ADD ON SERVICES</p>
      <span v-for="addon in addons" :key="`addon-${addon.code}`">
        <ul class="gp-quoteboard__list">
          <li
            class="gp-quoteboard__list-item list-item"
            v-for="fee in addon.fees"
            :key="`addon-fee-${fee.code}`"
          >
            <div class="list-item__inner">
              <div class="list-item__title">
                <div>{{ fee.name }}</div>
                <div>:</div>
              </div>
              <div class="list-item__figure">
                {{ fee.amount.toFixed(2) }}
              </div>
            </div>
          </li>
        </ul>
      </span>
    </div>

    <div class="gp-quoteboard__category">
      <ul class="gp-quoteboard__list">
        <li class="gp-quoteboard__list-item list-item">
          <div class="list-item__inner">
            <div class="list-item__title">
              <div>Total Amount Payable</div>
              <div>:</div>
            </div>
            <div class="list-item__figure list-item--text-emphasized">
              {{ totalPayable && totalPayable.toFixed(2) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    vendorPremium: Object,
    totalPayable: Number,
    addons: Array,
  },
  data() {
    return {
      motorExtraBenefits: [],
      mpa: [],
    };
  },
  mounted() {
    const { transformVendorPremium } = this;
    transformVendorPremium();
  },
  watch: {
    vendorPremium() {
      const { transformVendorPremium } = this;
      transformVendorPremium();
    },
  },
  methods: {
    transformVendorPremium() {
      const { vendorPremium } = this;
      if (!vendorPremium) {
        return;
      }

      // Calculate mpa premium
      const grossMpa = vendorPremium.mpa_premium / 1.06;
      this.mpa = [
        {
          label: 'MPA Premium',
          amount: _.has(vendorPremium, 'mpa_premium') ? grossMpa : 0,
          amountPerc: null,
        },
        {
          label: '+ GST',
          amount: 0,
          amountPerc: 0,
        },
        {
          label: '+ Stamp Duty',
          amount: 0,
          amountPerc: null,
        },
        {
          label: '+ SST',
          amount: _.has(vendorPremium, 'mpa_premium') ? grossMpa * 0.06 : 0,
          amountPerc: 6,
        },
      ];

      // Calculate motor and extra benefits premium
      const ncd = _.has(vendorPremium, 'ncd') ? vendorPremium.ncd : 0;
      const basicPremium = _.has(vendorPremium, 'basic_premium')
        ? vendorPremium.basic_premium
        : 0;
      const gstAmount = _.has(vendorPremium, 'gst_amount')
        ? vendorPremium.gst_amount
        : 0;
      const sstAmount = _.has(vendorPremium, 'sst_amount')
        ? vendorPremium.sst_amount
        : 0;
      const stampDuty = _.has(vendorPremium, 'stamp_duty')
        ? vendorPremium.stamp_duty
        : 0;
      const grossPremium = basicPremium - ncd;
      this.motorExtraBenefits = [
        {
          label: 'Motor Basic Premium',
          amount: basicPremium || 0,
          amountPerc: null,
        },
        {
          label: '- NCD',
          amount: ncd,
          amountPerc: (ncd / basicPremium) * 100,
        },
        {
          label: 'Extra Benefit Premium',
          amount: _.has(vendorPremium, 'extra_benefit_premium')
            ? vendorPremium.extra_benefit_premium
            : 0,
          amountPerc: null,
        },
        {
          label: 'Gross Premium',
          amount: grossPremium,
          amountPerc: null,
        },
        {
          label: '+ GST',
          amount: gstAmount,
          amountPerc: (gstAmount / grossPremium) * 100,
        },
        {
          label: '+ Stamp Duty',
          amount: stampDuty,
          amountPerc: null,
        },
        {
          label: '+ SST',
          amount: sstAmount,
          amountPerc: (sstAmount / grossPremium) * 100,
        },
      ]; // End this.motorExtraBenefits
    },
  },
};
</script>
