// Utilities
//import _ from 'lodash';

// Services
import ShortcodeService from '@/services/shortcodes';

const defaultState = () => ({
  isLoaded: false,
  loading: [],
  categories: {
    vehicle_assembler: [],
    unit_type: [],
    coverage_type: [],
    car_body: [],
    vehicle_class: [],
    vehicle_condition: [],
    car_type: [],
    car_fuel: [],
    hire_purchase_company: [],
    state: [],
    safety_feature: [],
    anti_theft_feature: [],
    permitted_driver: [],
    gender: [],
    customer_title: [],
    marital_status: [],
    ethnic: [],
    religion: [],
    occupation: [],
    car_make: [],
    car_model: [],
    relationship: [],
  },
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    isLoaded: (state, data) => (state.isLoaded = data),
    loading: (state, data) => (state.loading = data),
    switchLoading(state, loadingLabel) {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    vehicle_assembler: (state, data) =>
      (state.categories.vehicle_assembler = data),
    unit_type: (state, data) => (state.categories.unit_type = data),
    coverage_type: (state, data) => (state.categories.coverage_type = data),
    car_body: (state, data) => (state.categories.car_body = data),
    vehicle_class: (state, data) => (state.categories.vehicle_class = data),
    vehicle_condition: (state, data) =>
      (state.categories.vehicle_condition = data),
    car_type: (state, data) => (state.categories.car_type = data),
    car_fuel: (state, data) => (state.categories.car_fuel = data),
    hire_purchase_company: (state, data) =>
      (state.categories.hire_purchase_company = data),
    state: (state, data) => (state.categories.state = data),
    safety_feature: (state, data) => (state.categories.safety_feature = data),
    anti_theft_feature: (state, data) =>
      (state.categories.anti_theft_feature = data),
    permitted_driver: (state, data) =>
      (state.categories.permitted_driver = data),
    gender: (state, data) => (state.categories.gender = data),
    customer_title: (state, data) => (state.categories.customer_title = data),
    marital_status: (state, data) => (state.categories.marital_status = data),
    ethnic: (state, data) => (state.categories.ethnic = data),
    religion: (state, data) => (state.categories.religion = data),
    occupation: (state, data) => (state.categories.occupation = data),
    car_make: (state, data) => (state.categories.car_make = data),
    car_model: (state, data) => (state.categories.car_model = data),
    relationship: (state, data) => (state.categories.relationship = data),
    garage: (state, data) => (state.categories.garage = data),
  },
  actions: {
    async load({ commit, state }) {
      if (!state.isLoaded) {
        commit('loading', [
          'anti theft features',
          'vehicle bodies',
          'fuel types',
          'vehicle makes',
          'vehicle models',
          'vehicle types',
          'coverage types',
          'customer titles',
          'ethnics',
          'garage',
          'genders',
          'hire purchase companies',
          'marital status',
          'occupations',
          'permitted driver policies',
          'relationships',
          'religions',
          'safety features',
          'states',
          'unit types',
          'vehicle assemblers',
          'vehicle classes',
          'vehicle conditions',
        ]);
        Promise.all([
          ShortcodeService.getVehicleClassList()
            .then(res => commit('vehicle_class', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle classes')),

          ShortcodeService.getVehicleAssemblerList()
            .then(res => commit('vehicle_assembler', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle assemblers')),

          ShortcodeService.getUnitTypeList()
            .then(res => commit('unit_type', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'unit types')),

          ShortcodeService.getCoverageTypeList()
            .then(res => commit('coverage_type', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'coverage types')),

          ShortcodeService.getVehicleBodyList()
            .then(res => commit('car_body', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle bodies')),

          ShortcodeService.getFuelOptions()
            .then(res => commit('car_fuel', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'fuel types')),

          ShortcodeService.getHirePurchaseCompanyList()
            .then(res => commit('hire_purchase_company', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'hire purchase companies')),

          ShortcodeService.getStateList()
            .then(res => commit('state', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'states')),

          ShortcodeService.getVehicleTypeList()
            .then(res => commit('car_type', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle types')),

          ShortcodeService.getSafetyFeatureList()
            .then(res => commit('safety_feature', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'safety features')),

          ShortcodeService.getAntiTheftFeatureList()
            .then(res => commit('anti_theft_feature', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'anti theft features')),

          ShortcodeService.getPermittedDriverList()
            .then(res => commit('permitted_driver', res.data))
            .catch(err => console.error(err))
            .finally(() =>
              commit('switchLoading', 'permitted driver policies')
            ),

          ShortcodeService.getGenderList()
            .then(res => commit('gender', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'genders')),

          ShortcodeService.getCustomerTitleList()
            .then(res => commit('customer_title', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'customer titles')),

          ShortcodeService.getMaritalStatus()
            .then(res => commit('marital_status', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'marital status')),

          ShortcodeService.getEthnicList()
            .then(res => commit('ethnic', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'ethnics')),

          ShortcodeService.getReligionList()
            .then(res => commit('religion', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'religions')),

          ShortcodeService.getOccupationList()
            .then(res => commit('occupation', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'occupations')),

          ShortcodeService.getMakeList()
            .then(res => commit('car_make', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle makes')),

          ShortcodeService.getRelationshipList()
            .then(res => commit('relationship', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'relationships')),

          ShortcodeService.getVehicleConditionList()
            .then(res => commit('vehicle_condition', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle conditions')),

          ShortcodeService.getGarageList()
            .then(res => commit('garage', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'garage')),

          ShortcodeService.getModelList()
            .then(res => commit('car_model', res.data))
            .catch(err => console.error(err))
            .finally(() => commit('switchLoading', 'vehicle models')),
        ]).finally(() => commit('isLoaded', true));
      }
    },
  },
};
