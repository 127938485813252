<template>
  <div class="gp-vendor__container">
    <div class="gp-vendor container--flex justify--center align-items--center">
      <div class="container--25">
        <img
          class="gp-vendor__logo"
          :src="vendorLogo"
          alt="Tune Protect logo"
        />
      </div>

      <div class="container--75">
        <div
          class="container--flex justify--center align-items--center"
          style="font-size: 14px"
        >
          <div class="container--50">
            <div class="gp-vendor__subheader">Sum Insured</div>
          </div>
          <div class="container--50">
            : {{ sumInsured ? formatCurrency(sumInsured) : 'N/A' }}
          </div>
        </div>

        <div
          class="container--flex justify--center align-items--center"
          style="font-size: 14px"
        >
          <div class="container--50">
            <div class="gp-vendor__subheader">Premium</div>
          </div>
          <div class="container--50">
            : {{ formatCurrency(provider.total_amount_payable) }}
          </div>
        </div>

        <div class="notice">
          *All price is pre-quote and final price may different depend on add on
          of extra benefit
        </div>

        <div class="container--flex justify--center align-items--center">
          <v-ons-button
            :disabled="disabled"
            @click="$emit('selected', provider)"
          >
            Select
          </v-ons-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TIMB_LOGO } from '@/lib/constants';

export default {
  props: {
    provider: Object,
    sumInsured: {
      type: Number,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      vendorLogo: TIMB_LOGO,
    };
  },
  methods: {
    formatCurrency(number) {
      const formatter = new Intl.NumberFormat('en-MY', {
        style: 'currency',
        currency: 'MYR',
      });
      return formatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  color: red;
  font-size: 12px;
  margin: 8px 0;
}
</style>
