<template>
  <v-ons-page modifier="gp">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 3</div>
        <div class="gp-form__sublabel">
          Customer Details
        </div>
      </state-flag>

      <div>
        <customer-form
          variant="minimal"
          :customer="policyHolderInfo"
          :schema="schema.policyHolderInfo"
          :validators="validators.policyHolderInfo"
          :disabled="locked"
          :disabledFields="['customer_type', 'id_no']"
          @change="handleChange"
          v-bind:validation.sync="validation"
          :shortcodes="shortcodes.policyHolderInfo"
        />

        <!-- Call to actions -->
        <div class="button-container">
          <v-ons-button
            @click="gotoNextPage()"
            modifier="gp"
            :disabled="validation.$invalid"
            class="primary"
          >
            Next
          </v-ons-button>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import CustomerForm from '@/components/CustomerForm.vue';
import StateFlag from '@/components/StateFlag.vue';

// Views
import QuoteFormStep4 from '@/views/motor-insurance/QuoteFormStep4.vue';

// Utilities and config
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';

export default {
  mixins: [QuoteFormMixin],
  props: ['pageStack'],
  components: {
    PageToolbar,
    CustomerForm,
    StateFlag,
  },
  data() {
    return {
      dateFormat: 'yyyy-MM-dd',
      validation: {},
      nextPage: QuoteFormStep4,
      confirmDispatchActions: ['policy/savePolicyHolderInfo'],
    };
  },
  methods: {
    handleChange(field) {
      const { loadSchema, $store } = this;
      if (field === 'customer_type') {
        loadSchema();
      }
      $store.dispatch('policy/updateShortcodes', {
        field: `policyHolderInfo.${field}`,
      });
    },
  },
};
</script>
