import axios from '@/lib/axios';
import { API_BASE_URL } from '@/lib/constants';

function get(page = 1, length = 10, search = {}) {
  const searchQs = Object.keys(search)
    .filter(f => !!search[f])
    .map(f => `search[${f}]=${search[f]}`)
    .join('&');
  const qs = [];
  if (page) {
    qs.push('page=' + page);
  }
  if (length) {
    qs.push('length=' + length);
  }
  if (!page && !length) {
    qs.push(`find_all=on`);
  }
  return axios.gpAPI({
    method: 'GET',
    url: `/customers?${qs.join('&')}&${searchQs}`.trim('&'),
  });
}

function view(id) {
  return axios.gpAPI({
    method: 'GET',
    baseURL: API_BASE_URL,
    url: `/customers/${id}`,
  });
}

function save(id = null, data) {
  return axios.gpAPI({
    method: id ? 'PATCH' : 'PUT',
    baseURL: API_BASE_URL,
    url: id ? `/customers/${id}` : '/customers',
    data,
  });
}

function schema(customer_type, id = null) {
  return axios.gpAPI({
    method: id ? 'PATCH' : 'PUT',
    baseURL: API_BASE_URL,
    url: id ? `/customers/${id}` : '/customers',
    data: { customer_type, schema: true },
  });
}

const customersService = {
  get,
  view,
  save,
  schema,
};

export default customersService;
