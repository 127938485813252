<template>
  <ons-form-group
    v-if="$v.fee.amount"
    :validator="$v.fee.amount"
    :label="`${operator} ${fee.name} (RM)`"
    style="width:100%; margin:0"
  >
    <v-ons-input
      v-model.lazy.number="$v.fee.amount.$model"
      type="number"
      class="gp-input"
      modifier="gp"
      :disabled="disabled || definition.amount !== null"
      @keyup="handleFeeChange(fee)"
    ></v-ons-input>
  </ons-form-group>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';

export default {
  props: {
    disabled: Boolean,
    definition: Object,
    fee: Object,
    operator: String,
  },

  validations() {
    const { definition, disabled } = this;
    if (!disabled && !isNaN(definition.amount)) {
      return {
        fee: {
          amount: {
            required,
            minValue: minValue(1),
          },
        },
      };
    }
    return {
      fee: {
        amount: {},
      },
    };
  },

  mounted() {
    this.$emit('update:validator', !this.$v.$invalid);
  },

  watch: {
    fee() {
      this.$v.$touch();
    },
    definition() {
      this.$v.$touch();
    },
    '$v.$invalid'() {
      this.$emit('update:validator', !this.$v.$invalid);
    },
  },

  methods: {
    handleFeeChange(fee) {
      this.$emit('feeUpdated', fee);
    },
  },
};
</script>
