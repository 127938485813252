<template>
  <span>
    <dynamic-form-field
      v-if="schema.cover_from && !!validator.cover_from"
      label="Cover From"
      :validator="validator.cover_from"
      :definition="schema.cover_from"
      :key="
        `cover_from-${schema.cover_from ? schema.cover_from.value_type : 0}`
      "
      :disabled-dates="getCoverFromDisabledDates()"
      :disabled="disabled"
      @change="handleCoverFromChange"
      :shortcodes="shortcodes"
    />
    <dynamic-form-field
      v-if="schema.cover_to && !!validator.cover_to"
      label="Cover To"
      disabled
      :validator="validator.cover_to"
      :definition="schema.cover_to"
      :key="`cover_to-${schema.cover_to ? schema.cover_to.value_type : 0}`"
      :shortcodes="shortcodes"
    />
  </span>
</template>

<script>
// Page components
import DynamicFormField from '@/components/DynamicFormField.vue';

// Utilities
import moment from 'moment';

export default {
  props: {
    schema: Object,
    validator: Object,
    disabled: Boolean,
    shortcodes: Object,
    backdateMinimum: Object,
  },
  components: {
    DynamicFormField,
  },
  methods: {
    getCoverFromDisabledDates() {
      let allowedBackdate = moment();
      if (this.backdateMinimum) {
        if (moment.isMoment(this.backdateMinimum)) {
          allowedBackdate = this.backdateMinimum;
        } else {
          allowedBackdate = moment(this.backdateMinimum);
        }
      }
      return {
        //to: allowedBackdate.subtract(1, 'd').toDate(),
        to: allowedBackdate.toDate(),
      };
    },
    async handleCoverFromChange(coverFrom) {
      const coverTo = moment(coverFrom)
        .add(1, 'y')
        .subtract(1, 'd');
      this.$emit('update:cover_from', coverFrom);
      this.$emit('update:cover_to', coverTo.toDate());
    },
  },
};
</script>
