<template>
  <div class="gp-addon-details">
    <div class="gp-addon-details__group">
      <p class="gp-addon-details__title"></p>
      <div class="gp-addon-details__body" v-if="roadTaxDeliveryNote">
        <div class="gp-addon-details__item">
          <div class="gp-addon-details__item-label">
            <p>Road Tax Deliver To</p>
            <p>:</p>
          </div>
          <p v-html="roadTaxDeliveryNote"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addons: Array,
  },
  data() {
    return {
      roadTaxDeliveryNote: null,
    };
  },
  mounted() {
    this.configure();
  },
  watch: {
    addons() {
      this.configure();
    },
  },
  methods: {
    configure() {
      const { addons } = this;
      // Roadtax handler
      const roadTax = addons.find(a => a.name.match(/road tax/i));
      if (roadTax) {
        this.roadTaxDeliveryNote = roadTax.notes
          // Convert new lines to html br
          .map(n => n.value.replace(/(?:\r\n|\r|\n)/g, '<br>'))
          .join(''); // TODO: Temporary workaround for arrays
      }
    },
  },
};
</script>
