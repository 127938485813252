import axios from '@/lib/axios';

function getMakeList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/car_make',
  });
}

function getCustomerTitleList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/customer_title',
  });
}

function getGenderList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/gender',
  });
}

function getVehicleClassList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/vehicle_class',
  });
}

function getOccupationList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/occupation',
  });
}

function getReligionList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/religion',
  });
}

function getEthnicList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/ethnic',
  });
}

function getMaritalStatus() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/marital_status',
  });
}

function getSafetyFeatureList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/safety_feature',
  });
}

function getAntiTheftFeatureList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/anti_theft_feature',
  });
}

function getPermittedDriverList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/permitted_driver',
  });
}

function getVehicleAssemblerList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/vehicle_assembler',
  });
}

function getUnitTypeList() {
  return axios.gpAPI({
    method: 'GET',
    url: 'shortcodes/unit_type',
  });
}

function getVehicleTypeList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/car_type',
  });
}

function getStateList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/state',
  });
}

function getCoverageTypeList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/coverage_type',
  });
}

function getVehicleConditionList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/vehicle_condition',
  });
}

function getVehicleBodyList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/car_body',
  });
}

function getFuelOptions() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/car_fuel',
  });
}

function getHirePurchaseCompanyList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/hire_purchase_company',
  });
}

function getRelationshipList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/relationship',
  });
}

function getGarageList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/garage',
  });
}

function getModelList() {
  return axios.gpAPI({
    method: 'GET',
    url: '/shortcodes/car_model',
  });
}

const shortcodeService = {
  getMakeList,
  getModelList,
  getCustomerTitleList,
  getGenderList,
  getVehicleClassList,
  getOccupationList,
  getReligionList,
  getEthnicList,
  getMaritalStatus,
  getSafetyFeatureList,
  getAntiTheftFeatureList,
  getPermittedDriverList,
  getVehicleAssemblerList,
  getUnitTypeList,
  getVehicleTypeList,
  getStateList,
  getCoverageTypeList,
  getVehicleConditionList,
  getVehicleBodyList,
  getFuelOptions,
  getHirePurchaseCompanyList,
  getRelationshipList,
  getGarageList,
};

export default shortcodeService;
