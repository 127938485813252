<template id="nav">
  <v-ons-navigator :page-stack="pageStack">
    <component
      v-for="(page, i) in pageStack"
      :is="page"
      :key="i"
      :page-stack="pageStack"
    ></component>
  </v-ons-navigator>
</template>

<script>
import Home from '@/views/Home.vue';
import { handleBackButton } from '@/lib/pageHelpers';

export default {
  data() {
    return {
      pageStack: [Home],
    };
  },
  beforeCreate() {
    this.$ons.ready(() => {
      // Back button listener
      this.$ons.setDefaultDeviceBackButtonListener(async () => {
        handleBackButton(this);
      });
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
