// Utilities
import _ from 'lodash';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
//import { validYear, maxAtCurrentYear, validIcNo } from '@/validators';
import { validYear, maxAtCurrentYear } from '@/validators';
import * as shortcodeHelpers from '@/lib/shortcodeHelpers';

// Services
import CustomersService from '@/services/customers';

// Config
import { CUSTOMER } from '@/lib/constants';
const { SHORTCODE_FIELDS } = CUSTOMER;

const handleWeirdHttpStatus = commit => err => {
  if (
    err.response.data &&
    err.response.data.name &&
    err.response.data.message
  ) {
    commit('serverErrors', [
      `${err.response.data.name}: ${err.response.data.message}`,
    ]);
  } else {
    commit('serverErrors', [
      `${err.response.status}: ${err.response.statusText}`,
    ]);
  }
};

const defaultState = () => ({
  schema: {},
  validators: {
    customer: {},
  },
  customer: {
    id: null,
    customer_type: 'individual',
    company_regno: '',
    name: '',
    gender_code: '',
    title_code: '',
    ethnic_code: '',
    religion_code: '',
    dob: '',
    id_no: '',
    email: '',
    mobile_no: '',
    address1: '',
    address2: '',
    postcode: '',
    city: '',
    state_code: '',
    marital_status_code: '',
    occupation_code: '',
    driving_since: '',
    status_code: 'A',
  },
  isLocked: false,
  vehicles: [],
  serverErrors: [],
  loading: [],
  saving: [],
  shortcodes: {},
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      Object.assign(state, defaultState());
    },
    switchLoading(state, loadingLabel) {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    switchSaving(state, savingDataLabel) {
      const labelIndex = state.saving.findIndex(l => l === savingDataLabel);
      if (labelIndex > -1) {
        state.saving.splice(labelIndex, 1);
      } else {
        state.saving.push(savingDataLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.saving.length < 1) {
        state.saving = [];
      }
    },
    isLocked: (state, data) => (state.isLocked = data),
    customer: (state, data = []) => (state.customer = data),
    id: (state, id) => (state.customer.id = id),
    vehicles: (state, data = []) => (state.vehicles = data),
    serverErrors: (state, data = []) => (state.serverErrors = data),
    schema: (state, schema = {}) => (state.schema = schema),
    validators: (state, validators = {}) => (state.validators = validators),
    shortcodes: (state, shortcodes) =>
      (state.shortcodes = Object.assign({}, state.shortcodes, shortcodes)),
  },
  actions: {
    updateShortcodeValues({ state, commit }) {
      const shortcodes = _.cloneDeep(state.shortcodes);
      const customerState = _.cloneDeep(state.customer);
      for (const sf of SHORTCODE_FIELDS) {
        const value = _.get(state, `customer.${sf.field}`);
        const options = _.get(shortcodes, sf.category);
        // Set only fields with invalid shortcode
        if (!options.find(s => s.shortcode === value)) {
          // Find default shortcode to use
          let defaultShortcode = options.find(s => s.is_default === true);
          // Set to first shortcode if no default value
          if (!defaultShortcode) {
            defaultShortcode = options[0];
          }
          // Update state
          customerState[sf.field] = defaultShortcode.shortcode;
        }
      }
      commit('customer', customerState);
    },

    async updateShortcodeOptions({ rootState, state, commit }, { field }) {
      const shortcodes = Object.assign({}, rootState.shortcodes.categories);

      // Lookup field's shortcodes configuration
      const sf = SHORTCODE_FIELDS.find(sf => sf.field === field);
      if (!sf) {
        return;
      }

      // Retrieve full shortcodes
      const childrenShortcodes = shortcodeHelpers.getChildrenShortcodes(
        shortcodes,
        sf.category,
        state.customer[field]
      );
      // Assign it based on config
      await commit('shortcodes', childrenShortcodes);
    },

    async updateShortcodes({ dispatch }, { field }) {
      await dispatch('updateShortcodeOptions', { field });
      dispatch('updateShortcodeValues');
    },

    async rebuildShortcodes({ rootState, commit, dispatch }) {
      const shortcodes = Object.assign({}, rootState.shortcodes.categories);

      // Init options by assigning all shortcodes
      const options = {};
      for (const sf of SHORTCODE_FIELDS) {
        options[sf.category] = shortcodes[sf.category];
      }
      await commit('shortcodes', options);

      // Then assign shortcode options by selected value
      // if value is empty, assign empty array to shortcode options
      for (const category in options) {
        const { field } = SHORTCODE_FIELDS.find(sf => sf.category === category);
        await dispatch('updateShortcodes', { field });
      }
    },

    async loadSchema({ commit, state }) {
      commit('switchLoading', 'schema');
      // Request schema
      const schema = await CustomersService.schema(
        state.customer.customer_type,
        state.customer.id
      )
        .then(d => d.data)
        .catch(handleWeirdHttpStatus(commit))
        .finally(() => commit('switchLoading', 'schema'));
      // Parse schema
      const validators = { customer: {} };
      for (let field of Object.keys(schema)) {
        validators.customer[field] = {};
        if (schema[field].required) {
          validators.customer[field].required = required;
        }
        if (field === 'driving_since') {
          schema[field].value_type = 'number';
          validators.customer[field].validYear = validYear.validator;
          validators.customer[field].maxAtCurrentYear =
            maxAtCurrentYear.validator;
        }
      }
      commit('schema', schema);
      commit('validators', validators);
    },

    async loadData({ commit, state }) {
      if (state.customer.id) {
        commit('switchLoading', 'customer');
        const response = await CustomersService.view(state.customer.id)
          .catch(handleWeirdHttpStatus(commit))
          .finally(() => commit('switchLoading', 'customer'));
        commit(
          'customer',
          Object.assign({}, response.data, {
            driving_since: moment(
              response.data.driving_since,
              'YYYY-MM-DD'
            ).format('YYYY'),
          })
        );
        commit('vehicles', response.data.Vehicles);
        commit('isLocked', true);
      }
    },

    async save({ commit, state }) {
      commit('switchSaving', 'customer');
      //let ommittedFields = ['id'];
      let data = _.cloneDeep(state.customer);
      for (const f in state.customer) {
        const inSchema = _.has(state.schema, f);
        const isForbidden =
          state.schema[f] && state.schema[f].value_type === 'forbidden';
        if ((!inSchema || isForbidden) && f !== 'customer_type') {
          // Remove forbidden fields or fields not in schema
          data = _.omit(data, f);
        } else if (f === 'driving_since') {
          // Special driving_since handler
          data.driving_since = state.customer.driving_since + '-01-01';
        }
      }
      const response = await CustomersService.save(state.customer.id, data)
        .then(res => {
          commit('isLocked', true);
          return res;
        })
        .catch(handleWeirdHttpStatus(commit))
        .finally(() => commit('switchSaving', 'customer'));
      const customer_type = state.customer.customer_type;
      commit(
        'customer',
        Object.assign({}, response.data, {
          customer_type,
          driving_since: response.data.driving_since
            ? moment(response.data.driving_since, 'YYYY-MM-DD').format('YYYY')
            : '',
        })
      );
    },
  },
};
