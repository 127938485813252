<template>
  <div>
    <div
      class="gp-empty-state__list"
      v-if="$slots.default && $slots.default.length <= 0"
    >
      <h3>No {{ name }}</h3>
      <p>
        No {{ name }} found. Please tap on 'ADD MORE' to add {{ listName }}.
      </p>
    </div>

    <div v-else class="gp-list-container">
      <div class="gp-list-header">
        {{ name.charAt(0).toUpperCase() + name.slice(1) }} Listing
      </div>
      <div class="gp-list-items-container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
  },
};
</script>
