import axios from '@/lib/axios';
import _ from 'lodash';
import moment from 'moment';

function _beforeSave(data) {
  const dateFields = ['cover_from', 'cover_to'];
  const newData = _.cloneDeep(data);
  // Convert dates
  for (const f of dateFields) {
    if (_.has(data, f)) {
      newData[f] = moment(data[f]).format('YYYY-MM-DD');
    }
  }
  return newData;
}

function view(id) {
  return axios.gpAPI({
    method: 'GET',
    url: `/insurance_policies/${id}`,
  });
}

function getInsurancePolicies(page = 1, length = 10, search = {}) {
  const s = Object.assign({}, search);
  for (const f in s) {
    if (Array.isArray(s[f])) {
      s[f] = s[f].join(',');
    }
  }
  const searchQs = Object.keys(s)
    .filter(f => !!s[f])
    .map(f => `search[${f}]=${s[f]}`)
    .join('&');
  return axios.gpAPI({
    method: 'GET',
    url: `/insurance_policies?page=${page}&length=${length}&${searchQs}`.trim(
      '&'
    ),
  });
}

function getSearchOptions() {
  return axios.gpAPI({
    method: 'GET',
    url: '/insurance_policies/option_list',
  });
}

function requestQuotation(policyId) {
  return axios.gpAPI({
    method: 'POST',
    url: `/insurance_policies/quote/${policyId}`,
  });
}

function draft(data) {
  return axios.gpAPI({
    method: 'PUT',
    url: `/insurance_policies`,
    // Remove all falsy values
    data: _beforeSave(_.omitBy(data, o => !o)),
  });
}

function save(id, data) {
  return axios.gpAPI({
    method: 'PATCH',
    url: `/insurance_policies/${id}`,
    data: _beforeSave(data),
  });
}

function checkProviderPrice(id) {
  return axios.gpAPI({
    method: 'POST',
    url: `/insurance_policies/price/${id}`,
  });
}

function getProviderISMValue(formData) {
  return axios.gpAPI({
    method: 'GET',
    url: '/policy_providers/ism',
    params: formData,
  });
}

function checkNCD(formData) {
  return axios.gpAPI({
    method: 'GET',
    url: '/policy_providers/ncd',
    params: formData,
  });
}

function retrieveDocuments(id) {
  return axios.gpAPI({
    method: 'POST',
    url: `/policy_documents/${id}`,
  });
}

function getDownloadUrl(id, docId) {
  return axios.gpAPI({
    method: 'GET',
    url: `/policy_documents/${id}/download/${docId}`,
  });
}

async function schema(id = null, data = null) {
  if (id) {
    return axios.gpAPI({
      method: 'PATCH',
      url: `/insurance_policies/${id}`,
      data: { schema: true },
    });
  } else {
    return axios.gpAPI({
      method: 'PUT',
      url: `/insurance_policies`,
      data: { schema: true, ...data },
    });
  }
}

const policyService = {
  view,
  getInsurancePolicies,
  getSearchOptions,
  requestQuotation,
  draft,
  save,
  checkProviderPrice,
  getProviderISMValue,
  checkNCD,
  retrieveDocuments,
  getDownloadUrl,
  schema,
};

export default policyService;
