<template>
  <div class="customer-form-container">
    <div class="gp-form__group">
      <label class="gp-input__label">Customer Type</label>
      <div class="gp-select__container">
        <v-ons-select
          v-model="customer.customer_type"
          modifier="gp"
          :disabled="
            customer.id || !!disabledFields.find(df => df === 'customer_type')
          "
          @change="handleChange('customer_type')"
        >
          <option v-for="t in customerTypes" :key="t.value" :value="t.value">{{
            t.label
          }}</option>
        </v-ons-select>
      </div>
    </div>

    <div class="customer-form" v-if="Object.keys(schema).length > 0">
      <dynamic-form-field
        v-if="schema.title_code"
        label="Title"
        :validator="$v.customer.title_code"
        :definition="schema.title_code"
        :key="`title_code-${schema.title_code ? 1 : 0}`"
        :disabled="disabled"
        @change="handleChange('title_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.name"
        label="Name"
        :validator="$v.customer.name"
        :definition="schema.name"
        :key="`name-${schema.name ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'name')"
        @change="handleChange('name')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.id_no"
        label="NRIC / Old IC No. / Passport No. / Other No."
        :validator="$v.customer.id_no"
        :definition="schema.id_no"
        :key="`id_no-${schema.id_no ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'id_no')"
        @change="handleIdChange"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.company_regno"
        label="Company No."
        :validator="$v.customer.company_regno"
        :definition="schema.company_regno"
        :key="`company_regno-${schema.company_regno ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'company_regno')
        "
        @change="handleChange('company_regno')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.dob"
        label="Date of Birth"
        :validator="$v.customer.dob"
        :definition="schema.dob"
        :key="`dob-${schema.dob ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'dob')"
        @change="handleChange('dob')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.gender_code"
        label="Gender"
        :validator="$v.customer.gender_code"
        :definition="schema.gender_code"
        :key="`gender_code-${schema.gender_code ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'gender_code')
        "
        @change="handleChange('gender_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.marital_status_code"
        label="Marital Status"
        :validator="$v.customer.marital_status_code"
        :definition="schema.marital_status_code"
        :key="`marital_status_code-${schema.marital_status_code ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'marital_status_code')
        "
        @change="handleChange('marital_status_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.ethnic_code"
        label="Race"
        :validator="$v.customer.ethnic_code"
        :definition="schema.ethnic_code"
        :key="`ethnic_code-${schema.ethnic_code ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'ethnic_code')
        "
        @change="handleChange('ethnic_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.religion_code"
        label="Religion"
        :validator="$v.customer.religion_code"
        :definition="schema.religion_code"
        :key="`religion_code-${schema.religion_code ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'religion_code')
        "
        @change="handleChange('religion_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.occupation_code"
        label="Occupation"
        :validator="$v.customer.occupation_code"
        :definition="schema.occupation_code"
        :key="`occupation_code-${schema.occupation_code ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'occupation_code')
        "
        @change="handleChange('occupation_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.driving_since"
        label="Driving Since Year"
        :validator="$v.customer.driving_since"
        :definition="schema.driving_since"
        :key="`driving_since-${schema.driving_since ? 1 : 0}`"
        :disabled="
          disabled || !!disabledFields.find(df => df === 'driving_since')
        "
        @change="handleChange('driving_since')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.mobile_no"
        label="Office Telephone / Mobile No."
        :validator="$v.customer.mobile_no"
        :definition="schema.mobile_no"
        :key="`mobile_no-${schema.mobile_no ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'mobile_no')"
        @change="handleChange('mobile_no')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.email"
        label="Email"
        :validator="$v.customer.email"
        :definition="schema.email"
        :key="`email-${schema.email ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'email')"
        @change="handleChange('email')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.address1"
        label="Address Line 1"
        :validator="$v.customer.address1"
        :definition="schema.address1"
        :key="`address1-${schema.address1 ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'address1')"
        @change="handleChange('address1')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.address2"
        label="Address Line 2"
        :validator="$v.customer.address2"
        :definition="schema.address2"
        :key="`address2-${schema.address2 ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'address2')"
        @change="handleChange('address2')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.address3"
        label="Address Line 3"
        :validator="$v.customer.address3"
        :definition="schema.address3"
        :key="`address3-${schema.address3 ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'address3')"
        @change="handleChange('address3')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.postcode"
        label="Postcode"
        :validator="$v.customer.postcode"
        :definition="schema.postcode"
        :key="`postcode-${schema.postcode ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'postcode')"
        @change="handleChange('postcode')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.city"
        label="City"
        :validator="$v.customer.city"
        :definition="schema.city"
        :key="`city-${schema.city ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'city')"
        @change="handleChange('city')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.state_code"
        label="State"
        :validator="$v.customer.state_code"
        :definition="schema.state_code"
        :key="`state_code-${schema.state_code ? 1 : 0}`"
        :disabled="disabled || !!disabledFields.find(df => df === 'state_code')"
        @change="handleChange('state_code')"
        :shortcodes="shortcodes"
      />
      <dynamic-form-field
        v-if="schema.status_code && variant === 'full'"
        label="Status"
        :validator="$v.customer.status_code"
        :definition="
          schema.status_code ||
            !!disabledFields.find(df => df === 'status_code')
        "
        :key="`status_code-${schema.status_code ? 1 : 0}`"
        :disabled="disabled"
        @change="handleChange('status_code')"
        :shortcodes="shortcodes"
      />
    </div>
  </div>
</template>

<script>
// Components
import DynamicFormField from '@/components/DynamicFormField.vue';

// Utilities and config
import { CUSTOMER_TYPES } from '@/lib/constants';
import moment from 'moment';

export default {
  props: {
    customer: Object,
    schema: Object,
    validators: Object,
    disabled: Boolean,
    shortcodes: Object,
    variant: {
      type: String,
      default: 'full',
      validator: value => ['minimal', 'full'].indexOf(value) !== -1,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DynamicFormField,
  },
  data() {
    return {
      customerTypes: CUSTOMER_TYPES,
    };
  },
  validations() {
    return { customer: this.validators };
  },
  mounted() {
    const { $v } = this;
    $v.customer.$touch();
    this.$emit('update:validation', $v.customer);
  },
  watch: {
    customer() {
      const { $v } = this;
      $v.customer.$touch();
      this.$emit('update:validation', $v.customer);
    },
    schema() {
      const { $v } = this;
      $v.customer.$touch();
      this.$emit('update:validation', $v.customer);
    },
    validators() {
      const { $v } = this;
      $v.$reset();
      $v.customer.$touch();
      this.$emit('update:validation', $v.customer);
    },
  },
  methods: {
    handleChange(field) {
      const { $v } = this;
      this.$emit('update:validation', $v.customer);
      this.$emit('change', field);
    },
    handleIdChange() {
      const { customer, $v, handleChange } = this;
      const dobStr = customer.id_no.substring(0, 6);
      if (dobStr.length === 6) {
        const dob = moment(dobStr, 'YYMMDD');
        if (dob.isValid()) {
          customer.dob = dob.toDate();
          $v.customer.$touch();
        }
      }
      handleChange('dob');
      handleChange('id_no');
    },
  },
};
</script>
