import { mapState, mapMutations } from 'vuex';
import ErrorView from '@/views/Error.vue';

export default {
  watch: {
    // Alert on server errors
    async errors() {
      const { $ons, errors, errorTypes, saveErrors } = this;
      if (errors.length > 0) {
        const error = errors[0];
        if (error.type === errorTypes.MINOR) {
          $ons.notification.alert(error.message);
          await saveErrors([]);
        } else if (error.type === errorTypes.MAJOR) {
          $ons.notification.alert(error.message);
        } else if (error.type === errorTypes.CRITICAL) {
          this.pageStack.push({
            extends: ErrorView,
            data() {
              return { error: error.message };
            },
          });
        } else {
          $ons.notification.alert(error.message);
          console.error(error);
        }
      }
    },
  },

  methods: {
    ...mapMutations({
      saveErrors: 'policy/errors',
    }),
  },

  computed: mapState({
    // Detect API errors
    errorTypes: state => state.policy.errorTypes,
    errors: state => state.policy.errors,
  }),
};
