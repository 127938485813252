import axios from '@/lib/axios';

function get(page = 1, length = 10, search = {}) {
  const searchQs = Object.keys(search)
    .filter(f => !!search[f])
    .map(f => `search[${f}]=${search[f]}`)
    .join('&');
  const qs = [];
  if (page) {
    qs.push('page=' + page);
  }
  if (length) {
    qs.push('length=' + length);
  }
  return axios.gpAPI({
    method: 'GET',
    url: `/vehicles?${qs.join('&')}&${searchQs}`.trim('&'),
  });
}

function view(id) {
  return axios.gpAPI({
    method: 'GET',
    url: `/vehicles/${id}`,
  });
}

function save(customerId, id = null, data) {
  return axios.gpAPI({
    method: id ? 'PATCH' : 'PUT',
    url: id ? `/vehicles/${id}` : `/vehicles/customer/${customerId}`,
    data: data,
  });
}

function schema(customerId, data = {}, id = null) {
  return axios.gpAPI({
    method: id ? 'PATCH' : 'PUT',
    url: id ? `/vehicles/${id}` : `/vehicles/customer/${customerId}`,
    data: { ...data, schema: true },
  });
}

const vehiclesService = {
  get,
  schema,
  view,
  save,
};

export default vehiclesService;
