<template id="add-customer">
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="gp-form__label">Customer Details</div>

    <customer-form
      :validators="validators"
      :schema="schema"
      :customer="customer"
      :disabled="locked"
      @change="handleChange"
      v-bind:validation.sync="validation"
      :shortcodes="shortcodes"
    />

    <div class="container--flex">
      <div class="button-container container--50 mx-8" v-if="!!customer.id">
        <v-ons-button class="button--gp" modifier="gp" @click="onEditSwitch">
          {{ locked ? 'Edit' : 'Cancel' }}
        </v-ons-button>
      </div>
      <div
        class="button-container"
        :class="{
          'container--50 mx-8': !!customer.id,
          'container--100': !customer.id,
        }"
      >
        <v-ons-button
          class="button--gp primary"
          modifier="gp"
          :disabled="locked || validation.$invalid"
          @click="save"
        >
          Confirm
        </v-ons-button>
      </div>
    </div>

    <div class="vehicle-form container--flex">
      <div class="container--50">
        <div class="gp-form__label">Vehicle Details</div>
      </div>

      <div class="container--50">
        <v-ons-button
          :disabled="!customer.id"
          @click="pushToAddVehicle"
          modifier="gp"
          class="button--gp secondary"
        >
          + Add more
        </v-ons-button>
      </div>
    </div>

    <listing name="vehicle">
      <vehicle-list-item
        v-for="vehicle in vehicles"
        variant="minimal"
        :key="vehicle.id"
        :vehicle="vehicle"
        :customer="customer"
        @quote="gotoQuoteFormStep1"
        @view="gotoUpsertVehicle"
      ></vehicle-list-item>
    </listing>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import Listing from '@/components/Listing.vue';
import VehicleListItem from '@/components/VehicleListItem.vue';
import CustomerForm from '@/components/CustomerForm.vue';

// Views
import UpsertVehicle from '@/views/client/UpsertVehicle.vue';
import QuoteFormStep1 from '@/views/motor-insurance/QuoteFormStep1.vue';

// Utilities and config
import { mapState } from 'vuex';

export default {
  props: ['pageStack'],
  components: {
    PageToolbar,
    Listing,
    VehicleListItem,
    CustomerForm,
  },
  data() {
    return {
      id: null,
      validation: {},
    };
  },
  watch: {
    serverErrors() {
      const { $ons } = this;
      if (this.serverErrors.length > 0) {
        const message = this.serverErrors[0];
        $ons.notification.alert(message);
      }
    },
  },
  methods: {
    async handleChange(field) {
      const { checkSchema, $store } = this;
      await $store.dispatch('customer/updateShortcodes', { field });
      if (field === 'customer_type') {
        checkSchema();
      }
    },

    async handlePageShow() {
      const { $store, id, checkSchema } = this;
      await $store.commit('customer/reset');
      if (id) {
        await $store.commit('customer/id', id);
        await $store.dispatch('customer/loadData');
      }
      await $store.dispatch('customer/rebuildShortcodes');
      checkSchema();
    },

    checkSchema() {
      const { $store } = this;
      $store.dispatch('customer/loadSchema');
    },

    async save() {
      const $vm = this;
      const isEdit = !!$vm.customer.id;
      await $vm.$store.dispatch('customer/save');
      if ($vm.serverErrors.length <= 0) {
        let message =
          'You have successfully added a new customer into your account.';
        if (isEdit) {
          message = 'Customer updated';
          $vm.$store.commit('customer/isLocked', true);
        }
        $vm.$ons.notification.toast(message, { timeout: 3000 });
      }
      this.id = $vm.customer.id;
    },

    pushToAddVehicle() {
      const $vm = this;

      $vm.$store.commit('vehicle/reset');
      $vm.$store.commit('vehicle/customerId', $vm.customer.id);
      this.pageStack.push({
        extends: UpsertVehicle,
        data() {
          return {
            customer_id: $vm.customer.id,
          };
        },
      });
    },

    onEditSwitch() {
      const $vm = this;
      $vm.$store.commit('customer/isLocked', !$vm.locked);
    },

    async gotoQuoteFormStep1(vehicle) {
      const { pageStack } = this;

      // Forward policy data to step 1
      pageStack.push({
        extends: QuoteFormStep1,
        data() {
          return { loadVehicleId: vehicle.id };
        },
      });
    },

    async gotoUpsertVehicle(vehicle) {
      const { pageStack } = this;
      pageStack.push({
        extends: UpsertVehicle,
        data() {
          return {
            id: vehicle.id,
          };
        },
      });
    },
  },
  computed: mapState({
    // customers
    schema: state => state.customer.schema,
    validators: state => state.customer.validators.customer,
    customer: state => state.customer.customer,
    vehicles: state => state.customer.vehicles,
    locked: state => state.customer.isLocked,
    serverErrors: state => state.customer.serverErrors,
    shortcodes: state => state.customer.shortcodes,
  }),
};
</script>

<style lang="scss" scoped>
ons-input[disabled] {
  opacity: 1;
}

.vehicle-form {
  margin-top: 32px;
  margin-bottom: 16px;
  align-items: center;
}

.customer-form-container {
  margin-top: 32px;
}
</style>
