// Utilities
//import _ from 'lodash';

// Services
import CustomersService from '@/services/customers';

const defaultState = () => ({
  loading: [],
  items: [], // Used to populate select option
  errors: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      const items = [].concat(state.items);
      // Prevent items from being assigned
      Object.assign(state, defaultState(), { items });
    },
    switchLoading(state, loadingLabel = '') {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    items: (state, data = []) => (state.items = data),
    errors: (state, data) => (state.errors = data),
  },
  actions: {
    async load({ commit, state }) {
      if (!state.loading.find(l => l === 'customer options')) {
        commit('switchLoading', 'customer options');
        const response = await CustomersService.get(null, null).catch(err =>
          commit('errors', [`${err.name}: ${err.message}`])
        );
        commit('switchLoading', 'customer options');
        commit(
          'items',
          response.data.data.map(row => ({
            label: row.name,
            value: row.id,
            data: row,
          }))
        );
      }
    },
  },
};
