// Services
import AddonsService from '@/services/addons';

// Utilities
import _ from 'lodash';
const StateMachine = require('javascript-state-machine');

const defaultState = () => ({
  status: new StateMachine({
    init: 'idle',
    transitions: [
      { name: 'load', from: '*', to: 'loading' },
      { name: 'success', from: 'loading', to: 'success' },
      { name: 'error', from: 'loading', to: 'error' },
    ],
  }),
  items: [],
  notes: [],
  errors: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    isLoaded: (state, isLoaded) => (state.isLoaded = isLoaded),
    items: (state, items) => (state.items = items),
    notes: (state, notes) => (state.notes = notes),
    errors: (state, error) => state.errors.push(error),
  },
  actions: {
    async load({ commit, state }) {
      state.status.load();
      try {
        const addons = await AddonsService.list();
        // Transform it addons to standardized format
        await commit(
          'items',
          addons.data.data.map(addon => {
            const newAddon = _.pick(addon, ['name', 'code']);
            newAddon.fees = addon.addon_fee_items.map(afi =>
              _.pick(afi, ['name', 'code', 'amount'])
            );
            newAddon.notes = addon.addon_notes.map(an =>
              _.pick(an, ['label', 'field', 'field_type'])
            );
            return newAddon;
          })
        );
        state.status.success();
      } catch (err) {
        commit('errors', err);
        state.status.error();
      }
    },
  },
};
