<template>
  <v-ons-page modifier="gp" @show="reload">
    <page-toolbar :pageStack="pageStack" />

    <div class="search-form-container">
      <div class="gp-form__label">Customer</div>
      <customer-search-form
        :search-terms="searchTerms"
        @search="handleSearch"
      />
      <div class="search-form">
        <div v-if="loading.length <= 0 && totalPage > 1">
          <paginate
            v-model="currentPage"
            :pageCount="totalPage"
            :containerClass="'gp-pagination'"
            :page-class="'gp-pagination__item'"
            :active-class="'gp-pagination__item--active'"
            :prev-class="'gp-pagination__prev'"
            :next-class="'gp-pagination__next'"
            :click-handler="handleGotoPage"
          ></paginate>
        </div>

        <section v-if="errors.length > 0">
          <p class="gp-body--secondary gp-body--alert">
            We're sorry, we're not able to retrieve this information at the
            moment, please try back later...
          </p>
        </section>

        <div v-if="loading.length <= 0">
          <listing name="customer">
            <customer-list-item
              v-for="customer in customers"
              :key="customer.id"
              :customer="customer"
              @quote="openCreatePolicyDialog"
              @view="gotoUpsertCustomer"
              @list-vehicle="gotoVehicleList"
            />
          </listing>
        </div>
      </div>
    </div>

    <v-ons-dialog
      cancelable
      :visible.sync="createPolicyDialogVisible"
      modifier="gp"
    >
      <vehicle-selection-form @next="gotoQuoteFormStep1">
        <template #cancel>
          <v-ons-button modifier="gp" @click="closeCreatePolicyDialog">
            Cancel
          </v-ons-button>
        </template>
      </vehicle-selection-form>
    </v-ons-dialog>

    <v-ons-bottom-toolbar modifier="gp">
      <v-ons-toolbar-button modifier="gp">
        <v-ons-button @click="gotoUpsertCustomer" modifier="gp"
          >Add more</v-ons-button
        >
      </v-ons-toolbar-button>
    </v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import CustomerSearchForm from '@/components/CustomerSearchForm.vue';
import CustomerListItem from '@/components/CustomerListItem.vue';
import Listing from '@/components/Listing.vue';
import VehicleSelectionForm from '@/components/VehicleSelectionForm.vue';

// Views
import UpsertCustomer from '@/views/client/UpsertCustomer.vue';
import Vehicles from '@/views/client/Vehicles.vue';
import QuoteFormStep1 from '@/views/motor-insurance/QuoteFormStep1.vue';

// Utilities and config
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';

export default {
  props: ['pageStack'],
  data() {
    return {
      childKey: 0,
      createPolicyDialogVisible: false,
    };
  },
  components: {
    CustomerSearchForm,
    Listing,
    CustomerListItem,
    PageToolbar,
    VehicleSelectionForm,
  },
  methods: {
    ...mapMutations({
      resetCustomers: 'customers/reset',
      updateSearch: 'customers/search',
      updatePageNum: 'customers/page',
    }),
    ...mapActions({
      loadCustomers: 'customers/load',
      loadCustomerOptions: 'customer_options/load',
    }),

    reload() {
      const { resetCustomers, loadCustomers, loadCustomerOptions } = this;
      resetCustomers();
      loadCustomers();
      loadCustomerOptions();
    },

    async handleSearch(searchTerms) {
      const { updateSearch, updatePageNum, loadCustomers } = this;
      await updateSearch(searchTerms);
      await updatePageNum(1);
      loadCustomers();
    },

    async handleGotoPage(pageNum) {
      const { updatePageNum, loadCustomers } = this;
      await updatePageNum(pageNum);
      loadCustomers();
    },

    async openCreatePolicyDialog(customer) {
      const $vm = this;
      $vm.createPolicyDialogVisible = true;
      await $vm.$store.commit('customer/reset');
      await $vm.$store.commit('customer/id', customer.id);
      $vm.$store.dispatch('customer/loadData');
    },

    closeCreatePolicyDialog() {
      const $vm = this;
      $vm.createPolicyDialogVisible = false;
    },

    async gotoQuoteFormStep1(vehicleId) {
      this.createPolicyDialogVisible = false;
      const { pageStack } = this;

      // Forward policy data to step 1
      pageStack.push({
        extends: QuoteFormStep1,
        pageStack,
        data() {
          return { loadVehicleId: vehicleId };
        },
      });
    },

    async gotoUpsertCustomer(customer) {
      const { pageStack } = this;

      pageStack.push({
        extends: UpsertCustomer,
        data() {
          return {
            id: customer ? customer.id : null,
          };
        },
      });
    },

    async gotoVehicleList(customer) {
      const { pageStack } = this;
      pageStack.push({
        extends: Vehicles,
        data() {
          return { customer };
        },
      });
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(pageNum) {
        const { updatePageNum } = this;
        updatePageNum(pageNum);
      },
    },
    ...mapState({
      customers: state => state.customers.data,
      totalPage: state =>
        Math.ceil(state.customers.totalItems / state.customers.perPage),
      page: state => state.customers.page,
      searchTerms: state => state.customers.search,
      errors: state => state.customers.errors,
      loading: state =>
        _.concat(state.shortcodes.loading, state.customers.loading),
    }),
  },
};
</script>
