<template>
  <div class="search-form">
    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label">Name / Vehicle reg no.</label>
        <v-ons-input
          class="gp-input"
          modifier="gp"
          placeholder="Vehicle reg no."
          v-model="searchTerms.all"
        ></v-ons-input>
      </div>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp" v-if="!!showCoverPeriod">
      <label class="gp-input__label">Expire Within</label>
      <v-ons-input
        class="gp-input"
        modifier="gp"
        placeholder="Expire within x days"
        v-model="searchTerms.expire_within_days"
        type="number"
      ></v-ons-input>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp" v-if="!!showStatus">
      <div class="center">
        <label class="gp-input__label">Status</label>
        <v-ons-select modifier="gp" v-model="searchTerms.status_code">
          <option
            v-for="status in statusOptions"
            :key="status.value"
            :value="status.value"
            >{{ status.label }}
          </option>
        </v-ons-select>
      </div>
    </v-ons-list-item>

    <div class="button-container">
      <v-ons-button modifier="gp" @click="handleSearch">Search</v-ons-button>
    </div>
  </div>
</template>

<script>
import { POLICY_STATUS_LIST } from '@/lib/constants';

export default {
  props: {
    showCoverPeriod: Boolean,
    showStatus: Boolean,
    searchOptions: {
      type: Object,
      default: () => ({
        status_code: [],
      }),
    },
    initSearchTerms: {
      type: Object,
      default: () => ({
        all: '',
        expire_within_days: 0,
        status_code: '',
      }),
    },
  },
  data() {
    const { initSearchTerms } = this;
    const searchTerms = {
      all: initSearchTerms.all,
      expire_within_days: initSearchTerms.expire_within_days,
      status_code: initSearchTerms.status_code,
    };
    return {
      statusOptions: [],
      searchTerms,
      dateFormat: 'yyyy-MM-dd',
    };
  },
  mounted() {
    this.buildStatusOptions();
  },
  watch: {
    searchOptions: {
      immediate: true,
      handler() {
        this.buildStatusOptions();
      },
    },
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.searchTerms);
    },
    buildStatusOptions() {
      const { searchOptions } = this;
      const statusOptions = searchOptions.status_code.map(s => {
        const statusConf = POLICY_STATUS_LIST.find(psl => psl.code === s);
        if (!statusConf) {
          return;
        }
        return {
          label: statusConf.label.toUpperCase(),
          value: statusConf.code,
        };
      });
      this.statusOptions = [
        { label: 'ALL', value: '' },
        ...statusOptions.filter(s => !!s),
      ];
    },
  },
};
</script>
