<template>
  <v-ons-page modifier="gp">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 5</div>
      </state-flag>

      <div class="gp-form__sublabel">
        PIAM Information
      </div>
      <dynamic-form-field
        v-if="schema.policyInfo.garage_code"
        label="Garage"
        :validator="$v.policyInfo.garage_code"
        :definition="schema.policyInfo.garage_code"
        :disabled="locked"
        @change="handleChange('policyInfo.garage_code')"
        :shortcodes="shortcodes.policyInfo"
      />
      <dynamic-form-field
        v-if="schema.policyInfo.safety_feature_code"
        label="Safety Feature"
        :validator="$v.policyInfo.safety_feature_code"
        :definition="schema.policyInfo.safety_feature_code"
        :disabled="locked"
        @change="handleChange('policyInfo.safety_feature_code')"
        :shortcodes="shortcodes.policyInfo"
      />
      <dynamic-form-field
        v-if="schema.policyInfo.anti_theft_code"
        label="Anti Theft"
        :validator="$v.policyInfo.anti_theft_code"
        :definition="schema.policyInfo.anti_theft_code"
        :disabled="locked"
        @change="handleChange('policyInfo.anti_theft_code')"
        :shortcodes="shortcodes.policyInfo"
      />
      <dynamic-form-field
        v-if="schema.policyInfo.permitted_driver_code"
        label="Permitted Driver"
        :validator="$v.policyInfo.permitted_driver_code"
        :definition="schema.policyInfo.permitted_driver_code"
        :disabled="locked"
        @change="handleChange('policyInfo.permitted_driver_code')"
        :shortcodes="shortcodes.policyInfo"
      />

      <div>
        <div class="gp-form__sublabel">
          Insured Claim History
        </div>
        <dynamic-form-field
          v-if="schema.policyInfo.damage_count"
          label="Damage of Vehicle"
          subtitle="Number of times vehicle has been damaged"
          :validator="$v.policyInfo.damage_count"
          :definition="schema.policyInfo.damage_count"
          :disabled="locked"
          @change="handleChange('policyInfo.damage_count')"
          :shortcodes="shortcodes.policyInfo"
        />
        <dynamic-form-field
          v-if="schema.policyInfo.windscreen_count"
          subtitle="Number of times windscreen has been damaged"
          label="Windscreen"
          :validator="$v.policyInfo.windscreen_count"
          :definition="schema.policyInfo.windscreen_count"
          :disabled="locked"
          @change="handleChange('policyInfo.windscreen_count')"
          :shortcodes="shortcodes.policyInfo"
        />
        <dynamic-form-field
          v-if="schema.policyInfo.theft_count"
          label="Theft"
          subtitle="Number of times vehicle is involved in theft"
          :validator="$v.policyInfo.theft_count"
          :definition="schema.policyInfo.theft_count"
          :disabled="locked"
          @change="handleChange('policyInfo.theft_count')"
          :shortcodes="shortcodes.policyInfo"
        />
        <dynamic-form-field
          v-if="schema.policyInfo.third_party_claim_count"
          label="Third Party Claim"
          subtitle="Number of times vehicle claimed third party insurance"
          :validator="$v.policyInfo.third_party_claim_count"
          :definition="schema.policyInfo.third_party_claim_count"
          :disabled="locked"
          @change="handleChange('policyInfo.third_party_claim_count')"
          :shortcodes="shortcodes.policyInfo"
        />

        <v-ons-list-item
          v-for="(dec, i) in policyInfo.declarations"
          v-bind:key="i"
          modifier="gp"
        >
          <div class="center">
            <label class="gp-input__label">
              {{ dec.question }}
            </label>
            <!-- Dynamically determine fields by answer_type -->
            <div
              v-if="dec.answer_type === 'boolean'"
              class="gp-select__container"
            >
              <v-ons-select
                v-model="policyInfo.declarations[i].answer"
                modifier="gp"
                :disabled="locked"
              >
                <option value="n">No</option>
                <option value="y">Yes</option>
              </v-ons-select>
            </div>
            <span v-if="dec.answer_type === 'string'">
              <v-ons-input
                type="text"
                class="gp-input gp-input--uppercase"
                modifier="gp"
                v-model="policyInfo.declarations[i].answer"
                :disabled="locked"
              ></v-ons-input>
            </span>
            <span v-if="dec.answer_type === 'number'">
              <v-ons-input
                type="number"
                class="gp-input gp-input"
                modifier="gp"
                v-model="policyInfo.declarations[i].answer"
                :disabled="locked"
              ></v-ons-input>
            </span>
          </div>
        </v-ons-list-item>
      </div>

      <!-- Call to actions -->
      <div class="button-container">
        <v-ons-button
          @click="gotoNextPage()"
          modifier="gp"
          :disabled="$v.$invalid"
          class="primary"
        >
          Next
        </v-ons-button>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import DynamicFormField from '@/components/DynamicFormField.vue';
import StateFlag from '@/components/StateFlag.vue';

// Views
import QuoteFormStep6 from '@/views/motor-insurance/QuoteFormStep6.vue';

// Utilities and config
import _ from 'lodash';
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';

export default {
  mixins: [QuoteFormMixin],
  props: ['pageStack'],
  components: {
    PageToolbar,
    DynamicFormField,
    StateFlag,
  },
  data: () => ({
    nextPage: QuoteFormStep6,
    confirmDispatchActions: ['policy/savePolicyInfo'],
  }),
  validations() {
    const { validators } = this;
    const v = {
      policyInfo: {
        safety_feature_code: _.get(
          validators,
          'policyInfo.safety_feature_code'
        ),
        anti_theft_code: _.get(validators, 'policyInfo.anti_theft_code'),
        permitted_driver_code: _.get(
          validators,
          'policyInfo.permitted_driver_code'
        ),
        damage_count: _.get(validators, 'policyInfo.damage_count'),
        windscreen_count: _.get(validators, 'policyInfo.windscreen_count'),
        theft_count: _.get(validators, 'policyInfo.theft_count'),
        third_party_claim_count: _.get(
          validators,
          'policyInfo.third_party_claim_count'
        ),
        garage_code: _.get(validators, 'policyInfo.garage_code'),
        //agreed_value: _.get(validators, 'policyInfo.agreed_value'),
      },
    };
    // Remove undefined / null validators
    v.policyInfo = _.omitBy(v.policyInfo, _.isNil);
    return v;
  },
  methods: {
    handleChange(field) {
      const { validate, $store } = this;
      $store.dispatch('policy/updateShortcodes', { field });
      validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-form-cotainer {
  margin-top: 32px;
}
</style>
