<template>
  <div>
    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label">Make</label>
        <div class="gp-select__container">
          <v-ons-input v-model="make" readonly modifier="gp" class="gp-input" />
        </div>
      </div>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp">
      <dynamic-form-field
        v-if="schema.model_code"
        label="Model"
        :validator="$v.vehicle.model_code"
        :definition="schema.model_code"
        :shortcodes="shortcodes"
        :disabled="disabled"
        @change="handleVehicleChange"
      />
    </v-ons-list-item>

    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label">Variant</label>
        <div class="gp-select__container">
          <v-ons-select
            @change="handleVariantChange"
            v-model="policy.variant_id"
            modifier="gp"
          >
            <option v-for="v in variants" :value="v.id" :key="v.id">{{
              v.variant
            }}</option>
          </v-ons-select>
        </div>
      </div>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label">Market value</label>
        <div class="gp-select__container">
          <v-ons-input
            v-model="marketValue"
            float
            class="gp-input"
            modifier="gp"
            :readonly="true"
          ></v-ons-input>
        </div>
      </div>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp" v-if="policy.variant_id">
      <div class="center">
        <label class="gp-input__label">Sum insured</label>
        <v-ons-select
          v-model="policy.sum_insured"
          modifier="gp"
          @change="
            $emit('change', {
              field: 'policy.sum_insured',
              value: $event.target.value,
            })
          "
        >
          <option v-for="sum in sumOptions" :value="sum" :key="sum">{{
            sum
          }}</option>
        </v-ons-select>
      </div>
    </v-ons-list-item>

    <div class="button-container">
      <v-ons-button
        v-if="policy.sum_insured > 0"
        modifier="gp"
        @click="$emit('next')"
        >Next</v-ons-button
      >
    </div>
  </div>
</template>

<script>
import DynamicFormField from '@/components/DynamicFormField.vue';
import _ from 'lodash';

export default {
  components: { DynamicFormField },
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    validators: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shortcodes: {
      type: Object,
      required: true,
    },
    vehicle: {
      type: Object,
      required: true,
      validator(value) {
        return _.has(value, 'model_code') && _.has(value, 'make_code');
      },
    },
    policy: {
      type: Object,
      required: true,
      validator(value) {
        return _.has(value, 'variant_id') && _.has(value, 'sum_insured');
      },
    },
  },
  data() {
    return {
      marketValue: 0,
      sumOptions: [],
      //selected: {
      //  variantId: '',
      //  sumInsured: 0,
      //},
      make: '',
    };
  },
  validations() {
    return {
      vehicle: {
        model_code: _.has(this.validators, 'model_code')
          ? _.get(this.validators, 'model_code')
          : {},
      },
    };
  },
  watch: {
    variants() {
      const selectedVariant = this.policy.variant_id;
      this.handleVariantChange({ target: { value: selectedVariant } });
    },
    vehicle() {
      const { schema, shortcodes, vehicle } = this;
      if (_.has(schema, 'make_code.value_type')) {
        const category = schema.make_code.value_type.replace('shortcode|', '');
        if (!shortcodes[category]) {
          return;
        }
        const makeShortcode = shortcodes[category].find(
          s => s.shortcode === vehicle.make_code
        );
        this.make = makeShortcode.description;
      }
    },
  },
  methods: {
    handleVariantChange(e) {
      const $vm = this;

      const variant = $vm.variants.find(v => v.id === e.target.value);
      $vm.sumOptions = variant.acceptable_sum_insured;
      $vm.marketValue = variant.market_value;

      this.$emit('change', {
        field: 'policy.variant_id',
        value: variant.id,
      });
    },

    handleVehicleChange() {
      this.$emit('change', {
        field: 'vehicle.model_code',
        value: this.$v.vehicle.model_code,
      });
    },
  },
};
</script>
