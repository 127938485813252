<template>
  <v-ons-page modifier="gp">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 6</div>
      </state-flag>

      <div>
        <div v-for="addon in availableAddons" :key="`addon-${addon.code}`">
          <addon-field
            :addon="addon"
            :feeDefs="addon.fees"
            :noteDefs="addon.notes"
            :selected="addons.find(a => addon.code === a.code)"
            :disabled="locked"
            @enable="enableAddon(addon)"
            @disable="disableAddon(addon)"
            @feeUpdated="handleAddonFeeUpdate"
            @noteUpdated="handleAddonNoteUpdate"
            :isValid.sync="isAddonValid"
          />
        </div>
      </div>

      <extra-benefits
        :extraBenefitConfig="extraBenefitConfig"
        :defaultExtraBenefits="extraBenefits"
        :disabled="locked"
        v-bind:validation.sync="ebcValidation"
        @change="handleExtraBenefitsChange"
      >
        <additional-drivers
          v-if="excess"
          :policyHolderInfo="policyHolderInfo"
          :additionalDrivers="additionalDrivers"
          :policyExcess="excess"
          :disabled="locked"
          @add="addAdditionalDriver"
          @edit="editAdditionalDriver"
          @delete="deleteAdditionalDriver"
        />
      </extra-benefits>

      <!-- Call to actions -->
      <div class="button-container">
        <v-ons-button
          @click="handleNextPage"
          modifier="gp"
          class="primary"
          :disabled="!isAddonValid || ebcValidation.$invalid"
        >
          Next
        </v-ons-button>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import AdditionalDrivers from '@/components/AdditionalDrivers.vue';
import ExtraBenefits from '@/components/ExtraBenefits.vue';
import AddonField from '@/components/AddonField';
import StateFlag from '@/components/StateFlag.vue';

// Views
import Payment from '@/views/payment/Payment.vue';

// Utilites and config
import { mapState, mapActions, mapMutations } from 'vuex';
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';
import _ from 'lodash';
import moment from 'moment';

export default {
  mixins: [QuoteFormMixin],
  props: ['pageStack'],
  components: {
    PageToolbar,
    AdditionalDrivers,
    ExtraBenefits,
    AddonField,
    StateFlag,
  },
  data: () => ({
    nextPage: Payment,
    ignoreLock: true, // Dispatch action regardless of locks
    ebcValidation: {},
    confirmDispatchActions: [
      'policy/saveAddons',
      'policy/saveExtraBenefits',
      'policy/loadPremiumAmounts',
      'policy/requestQuotation',
    ],
    nextPageHook: {
      async before(errors) {
        if (_.isEmpty(errors)) {
          await this.pageStack.splice(1);
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      },
    },
    isAddonValid: null,
  }),
  mounted() {
    const {
      isAddonsLoaded,
      loadAddons,
      policyInfo,
      confirmDispatchActions,
    } = this;
    // Load addons if required
    if (!isAddonsLoaded) {
      loadAddons();
    }
    // If status is not draft, do not save addons or load premium amounts
    if (policyInfo.status_code !== '01') {
      this.confirmDispatchActions = confirmDispatchActions.filter(
        da => ['policy/saveAddons', 'policy/loadPremiumAmounts'].indexOf(da) < 0
      );
    }
  },
  methods: {
    ...mapActions({
      loadAddons: 'addons/load',
    }),
    ...mapMutations({
      pushAddon: 'policy/pushAddon',
      removeAddon: 'policy/removeAddon',
      updateAddonFees: 'policy/updateAddonFees',
      updateAddonNotes: 'policy/updateAddonNotes',
    }),

    enableAddon(addon) {
      const { pushAddon } = this;
      pushAddon(addon);
    },

    disableAddon(addon) {
      const { removeAddon } = this;
      removeAddon(addon.code);
    },

    handleAddonFeeUpdate({ addon, fees }) {
      const { updateAddonFees } = this;
      updateAddonFees({ addonCode: addon.code, fees });
    },

    handleAddonNoteUpdate({ addon, notes }) {
      const { updateAddonNotes } = this;
      updateAddonNotes({ addonCode: addon.code, notes });
    },

    async deleteAdditionalDriver(driverIndex) {
      const $vm = this;

      await $vm.$store.commit('policy/removeAdditionalDriverAt', driverIndex);
      await $vm.$store.dispatch('policy/saveAdditionalDrivers');
    },

    async handleNextPage() {
      const { $ons, gotoNextPage, policyInfo } = this;
      const expiryDate = moment(policyInfo.cover_from).format('DD-MM-YYYY');
      const confirm = await $ons.notification.confirm(`
        <p>Quotation will expire by end of cover period start date on ${expiryDate},
        so please make your payment before <strong>${expiryDate} 23:59 hrs</strong>, proceed?</p>
      `);
      if (confirm) {
        gotoNextPage({ policyId: policyInfo.id });
      }
    },

    async addAdditionalDriver(data) {
      const $vm = this;

      //$vm.additionalDriverFormVisible = false;
      await $vm.$store.commit('policy/appendAdditionalDriver', {
        driver_name: data.name,
        id_no: data.idNo,
        relationship_code: data.relationship,
        age: Number(data.age),
        driving_years: Number(data.drivingYears),
        occupation_code: data.occupation,
      });
      await $vm.$store.dispatch('policy/saveAdditionalDrivers');
    },

    async editAdditionalDriver(data) {
      const $vm = this;
      await $vm.$store.commit('policy/editAdditionalDriver', {
        index: data.index,
        driver_name: data.name,
        id_no: data.idNo,
        relationship_code: data.relationship,
        age: Number(data.age),
        driving_years: Number(data.drivingYears),
        occupation_code: data.occupation,
      });
      await $vm.$store.dispatch('policy/saveAdditionalDrivers');
    },

    async handleExtraBenefitsChange(eb) {
      await this.$store.commit('policy/extraBenefits', eb);
      //await this.$store.dispatch('policy/saveExtraBenefits');
    },
  },
  computed: mapState({
    quotationNo: state => state.policy.policyInfo.quotation_no,
    additionalDrivers: state => state.policy.additionalDrivers,
    extraBenefitConfig: state => state.policy.extraBenefitConfig,
    extraBenefits: state => state.policy.extraBenefits,
    addons: state => state.policy.addons,
    isAddonsLoaded: state => state.addons.state === 'success',
    availableAddons: state => state.addons.items,
    excess: state => state.policy.policyExcess,
  }),
};
</script>

<style lang="scss" scoped>
.quote-form-cotainer {
  margin-top: 32px;
}
</style>
