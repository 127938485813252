<template>
  <ons-form-group
    v-if="$v.note.value"
    :validator="$v.note.value"
    :label="definition.label"
  >
    <textarea
      v-model="$v.note.value.$model"
      type="text"
      class="text-input--gp__multiline"
      modifier="gp"
      :disabled="disabled"
      @keyup="handleNoteChange(note)"
    ></textarea>
  </ons-form-group>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    disabled: Boolean,
    note: Object,
    definition: Object,
  },

  validations() {
    const { disabled } = this;
    if (!disabled) {
      return {
        note: {
          value: { required },
        },
      };
    }
    return { note: {} };
  },

  mounted() {
    this.$emit('update:validator', !this.$v.$invalid);
  },

  watch: {
    'note.value'() {
      this.$v.$touch();
    },
    definition() {
      this.$v.$touch();
    },
    '$v.$invalid'() {
      this.$emit('update:validator', !this.$v.$invalid);
    },
  },

  methods: {
    handleNoteChange(note) {
      this.$emit('updated', note);
    },
  },
};
</script>
