<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="vehicle-form-container">
      <div class="gp-form__label">Vehicle Details</div>

      <vehicle-form
        :vehicle="vehicle"
        :schema="schema"
        :validators="validators"
        :disabled="locked"
        :shortcodes="shortcodes"
        @change="handleChange"
        v-bind:validation.sync="validation"
      />

      <div class="button-container">
        <div class="button-container container--50 mx-8" v-if="!!vehicle.id">
          <v-ons-button
            v-if="!!vehicle.id"
            class="button--gp"
            v-on:click="onEditSwitch"
            modifier="gp"
            >{{ locked ? 'Edit' : 'Cancel' }}</v-ons-button
          >
        </div>
        <div
          class="button-container"
          :class="{
            'container--50 mx-8': !!vehicle.id,
            'container--100': !vehicle.id,
          }"
        >
          <v-ons-button
            class="button--gp primary"
            v-on:click="save"
            modifier="gp"
            :disabled="locked || validation.$invalid"
          >
            Confirm
          </v-ons-button>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import VehicleForm from '@/components/VehicleForm.vue';

// Utilities and config
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  props: ['pageStack'],
  components: {
    PageToolbar,
    VehicleForm,
  },
  data() {
    return {
      id: null,
      customer_id: null,
      validation: {},
    };
  },
  watch: {
    serverErrors() {
      const { $ons } = this;
      if (this.serverErrors.length > 0) {
        const message = this.serverErrors[0];
        $ons.notification.alert(message);
      }
    },
  },
  methods: {
    ...mapMutations({
      resetVehicle: 'vehicle/reset',
      commitVehicleId: 'vehicle/id',
      commitCustomerId: 'vehicle/customerId',
      commitVehicle: 'vehicle/vehicle',
      commitIsLocked: 'vehicle/isLocked',
    }),
    ...mapActions({
      loadVehicle: 'vehicle/loadData',
      rebuildShortcodes: 'vehicle/rebuildShortcodes',
      updateShortcodes: 'vehicle/updateShortcodes',
      loadSchema: 'vehicle/loadSchema',
      saveVehicle: 'vehicle/save',
    }),

    async handlePageShow() {
      const {
        id,
        customer_id,
        checkSchema,
        resetVehicle,
        commitVehicleId,
        commitCustomerId,
        loadVehicle,
        rebuildShortcodes,
      } = this;
      await resetVehicle();
      if (id) {
        await commitVehicleId(id);
        await loadVehicle();
      } else if (customer_id) {
        await commitCustomerId(customer_id);
      }
      await rebuildShortcodes();
      checkSchema();
    },

    async handleChange(field) {
      const { checkSchema, validation, commitVehicle, updateShortcodes } = this;
      await commitVehicle(validation.$model);
      await updateShortcodes({ field });
      const reloadSchemaFields = [
        'condition_code',
        'class_code',
        'unit_type_code',
      ];
      if (_.indexOf(reloadSchemaFields, field) > -1) {
        checkSchema();
      }
    },

    async checkSchema() {
      const { vehicle, loadSchema } = this;
      if (vehicle.condition_code && vehicle.class_code) {
        await loadSchema('vehicle/loadSchema');
      }
    },

    async save(e) {
      const {
        vehicle,
        serverErrors,
        $ons,
        saveVehicle,
        pageStack,
        commitIsLocked,
      } = this;
      e.preventDefault();
      const isEdit = !!vehicle.id;
      await saveVehicle();
      if (serverErrors.length <= 0) {
        let message =
          'You have successfully added a new vehicle into your account.';
        if (isEdit) {
          message = 'Vehicle updated';
          commitIsLocked(true);
        } else {
          pageStack.pop();
        }
        $ons.notification.toast(message, { timeout: 3000 });
      }
    },

    onEditSwitch() {
      const { locked, commitIsLocked } = this;
      commitIsLocked(!locked);
    },
  },
  computed: {
    ...mapState({
      schema: state => state.vehicle.schema,
      validators: state => state.vehicle.validators.vehicle,
      vehicle: state => state.vehicle.vehicle,
      locked: state => state.vehicle.isLocked,
      serverErrors: state => state.vehicle.serverErrors,
      shortcodes: state => state.vehicle.shortcodes,
    }),
  },
};
</script>

<style lang="scss" scoped>
.vehicle-form {
  margin-top: 32px;
  margin-bottom: 16px;
}

.vehicle-form-container {
  margin-top: 32px;
}
</style>
