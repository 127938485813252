<template>
  <div class="vehicle-form" v-if="Object.keys(schema).length > 0">
    <dynamic-form-field
      v-if="schema.class_code"
      label="Vehicle Class"
      :validator="$v.vehicle.class_code"
      :definition="schema.class_code"
      :key="
        `class_code-${schema.class_code ? schema.class_code.value_type : 0}`
      "
      :disabled="disabled || !!disabledFields.find(df => df === 'class_code')"
      :shortcodes="shortcodes"
      @change="handleChange('class_code')"
    />
    <dynamic-form-field
      v-if="schema.condition_code"
      label="Condition"
      :validator="$v.vehicle.condition_code"
      :definition="schema.condition_code"
      :key="
        `condition_code-${
          schema.condition_code ? schema.condition_code.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'condition_code')
      "
      :shortcodes="shortcodes"
      @change="handleChange('condition_code')"
    />
    <dynamic-form-field
      v-if="schema.car_type_code"
      label="Vehicle Type"
      :validator="$v.vehicle.car_type_code"
      :definition="schema.car_type_code"
      :key="
        `car_type_code-${
          schema.car_type_code ? schema.car_type_code.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'car_type_code')
      "
      :shortcodes="shortcodes"
      @change="handleChange('car_type_code')"
    />
    <dynamic-form-field
      v-if="schema.use_state_code"
      label="Place of Use"
      :validator="$v.vehicle.use_state_code"
      :definition="schema.use_state_code"
      :key="
        `use_state_code-${
          schema.use_state_code ? schema.use_state_code.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'use_state_code')
      "
      :shortcodes="shortcodes"
      @change="handleChange('use_state_code')"
    />
    <dynamic-form-field
      v-if="schema.registration_no"
      label="Vehicle Reg. No"
      transform="uppercase"
      :validator="$v.vehicle.registration_no"
      :definition="schema.registration_no"
      :key="
        `registration_no-${
          schema.registration_no ? schema.registration_no.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'registration_no')
      "
      :shortcodes="shortcodes"
      @change="handleChange('registration_no')"
    />
    <dynamic-form-field
      v-if="schema.make_code"
      label="Make"
      :validator="$v.vehicle.make_code"
      :definition="schema.make_code"
      :key="`make_code-${schema.make_code ? schema.make_code.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'make_code')"
      :shortcodes="shortcodes"
      @change="handleChange('make_code')"
    />
    <dynamic-form-field
      v-if="schema.model_code"
      label="Model"
      :validator="$v.vehicle.model_code"
      :definition="schema.model_code"
      :key="
        `model_code-${schema.model_code ? schema.model_code.value_type : 0}`
      "
      :disabled="disabled || !!disabledFields.find(df => df === 'model_code')"
      :shortcodes="shortcodes"
      @change="handleChange('model_code')"
    />
    <dynamic-form-field
      v-if="schema.chassis_no"
      label="Chassis No."
      :validator="$v.vehicle.chassis_no"
      :definition="schema.chassis_no"
      :key="
        `chassis_no-${schema.chassis_no ? schema.chassis_no.value_type : 0}`
      "
      :disabled="disabled || !!disabledFields.find(df => df === 'chassis_no')"
      :shortcodes="shortcodes"
      @change="handleChange('chassis_no')"
    />
    <dynamic-form-field
      v-if="schema.engine_no"
      label="Engine No."
      :validator="$v.vehicle.engine_no"
      :definition="schema.engine_no"
      :key="`engine_no-${schema.engine_no ? schema.engine_no.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'engine_no')"
      :shortcodes="shortcodes"
      @change="handleChange('engine_no')"
    />
    <dynamic-form-field
      v-if="schema.year"
      label="Year"
      :validator="$v.vehicle.year"
      :definition="schema.year"
      :key="`year-${schema.year ? schema.year.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'year')"
      :shortcodes="shortcodes"
      @change="handleChange('year')"
    />

    <!-- engine/motor/load/unit capacity -->
    <div
      v-if="schema.unit_type_code"
      class="container--flex align-items--flex-start form-item__container justify--between"
    >
      <dynamic-form-field
        v-if="schema.engine_capacity"
        label="Engine / Electrical Capacity"
        :validator="$v.vehicle.engine_capacity"
        :definition="schema.engine_capacity"
        :key="
          `engine_capacity-${
            schema.engine_capacity ? schema.engine_capacity.value_type : 0
          }`
        "
        :disabled="
          disabled || !!disabledFields.find(df => df === 'engine_capacity')
        "
        :shortcodes="shortcodes"
        @change="handleChange('engine_capacity')"
      />
      <dynamic-form-field
        v-if="schema.motor_capacity"
        label="Engine / Electrical Capacity"
        :validator="$v.vehicle.motor_capacity"
        :definition="schema.motor_capacity"
        :key="
          `motor_capacity-${
            schema.motor_capacity ? schema.motor_capacity.value_type : 0
          }`
        "
        :disabled="
          disabled || !!disabledFields.find(df => df === 'motor_capacity')
        "
        :shortcodes="shortcodes"
        @change="handleChange('motor_capacity')"
      />
      <dynamic-form-field
        v-if="schema.load_capacity"
        label="Load Capacity"
        :validator="$v.vehicle.load_capacity"
        :definition="schema.load_capacity"
        :key="
          `load_capacity-${
            schema.load_capacity ? schema.load_capacity.value_type : 0
          }`
        "
        :disabled="
          disabled || !!disabledFields.find(df => df === 'motor_capacity')
        "
        :shortcodes="shortcodes"
        @change="handleChange('load_capacity')"
      />
      <dynamic-form-field
        v-if="schema.unit_capacity"
        label="Unit Capacity"
        :validator="$v.vehicle.unit_capacity"
        :definition="schema.unit_capacity"
        :key="
          `unit_capacity-${
            schema.unit_capacity ? schema.unit_capacity.value_type : 0
          }`
        "
        :disabled="
          disabled || !!disabledFields.find(df => df === 'unit_capacity')
        "
        :shortcodes="shortcodes"
        @change="handleChange('unit_capacity')"
      />
      <div class="container--25 ml-8">
        <dynamic-form-field
          v-if="schema.unit_type_code"
          label="Unit Type"
          :validator="$v.vehicle.unit_type_code"
          :definition="schema.unit_type_code"
          :key="
            `unit_type_code-${
              schema.unit_type_code ? schema.unit_type_code.value_type : 0
            }`
          "
          :disabled="
            disabled || !!disabledFields.find(df => df === 'unit_type_code')
          "
          :shortcodes="shortcodes"
          @change="handleChange('unit_type_code')"
        />
      </div>
    </div>
    <!-- end engine/motor/load/unit capacity -->

    <dynamic-form-field
      v-if="schema.voc_no"
      label="VOC No."
      :validator="$v.vehicle.voc_no"
      :definition="schema.voc_no"
      :key="`voc_no-${schema.voc_no ? schema.voc_no.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'voc_no')"
      :shortcodes="shortcodes"
      @change="handleChange('voc_no')"
    />
    <dynamic-form-field
      v-if="schema.assembler_code"
      label="Vehicle Origin"
      :validator="$v.vehicle.assembler_code"
      :definition="schema.assembler_code"
      :key="
        `assembler_code-${
          schema.assembler_code ? schema.assembler_code.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'assembler_code')
      "
      :shortcodes="shortcodes"
      @change="handleChange('assembler_code')"
    />
    <dynamic-form-field
      v-if="schema.body_code"
      label="Body Type"
      :validator="$v.vehicle.body_code"
      :definition="schema.body_code"
      :key="`body_code-${schema.body_code ? schema.body_code.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'body_code')"
      :shortcodes="shortcodes"
      @change="handleChange('body_code')"
    />
    <dynamic-form-field
      v-if="schema.fuel_code"
      label="Bahan Bakar"
      :validator="$v.vehicle.fuel_code"
      :definition="schema.fuel_code"
      :key="`fuel_code-${schema.fuel_code ? schema.fuel_code.value_type : 0}`"
      :disabled="disabled || !!disabledFields.find(df => df === 'fuel_code')"
      :shortcodes="shortcodes"
      @change="handleChange('fuel_code')"
    />
    <dynamic-form-field
      v-if="schema.seating_capacity"
      label="Seating Capacity (Driver + Passengers)"
      :validator="$v.vehicle.seating_capacity"
      :definition="schema.seating_capacity"
      :key="
        `seating_capacity-${
          schema.seating_capacity ? schema.seating_capacity.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'seating_capacity')
      "
      :shortcodes="shortcodes"
      @change="handleChange('seating_capacity')"
    />
    <dynamic-form-field
      v-if="schema.purchase_price"
      label="Vehicle Purchase Price"
      :validator="$v.vehicle.purchase_price"
      :definition="schema.purchase_price"
      :key="
        `purchase_price-${
          schema.purchase_price ? schema.purchase_price.value_type : 0
        }`
      "
      :disabled="
        disabled || !!disabledFields.find(df => df === 'purchase_price')
      "
      :shortcodes="shortcodes"
      @change="handleChange('purchase_price')"
    />
    <dynamic-form-field
      v-if="schema.status_code && variant === 'full'"
      label="Status"
      :validator="$v.vehicle.status_code"
      :definition="schema.status_code"
      :key="
        `status_code-${schema.status_code ? schema.status_code.value_type : 0}`
      "
      :disabled="disabled || !!disabledFields.find(df => df === 'status_code')"
      :shortcodes="shortcodes"
      @change="handleChange('status_code')"
    />
  </div>
</template>

<script>
// Page components
import DynamicFormField from '@/components/DynamicFormField.vue';

export default {
  props: {
    vehicle: Object,
    schema: Object,
    validators: Object,
    disabled: Boolean,
    shortcodes: Object,
    variant: {
      type: String,
      default: 'full',
      validator: value => ['minimal', 'full'].indexOf(value) !== -1,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DynamicFormField,
  },
  validations() {
    return { vehicle: this.validators };
  },
  watch: {
    validators() {
      this.$v.$reset();
      this.updateValidations();
    },
  },
  mounted() {
    this.updateValidations();
  },
  methods: {
    updateValidations() {
      this.$v.vehicle.$touch();
      this.$emit('update:validation', this.$v.vehicle);
    },
    handleChange(field) {
      this.updateValidations();
      this.$v.$touch();
      this.$emit('change', field);
    },
  },
};
</script>
