// Utilities
//import _ from 'lodash';

// Services
import PresetsService from '@/services/presets';

const defaultState = () => ({
  loading: [],
  data: [],
  isLoaded: false,
  errors: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      const options = [].concat(state.options);
      // Prevent options from being assigned
      Object.assign(state, defaultState(), { options });
    },
    switchLoading(state, loadingLabel = '') {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    isLoaded: (state, isLoaded) => (state.isLoaded = isLoaded),
    data: (state, data = []) => (state.data = data),
    errors: (state, data) => (state.errors = data),
  },
  actions: {
    async load({ commit }) {
      commit('switchLoading', 'presets');
      const response = await PresetsService.get()
        .then(response => response.data)
        .catch(err => commit('errors', [`${err.name}: ${err.message}`]))
        .finally(() => {
          commit('switchLoading', 'presets');
          commit('isLoaded', true);
        });
      commit('data', response.data);
    },
  },
};
