<template>
  <div>
    <ons-form-group :validator="$v.customer.id" label="Customer">
      <div class="gp-select__container">
        <v-ons-select
          v-model="$v.customer.id.$model"
          modifier="gp"
          @change="handleCustomerChange()"
        >
          <option v-for="v in customers" :value="v.value" :key="v.value">
            {{ v.label }}
          </option>
        </v-ons-select>
      </div>
    </ons-form-group>

    <ons-form-group
      :validator="$v.vehicleId"
      label="Vehicle Reg. No. / Chassis No."
    >
      <div class="gp-select__container">
        <v-ons-select
          v-model="$v.vehicleId.$model"
          modifier="gp"
          :disabled="vehicles.length <= 0"
        >
          <option v-for="v in vehicles" :value="v.id" :key="v.id">
            {{ v.registration_no ? v.registration_no : v.chassis_no }}
          </option>
        </v-ons-select>
      </div>
    </ons-form-group>

    <div>
      <div class="button-container container--100 my-8">
        <v-ons-button
          modifier="gp"
          class="button--gp primary"
          @click="$emit('next', vehicleId)"
          :disabled="$v.$invalid"
        >
          Next
        </v-ons-button>
      </div>

      <div
        class="button-container container--100 my-8"
        v-for="name in Object.keys($slots)"
        :key="name"
      >
        <slot :name="name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

export default {
  mounted() {
    const $vm = this;
    if ($vm.customers.length <= 0) {
      $vm.loadCustomers();
    }
    $vm.validate();
  },
  data() {
    return {
      vehicleId: '',
    };
  },
  validations() {
    return {
      customer: {
        id: { required },
      },
      vehicleId: { required },
    };
  },
  methods: {
    handleCustomerChange() {
      const $vm = this;
      $vm.vehicleId = '';
      $vm.loadVehicles();
      $vm.validate();
      this.$emit('update:customer_id', $vm.customer.id);
    },
    validate() {
      const $vm = this;
      $vm.$v.customer.id.$touch();
      $vm.$v.vehicleId.$touch();
    },
    async loadCustomers() {
      const $vm = this;
      $vm.$store.dispatch('customer_options/load');
    },
    async loadVehicles() {
      const $vm = this;
      $vm.$store.dispatch('customer/loadData');
    },
  },
  computed: mapState({
    customer: state => state.customer.customer,
    vehicles: state => state.customer.vehicles,
    customers: state => state.customer_options.items,
  }),
};
</script>
