import axios from '@/lib/axios';

function get() {
  return axios.gpAPI({
    method: 'GET',
    url: '/presets',
  });
}

export default {
  get,
};
