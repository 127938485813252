import axios from 'axios';
import { API_BASE_URL } from '@/lib/constants.js';

// Token has to be initialized first before gpAPI
const qs = new URLSearchParams(window.location.search);
if (qs.has('token')) {
  window.localStorage.setItem('token', qs.get('token'));
}

const gpAPI = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'X-Auth-Token': window.localStorage.getItem('token'),
  },
});

export default { gpAPI };
