<template>
  <div class="gp-list__item-container">
    <div class="container--flex align-items--center">
      <div class="gp-list__item-section container--50">
        <div
          class="gp-list__item-label gp-list__item-label--l"
          v-if="shouldShow('vehicleRegNo')"
        >
          {{
            vehicle.registration_no
              ? vehicle.registration_no
              : vehicle.chassis_no
          }}
        </div>
        <div class="gp-list__item-label" v-if="shouldShow('chassisNo')">
          {{ vehicle.chassis_no }}
        </div>
        <div class="gp-list__item-label" v-if="shouldShow('customerName')">
          {{ customer.name }}
        </div>
        <div class="gp-list__item" v-if="shouldShow('customerIdNo')">
          {{ customer.id_no ? customer.id_no : customer.company_regno }}
        </div>
        <div class="gp-list__item" v-if="shouldShow('mobileNo')">
          {{ customer.mobile_no }}
        </div>
        <div class="gp-list__item-active" v-if="shouldShow('vehicleStatus')">
          <strong>{{ status[vehicle.status_code] }}</strong>
        </div>
      </div>
      <div
        class="gp-list__item-ctas gp-list__item-section container--flex container--50"
      >
        <button
          v-if="shouldShow('viewButton')"
          @click="$emit('view', vehicle)"
          class="button-gp--pill button-gp--pill__blue"
        >
          View
        </button>
        <button
          v-if="shouldShow('quoteButton')"
          class="button-gp--pill button-gp--pill__orange"
          @click="$emit('quote', vehicle)"
        >
          Get Quote
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const MINIMAL_ELEMENTS = [
  'vehicleRegNo',
  'chassisNo',
  'vehicleStatus',
  'viewButton',
];

export default {
  props: {
    vehicle: Object,
    customer: Object,
    variant: {
      type: String,
      default: 'full',
      validator: value => ['minimal', 'full'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      status: { A: 'Active', X: 'Inactive' },
    };
  },
  methods: {
    shouldShow(elem) {
      const { variant } = this;
      if (variant === 'minimal') {
        return MINIMAL_ELEMENTS.indexOf(elem) > -1;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.gp-list__item-label {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.gp-list__item-label--l {
  font-size: 20px;
  line-height: 40px;
  /*line-height: 56px;*/
}

.gp-list__item-container {
  padding: 8px;
  margin: 0 -8px;
}

.gp-list__item-section {
  padding: 0 8px;
}

.gp-list__item-ctas {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
}
</style>
