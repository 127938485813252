// Utilities
import _ from 'lodash';
import moment from 'moment';
//import { required, integer, decimal } from 'vuelidate/lib/validators';
import { required, minValue, maxLength, email } from 'vuelidate/lib/validators';
import { validYear, maxAtCurrentYear } from '@/validators';
import * as shortcodeHelpers from '@/lib/shortcodeHelpers';

// Services
import PolicyService from '@/services/policy';
import PaymentService from '@/services/payment';

// Config
import { POLICY, POLICY_STATUS_LIST } from '@/lib/constants';
const { SHORTCODE_FIELDS } = POLICY;

// Configuration constants
// Considered success when returned status_code is:
// - 03 = pending payment
// - 13 = refer case: pending approval
//const QUOTE_REQUEST_SUCCESS_CODES = ['03', '13'];

const ERROR_TYPES = () => ({
  MINOR: 'minor', // Alert only but can continue
  MAJOR: 'major', // Major error, needs user correction
  CRITICAL: 'critical', // Critical error, cannot continue
  FATAL: 'fatal', // Fatal error from the server or frontend, needs to be fixed
});
const handleWeirdHttpStatus = commit => err => {
  if (_.has(err, 'response.data.name') && _.has(err, 'response.data.message')) {
    if (err.response.data.name === 'NoPolicyNumber') {
      return commit('errors', [
        {
          type: ERROR_TYPES().FATAL,
          message: `${err.response.data.message}, please contact us for more information.`,
        },
      ]);
    }
    commit('errors', [
      {
        type: ERROR_TYPES().FATAL,
        message: `${err.response.data.name}: ${err.response.data.message}`,
      },
    ]);
  } else {
    commit('errors', [
      {
        type: ERROR_TYPES().FATAL,
        message: `${err.response.status}: ${err.response.statusText}`,
      },
    ]);
  }
};

const handleResponse = commit => response => {
  // Parse response statuses
  const getResponseError = resStatus => {
    const [code, status] = resStatus.split('|');
    if (Number(code) >= 1 && Number(code) <= 9) {
      // Notify only, able to continue
      return {
        type: ERROR_TYPES().MINOR,
        message: `${status}`,
      };
    } else if (Number(code) >= 10 && Number(code) <= 29) {
      // Invalid data status 10-29 unable to continue
      return {
        type: ERROR_TYPES().MAJOR,
        message: `Got response ${code}: ${status}`,
      };
    } else if (Number(code) >= 30 && Number(code) <= 39) {
      // Renewal / record failures status 30-39 unable to continue
      return {
        type: ERROR_TYPES().CRITICAL,
        message: `Got response ${code}: ${status}`,
      };
    } else if (Number(code) >= 90 && Number(code) <= 99) {
      // Internal server error status 90-99 unable to continue
      return {
        type: ERROR_TYPES().FATAL,
        message: `Oops! Got server error ${code}: ${status}, please contact GoProtect!`,
      };
    }
    return null;
  };

  // Supports for both object and array response types
  const errors = [];
  if (response && Array.isArray(response.data)) {
    // Loop through array response and check that status exists for each array item,
    // return response data if response status is invalid
    for (const res of response.data) {
      if (!_.has(res, 'status')) {
        return response.data;
      }
      const error = getResponseError(res.status);
      if (!_.isNil(error) && !_.isNil(error.type)) {
        errors.push(error);
      }
    }
  } else if (response && typeof response.data === 'object') {
    // Only parse status if response status exists or return response data
    if (_.has(response, 'data.status')) {
      const error = getResponseError(response.data.status);
      if (!_.isNil(error) && !_.isNil(error.type)) {
        errors.push(error);
      }
    }
  } else {
    throw new Error('Unexpected response data type');
  }
  commit('errors', errors);
  return response.data;
};

//const vendorErrorsToInternalErrors = vendorErrors => {
//  return vendorErrors.map(err => ({
//    type: ERROR_TYPES().CRITICAL,
//    message: `Got invalid vendor response from ${err.provider_code}: ${err.response}`,
//  }));
//};

const processPolicyHolderInfo = policyHolderInfo =>
  Object.assign({}, policyHolderInfo, {
    driving_since: moment(policyHolderInfo.driving_since, 'YYYY-MM-DD').format(
      'YYYY'
    ),
  });
const processPolicyInfo = policyInfo => {
  let { agreed_value } = policyInfo;
  if (typeof agreed_value === 'boolean') {
    agreed_value = agreed_value ? '1' : '0';
  }
  return Object.assign({}, policyInfo, { agreed_value });
};

const handleAfterSave = async (state, commit, data, ignore = []) => {
  if (data) {
    await commit('policyInfo', processPolicyInfo(data));
    if (
      JSON.stringify(data.vehicle_info) !== JSON.stringify(state.vehicleInfo) &&
      !ignore.find(f => f === 'vehicleInfo')
    ) {
      await commit('vehicleInfo', data.vehicle_info);
    }
    if (
      JSON.stringify(data.policyHolderInfo) !==
        JSON.stringify(state.policyHolderInfo) &&
      !ignore.find(f => f === 'policyHolderInfo')
    ) {
      await commit(
        'policyHolderInfo',
        processPolicyHolderInfo(data.policy_holder_info)
      );
    }
    if (
      JSON.stringify(data.extra_benefit_config) !==
        JSON.stringify(state.extraBenefitConfig) &&
      !ignore.find(f => f === 'extraBenefitConfig')
    ) {
      await commit('extraBenefitConfig', data.extra_benefit_config);
    }
    if (
      JSON.stringify(data.extra_benefits) !==
        JSON.stringify(state.extraBenefits) &&
      !ignore.find(f => f === 'extraBenefits')
    ) {
      await commit('extraBenefits', data.extra_benefits);
    }
    if (
      JSON.stringify(data.additional_drivers) !==
        JSON.stringify(state.additionalDrivers) &&
      !ignore.find(f => f === 'additionalDrivers')
    ) {
      await commit('additionalDrivers', data.additional_drivers);
    }
    if (
      JSON.stringify(data.addons) !== JSON.stringify(state.addons) &&
      !ignore.find(f => f === 'addons')
    ) {
      await commit('addons', data.addons);
    }
    if (
      JSON.stringify(data.policy_excess) !==
        JSON.stringify(state.policyExcess) &&
      !ignore.find(f => f === 'additionalDrivers')
    ) {
      await commit('policyExcess', data.policy_excess);
    }
    if (
      JSON.stringify(data.premium_amounts) !==
        JSON.stringify(state.premium_amounts) &&
      !ignore.find(f => f === 'additionalDrivers')
    ) {
      await commit('premiumAmounts', data.premium_amounts);
    }
    //await commit('errors', vendorErrorsToInternalErrors(data.vendor_errors));
    await commit('quotable', data.can_quote);
  }
};

// Parse schema and validators
const buildSchema = (dispatch, serverSchema) => {
  // schema parser
  const validators = {};
  const schema = {};
  for (let field of Object.keys(serverSchema)) {
    if (!_.has(serverSchema, `${field}.value_type`)) {
      continue;
    }
    schema[field] = serverSchema[field];
    validators[field] = {};
    // Validators
    if (serverSchema[field].required) {
      validators[field].required = required;
    }
    // Years handler
    if (_.indexOf(['year', 'driving_since'], field) > -1) {
      schema[field].value_type = 'number';
      validators[field].validYear = validYear.validator;
      validators[field].maxAtCurrentYear = maxAtCurrentYear.validator;
    }
    // Sum insured
    if (_.indexOf(['sum_insured'], field) > -1) {
      validators[field].minValue = minValue(1);
    }
    // Postcode handler
    if (_.indexOf(['postcode'], field) > -1) {
      validators[field].maxLength = maxLength(5);
    }
    // Email handler
    if (_.indexOf(['email'], field) > -1) {
      validators[field].email = email;
    }
  }
  return { validators, schema };
};

const updateShortcodeValues = (shortcodes, state) => {
  const infoState = {
    policyInfo: _.cloneDeep(state.policyInfo),
    policyHolderInfo: _.cloneDeep(state.policyHolderInfo),
    vehicleInfo: _.cloneDeep(state.vehicleInfo),
  };
  for (const sf of SHORTCODE_FIELDS) {
    if (sf.ignore_default) {
      continue;
    }
    const [stateProp, field] = sf.field.split('.');
    const value = _.get(infoState, `${stateProp}.${field}`);
    const options = _.get(shortcodes, `${stateProp}.${sf.category}`);
    // Set only fields with invalid shortcode
    // TODO: find of undefined
    if (!options.find(s => s.shortcode === value)) {
      // Find default shortcode to use
      let defaultShortcode = options.find(s => s.is_default === true);
      // Set to first shortcode if no default value
      if (!defaultShortcode) {
        defaultShortcode = '';
      }
      // Update state
      infoState[stateProp][field] = defaultShortcode.shortcode;
    }
  }
  return infoState;
};

const updateShortcodeOptions = (rootShortcodes, shortcodes, state, field) => {
  const updatedShortcodes = _.cloneDeep(shortcodes);

  // Lookup field's shortcodes configuration
  const sf = SHORTCODE_FIELDS.find(sf => sf.field === field);
  if (!sf) {
    return updatedShortcodes;
  }

  // Retrieve full shortcodes
  const childrenShortcodes = shortcodeHelpers.getChildrenShortcodes(
    rootShortcodes,
    sf.category,
    _.get(state, field)
  );
  for (const category in childrenShortcodes) {
    const childrenSf = SHORTCODE_FIELDS.filter(sf => sf.category === category);
    for (const childSf of childrenSf) {
      const [stateProp] = childSf.field.split('.');
      updatedShortcodes[stateProp][category] = childrenShortcodes[category];
    }
  }
  return updatedShortcodes;
};

const defaultState = () => ({
  errorTypes: ERROR_TYPES(),
  schema: {
    policyHolderInfo: {},
    policyInfo: {},
    vehicleInfo: {},
  },
  validators: {
    policyHolderInfo: {},
    policyInfo: {},
    vehicleInfo: {},
  },
  policyInfo: {
    id: null,
    sum_insured: 0,
    ncd: null,
    declarations: [
      {
        question:
          'Committed any road offence involving your license being endorsed',
        answer: 'n',
        answer_type: 'boolean',
      },
    ],
    documents: [],
    provider_code: null,
    coverage_type_code: '',
    cover_from: '',
    vendor_cover_from: '',
    cover_to: '',
    vendor_cover_to: '',
    hpc_code: '',
    prev_vehicle_regno: '',
    safety_feature_code: '',
    anti_theft_code: '',
    permitted_driver_code: '',
    driving_years: 0,
    status_code: null,
    damage_count: 0,
    windscreen_count: 0,
    theft_count: 0,
    third_party_claim_count: 0,
    garage_code: '',
    quotation_no: null,
    policy_no: null,
    total_payable: 0,
    reference_no: '',
    variant_id: '',
  },
  vehicleInfo: {
    registration_no: '',
    use_state_code: 'SABAH',
    car_type_code: '',
    voc_no: '',
    chassis_no: '',
    engine_no: '',
    year: 0,
    make_code: '',
    model_code: '',
    class_code: '',
    assembler_code: '',
    purchase_price: 0,
    body_code: '',
    fuel_code: '',
    condition_code: '',
    unit_type_code: '',
    engine_capacity: null,
    motor_capacity: null,
    unit_capacity: null,
    load_capacity: null,
    seating_capacity: null,
  },
  policyHolderInfo: {
    customer_type: 'individual',
    company_regno: '',
    name: '',
    id_no: '',
    email: '',
    mobile_no: '',
    address1: '',
    address2: '',
    postcode: '',
    city: '',
    state_code: '',
    status_code: 'A',
    marital_status_code: '',
    ethnic_code: '',
    religion_code: '',
    occupation_code: '',
    driving_since: null,
    title_code: '',
    gender_code: '',
    dob: null,
    //customer_id: null,
  },
  extraBenefits: {
    llop: '',
    llopn: '',
    windscreen: '',
    spray_painting: '',
    inconvenience_allowance: '',
    perils_inclusion: '',
    mpa: '',
  },
  quotable: false,
  quotation: {
    quotation_no: null,
    total_payable: 0,
  },
  policyNo: null,
  prevVehicleNcd: {},
  loading: [],
  saving: [],
  extraBenefitConfig: [],
  premiumAmounts: [],
  marketValues: [],
  addons: [],
  policyExcess: {},
  errors: [],
  additionalDrivers: [],
  isLocked: false,
  shortcodes: {
    vehicleInfo: {},
    policyInfo: {},
    policyHolderInfo: {},
  },
  payment: {
    notice: null,
    isPaymentLinkEnabled: false,
    gateway: {
      gopay: {
        balance: null,
        isEnabled: false,
      },
      fpx: {
        balance: null,
        isEnabled: false,
      },
    },
  },
  agreed_value: null,
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      // NOTE: DO NOT RESET state.loading or state.saving!
      Object.assign(state, _.omit(defaultState(), ['loading', 'saving']));
    },
    switchLoading(state, loadingLabel) {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
    },
    switchSaving(state, savingDataLabel) {
      const labelIndex = state.saving.findIndex(l => l === savingDataLabel);
      if (labelIndex > -1) {
        state.saving.splice(labelIndex, 1);
      } else {
        state.saving.push(savingDataLabel);
      }
    },
    extraBenefitConfig(state, serverConf) {
      state.extraBenefitConfig = Object.keys(serverConf).map(conf => {
        let config = Object.assign(serverConf[conf], {
          key: conf,
          options:
            serverConf[conf].value_type === 'option'
              ? _.concat(serverConf[conf].options, {
                  label: 'Nope, Thanks!',
                  value: '',
                })
              : serverConf[conf].options,
        });
        if (conf === 'llop') {
          config = Object.assign(serverConf[conf], {
            label: 'LLP - Legal liability to passengers',
          });
        } else if (conf === 'llopn') {
          config = Object.assign(serverConf[conf], {
            label: 'LLOP - Legal liability of passengers',
          });
        } else if (conf === 'windscreen') {
          config = Object.assign(serverConf[conf], { label: 'Windscreen' });
        } else if (conf === 'spray_painting') {
          config = Object.assign(serverConf[conf], {
            label: 'Spray painting',
          });
        } else if (conf === 'inconvenience_allowance') {
          config = Object.assign(serverConf[conf], {
            label: 'Inconvenience allowance',
          });
        } else if (conf === 'perils_inclusion') {
          config = Object.assign(serverConf[conf], {
            label: 'Inclusion of special perils',
          });
        } else if (conf === 'mpa') {
          config = Object.assign(serverConf[conf], { label: 'MPA' });
        }
        return config;
      });
    },
    policyInfo(state, data) {
      const sanitizedData = _.omit(data, [
        'additional_drivers',
        'vehicle_info',
        'policy_holder_info',
        'can_quote',
        'extra_benefits',
        'extra_benefit_config',
        'premium_amounts',
        'market_values',
        'vendor_errors',
        'validation_errors',
      ]);
      Object.assign(sanitizedData, {
        cover_from: sanitizedData.cover_from
          ? moment(sanitizedData.cover_from, 'YYYY-MM-DD').toDate()
          : state.policyInfo.cover_from,
        vendor_cover_from: sanitizedData.vendor_cover_from
          ? moment(sanitizedData.vendor_cover_from, 'YYYY-MM-DD').toDate()
          : state.policyInfo.vendor_cover_from,
        cover_to: sanitizedData.cover_to
          ? moment(sanitizedData.cover_to, 'YYYY-MM-DD').toDate()
          : state.policyInfo.cover_to,
        vendor_cover_to: sanitizedData.vendor_cover_to
          ? moment(sanitizedData.vendor_cover_to, 'YYYY-MM-DD').toDate()
          : state.policyInfo.vendor_cover_to,
        prev_vehicle_regno: sanitizedData.prev_vehicle_regno
          ? sanitizedData.prev_vehicle_regno
          : state.vehicleInfo.registration_no,
      });
      state.policyInfo = Object.assign({}, state.policyInfo, sanitizedData);
    },
    vehicleInfo: (state, data) =>
      (state.vehicleInfo = Object.assign({}, state.vehicleInfo, data)),
    policyHolderInfo: (state, data) =>
      (state.policyHolderInfo = Object.assign(
        {},
        state.policyHolderInfo,
        data
      )),
    extraBenefits: (state, data) => {
      const newValues = Object.assign({}, state.extraBenefits, data);
      if (_.isEmpty(state.extraBenefitConfig)) {
        return;
      }
      for (const k of Object.keys(newValues)) {
        const config = state.extraBenefitConfig.find(ebc => ebc.key === k);
        if (config.value_type === 'boolean') {
          newValues[k] = !!newValues[k];
          //} else if (config.value_type === 'number') {
          //  if (newValues[k] != '' && isNaN(newValues[k])) {
          //    newValues[k] = 0;
          //  } else if (newValues[k] != '') {
          //    newValues[k] = parseInt(data[k]);
          //  } else {
          //    newValues[k] = '';
          //  }
        } else {
          newValues[k] = newValues[k] ? newValues[k] : '';
        }
      }
      state.extraBenefits = newValues;
    },
    premiumAmounts: (state, data = []) => (state.premiumAmounts = data),
    marketValues: (state, data = []) => (state.marketValues = data),
    errors: (state, data = []) => (state.errors = data),
    prevVehicleNcd: (state, data = {}) => (state.prevVehicleNcd = data),
    quotable: (state, data = false) => (state.quotable = data),
    quotation: (state, data) => (state.quotation = data),
    policyNo: (state, data) => (state.policyNo = data),
    isLocked: (state, data) => (state.isLocked = data),
    additionalDrivers: (state, drivers) => (state.additionalDrivers = drivers),
    appendAdditionalDriver: (state, driver) =>
      (state.additionalDrivers = _.concat(state.additionalDrivers, driver)),
    editAdditionalDriver: (state, data) =>
      (state.additionalDrivers[data.index] = _.omit(data, ['index'])),
    removeAdditionalDriverAt: (state, index) =>
      state.additionalDrivers.splice(index, 1),
    schema: (state, data) => (state.schema = data),
    validators: (state, data) => (state.validators = data),
    shortcodes: (state, shortcodes) => {
      state.shortcodes = {
        policyInfo: _.cloneDeep(shortcodes.policyInfo),
        policyHolderInfo: _.cloneDeep(shortcodes.policyHolderInfo),
        vehicleInfo: _.cloneDeep(shortcodes.vehicleInfo),
      };
    },
    policyExcess: (state, excess) => (state.policyExcess = excess),
    addons: (state, addons) => (state.addons = addons),
    pushAddon: (state, addon) => {
      if (!state.addons.find(a => a.code !== addon.code)) {
        state.addons.push(
          Object.assign({}, addon, {
            fees: addon.fees.map(f =>
              Object.assign({}, f, {
                amount: isNaN(f.amount) ? 0 : Number(f.amount),
              })
            ),
          })
        );
      }
    },
    removeAddon: (state, code) => {
      const index = state.addons.findIndex(a => a.code === code);
      if (index >= 0) {
        state.addons.splice(index, 1);
      }
    },
    updateAddonFees: (state, { addonCode, fees }) => {
      const index = state.addons.findIndex(a => a.code === addonCode);
      if (index >= 0) {
        state.addons[index].fees = fees;
      }
    },
    updateAddonNotes: (state, { addonCode, notes }) => {
      const index = state.addons.findIndex(a => a.code === addonCode);
      if (index >= 0) {
        state.addons[index].notes = notes;
      }
    },
    payment: (state, data) => Object.assign(state.payment, data),
  },
  actions: {
    async updateShortcodes({ rootState, state, commit }, { field }) {
      const shortcodes = updateShortcodeOptions(
        rootState.shortcodes.categories,
        state.shortcodes,
        state,
        field
      );
      await commit('shortcodes', shortcodes);

      const infoState = updateShortcodeValues(state.shortcodes, state);
      await commit('policyInfo', infoState.policyInfo);
      await commit('policyHolderInfo', infoState.policyHolderInfo);
      await commit('vehicleInfo', infoState.vehicleInfo);
    },

    async rebuildShortcodes({ rootState, state, commit }) {
      const rootShortcodes = _.cloneDeep(rootState.shortcodes.categories);

      // Init options by assigning all shortcodes
      const options = {
        policyInfo: {},
        policyHolderInfo: {},
        vehicleInfo: {},
      };
      for (const sf of SHORTCODE_FIELDS) {
        const [stateProp] = sf.field.split('.');
        if (!rootShortcodes[sf.category]) {
          throw new Error(
            `Failed to retrieve shortcodes of category ${sf.category}`
          );
        }
        options[stateProp][sf.category] = rootShortcodes[sf.category];
      }
      // Then assign shortcode options by selected value
      // if value is empty, assign empty array to shortcode options
      let shortcodes = _.cloneDeep(options);
      let infoState = {
        policyInfo: _.cloneDeep(state.policyInfo),
        policyHolderInfo: _.cloneDeep(state.policyHolderInfo),
        vehicleInfo: _.cloneDeep(state.vehicleInfo),
      };
      for (const stateProp in options) {
        for (const category in options[stateProp]) {
          const { field } = SHORTCODE_FIELDS.find(
            sf => sf.category === category
          );
          shortcodes = updateShortcodeOptions(
            rootShortcodes,
            shortcodes,
            state,
            field
          );
          infoState = updateShortcodeValues(shortcodes, infoState);
        }
      }
      await commit('shortcodes', shortcodes);
      await commit('policyInfo', infoState.policyInfo);
      await commit('policyHolderInfo', infoState.policyHolderInfo);
      await commit('vehicleInfo', infoState.vehicleInfo);
    },

    async renew({ commit }, { id }) {
      commit('switchLoading', 'policy details');
      let data = await PolicyService.view(id)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(commit('switchLoading', 'policy details'));
      if (data) {
        // Commit only the necessary data
        commit('switchLoading', 'copying data');
        const policyHolderInfo = processPolicyHolderInfo(
          data.policy_holder_info
        );
        const policyInfo = processPolicyInfo(
          _.omit(data, [
            'addons',
            'cover_from',
            'vendor_cover_from',
            'cover_to',
            'vendor_cover_to',
            'createdAt',
            'created_by',
            'documents',
            'driving_years',
            'id',
            'ncd',
            'policy_excess',
            'policy_no',
            'prev_vehicle_regno',
            'provider_code',
            'quotation_no',
            'policy_holder_info',
            'vehicle_info',
            'reference_no',
            'status_code',
            'total_payable',
            'updatedAt',
            'updated_by',
            'variant_id',
          ])
        );
        policyInfo.cover_from = moment(data.cover_to).add(1, 'days');
        policyInfo.vendor_cover_from = moment(data.cover_to).add(1, 'days');
        policyInfo.cover_to = moment(data.cover_to).add(1, 'year');
        policyInfo.vendor_cover_to = moment(data.cover_to).add(1, 'year');
        Promise.all([
          commit('policyInfo', policyInfo),
          commit('additionalDrivers', data.additional_drivers),
          commit('vehicleInfo', data.vehicle_info),
          commit('policyHolderInfo', policyHolderInfo),
        ]).then(() => commit('switchLoading', 'copying data'));
      }
    },

    async draft({ commit, state }) {
      await commit('switchSaving', 'draft');
      // Draft
      const draftData = _.omit(state.policyInfo, ['id']);
      Object.assign(draftData, {
        policy_holder_info: Object.assign({}, state.policyHolderInfo, {
          driving_since: state.policyHolderInfo.driving_since
            ? state.policyHolderInfo.driving_since + '-01-01'
            : null,
        }),
        vehicle_info: state.vehicleInfo,
        ...Object.assign({}, _.omit(state.policyInfo, ['id']), {
          // NOTE: Special handling for state.prev_vehicle_regno
          prev_vehicle_regno:
            state.policyInfo.prev_vehicle_regno ===
            state.vehicleInfo.registration_no
              ? ''
              : state.policyInfo.prev_vehicle_regno,
        }),
      });
      const data = await PolicyService.draft(draftData)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'draft'));
      await handleAfterSave(state, commit, data);
    },

    async loadSchema({ commit, state, dispatch }) {
      commit('switchLoading', 'schema');

      // Request schema
      const schemaResponse = await PolicyService.schema(state.policyInfo.id, {
        ...state.policyInfo,
        policy_holder_info: state.policyHolderInfo,
        vehicle_info: state.vehicleInfo,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchLoading', 'schema'));

      if (schemaResponse) {
        // Build schema and validators
        const policyInfo = buildSchema(
          dispatch,
          schemaResponse,
          state.policyInfo
        );
        const policyHolderInfo = buildSchema(
          dispatch,
          schemaResponse.policy_holder_info,
          state.policyHolderInfo
        );
        const vehicleInfo = buildSchema(
          dispatch,
          schemaResponse.vehicle_info,
          state.vehicleInfo
        );

        // Commit schema and validators
        await commit('schema', {
          policyInfo: policyInfo.schema,
          policyHolderInfo: policyHolderInfo.schema,
          vehicleInfo: vehicleInfo.schema,
        });
        await commit('validators', {
          policyInfo: policyInfo.validators,
          policyHolderInfo: policyHolderInfo.validators,
          vehicleInfo: vehicleInfo.validators,
        });
      }
    },

    async loadData({ commit, state, dispatch }) {
      if (!state.policyInfo.id) {
        return;
      }
      commit('switchLoading', 'policy details');
      let data = await PolicyService.view(state.policyInfo.id)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(commit('switchLoading', 'policy details'));
      if (data) {
        const policyHolderInfo = processPolicyHolderInfo(
          data.policy_holder_info
        );
        let promises = [
          commit('policyInfo', processPolicyInfo(data)),
          commit('additionalDrivers', data.additional_drivers),
          commit('vehicleInfo', data.vehicle_info),
          commit('policyHolderInfo', policyHolderInfo),
          commit('premiumAmounts', data.premium_amounts),
          commit('marketValues', data.market_values),
          //commit('errors', vendorErrorsToInternalErrors(data.vendor_errors)),
          commit('quotable', data.can_quote),
          commit('quotation', {
            quotation_no: data.quotation_no,
            total_payable: data.total_payable,
          }),
          commit('isLocked', data.status_code !== '01'),
          commit('policyNo', data.policy_no),
          commit('addons', data.addons),
          commit('policyExcess', data.policy_excess),
        ];
        if (!_.isEmpty(data.extra_benefit_config)) {
          promises = _.concat(promises, [
            commit('extraBenefitConfig', data.extra_benefit_config),
            commit('extraBenefits', data.extra_benefits),
          ]);
        }
        await Promise.all(promises);
      }
      if (data.status_code != '01') {
        dispatch('checkCreditBalance');
      }
    },

    async loadISM({ commit, state }) {
      commit('switchLoading', 'ISM market values');
      const data = await PolicyService.getProviderISMValue({
        class_code: state.vehicleInfo.class_code,
        car_type_code: state.vehicleInfo.car_type_code,
        registration_no: state.vehicleInfo.registration_no,
        use_state_code: state.vehicleInfo.use_state_code,
        id_no: state.policyHolderInfo.id_no,
        company_regno: state.policyHolderInfo.company_regno,
        make_code: state.vehicleInfo.make_code,
        model_code: state.vehicleInfo.model_code,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchLoading', 'ISM market values'));
      if (data) {
        const vehicleInfo = data[0].vehicle_info;
        const marketValues = data[0].market_values;
        commit('vehicleInfo', vehicleInfo);
        commit('marketValues', marketValues);
      }
    },

    async loadPremiumAmounts({ commit, state }) {
      commit('switchLoading', 'premium amounts');
      const data = await PolicyService.checkProviderPrice(state.policyInfo.id)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchLoading', 'premium amounts'));
      await handleAfterSave(state, commit, data);
    },

    async checkVehicleNcd({ commit, state }) {
      const data = await PolicyService.checkNCD({
        registration_no: state.policyInfo.prev_vehicle_regno
          ? state.policyInfo.prev_vehicle_regno
          : state.vehicleInfo.registration_no,
        car_type_code: state.vehicleInfo.car_type_code,
        id_no: state.policyHolderInfo.id_no,
        company_regno: state.policyHolderInfo.company_regno,
        use_state_code: state.vehicleInfo.use_state_code,
        class_code: state.vehicleInfo.class_code,
        model_code: state.vehicleInfo.model_code,
        assembler_code: state.vehicleInfo.assembler_code,
        coverage_type_code: state.policyInfo.coverage_type_code,
        chassis_no: state.vehicleInfo.chassis_no,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit));
      if (data) {
        if (
          state.policyInfo.prev_vehicle_regno !==
          state.vehicleInfo.registration_no
        ) {
          commit('prevVehicleNcd', data[0].ncd);
        } else {
          commit('policyInfo', { ncd: data[0].ncd });
        }
      }
    },

    async requestQuotation({ commit, state, dispatch }) {
      // Request quotation
      await commit('switchSaving', 'quotation request');
      const data = await PolicyService.requestQuotation(state.policyInfo.id)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'quotation request'));
      await handleAfterSave(state, commit, data);
      dispatch('checkCreditBalance');
    },

    async checkCreditBalance({ commit, state }) {
      await commit('switchLoading', 'credit balance');
      const creditBalance = await PaymentService.getCreditBalance(
        state.policyInfo.reference_no
      )
        .catch(handleWeirdHttpStatus(commit))
        .then(res => res.data)
        .finally(() => commit('switchLoading', 'credit balance'));

      // Disable all payment gateways if status_code's paymentEnabled is falsy
      // and set notice
      const payment = {
        notice: null,
        isPaymentLinkEnabled: true,
        gateway: {
          gopay: {
            isEnabled: creditBalance.gopay.is_sufficient,
            balance: creditBalance.gopay.balance,
          },
          fpx: {
            isEnabled: creditBalance.fpx.is_sufficient,
            balance: creditBalance.fpx.balance,
          },
        },
      };
      const statusConf = POLICY_STATUS_LIST.find(
        psl => psl.code === state.policyInfo.status_code
      );
      if (statusConf) {
        if (statusConf.notice) {
          payment.notice = statusConf.notice;
        }
        for (const gw in payment.gateway) {
          payment.gateway[gw].isEnabled = !!statusConf.paymentEnabled;
        }
        payment.isPaymentLinkEnabled = !!statusConf.paymentEnabled;
      }

      // Finally commit payment
      commit('payment', payment);
    },

    async retrieveDocuments({ commit, state }) {
      commit('switchLoading', 'documents');
      const data = await PolicyService.retrieveDocuments(state.policyInfo.id)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchLoading', 'documents'));
      await handleAfterSave(state, commit, data);
    },

    async download({ commit, state }, { document_id }) {
      commit('switchLoading', 'document url');
      const docIndex = state.policyInfo.documents.findIndex(
        d => d.id === document_id
      );
      const response = await PolicyService.getDownloadUrl(
        state.policyInfo.id,
        document_id
      ).finally(() => commit('switchLoading', 'document url'));
      const documents = state.policyInfo.documents.slice();
      documents[docIndex].url = response.data.url;
      commit('policyInfo', { documents });
    },

    async saveAllInfo({ commit, state }) {
      await commit('switchSaving', 'policy');
      const data = await PolicyService.save(state.policyInfo.id, {
        ..._.omit(state.policyInfo, ['id']),
        vehicle_info: state.vehicleInfo,
        policy_holder_info: Object.assign({}, state.policyHolderInfo, {
          driving_since: state.policyHolderInfo.driving_since
            ? state.policyHolderInfo.driving_since + '-01-01'
            : null,
        }),
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'policy'));
      await handleAfterSave(state, commit, data);
    },

    async savePolicyInfo({ commit, state }) {
      await commit('switchSaving', 'policy info');
      const reqData = Object.assign({}, _.omit(state.policyInfo, ['id']), {
        // NOTE: Special handling for state.prev_vehicle_regno
        prev_vehicle_regno:
          state.policyInfo.prev_vehicle_regno ===
          state.vehicleInfo.registration_no
            ? ''
            : state.policyInfo.prev_vehicle_regno,
      });
      const data = await PolicyService.save(state.policyInfo.id, reqData)
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'policy info'));
      await handleAfterSave(state, commit, data);
    },

    async saveVehicleInfo({ commit, state }) {
      await commit('switchSaving', 'vehicle info');
      const data = await PolicyService.save(state.policyInfo.id, {
        vehicle_info: state.vehicleInfo,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'vehicle info'));
      await handleAfterSave(state, commit, data);
    },

    async savePolicyHolderInfo({ commit, state }) {
      await commit('switchSaving', 'policy holder info');
      const data = await PolicyService.save(state.policyInfo.id, {
        policy_holder_info: Object.assign({}, state.policyHolderInfo, {
          driving_since: state.policyHolderInfo.driving_since
            ? state.policyHolderInfo.driving_since + '-01-01'
            : null,
        }),
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'policy holder info'));
      await handleAfterSave(state, commit, data);
    },

    async saveExtraBenefits({ commit, state }) {
      await commit('switchSaving', 'extra benefits');
      const data = await PolicyService.save(state.policyInfo.id, {
        extra_benefits: _.mapValues(state.extraBenefits, (v, k) => {
          const config = state.extraBenefitConfig.find(ebc => ebc.key === k);
          if (config.value_type === 'number') {
            if (v == '' || _.isNil(v)) {
              return 0;
            }
          }
          return v;
        }),
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'extra benefits'));
      await handleAfterSave(state, commit, data, ['addons']);
    },

    async saveAdditionalDrivers({ commit, state }) {
      await commit('switchSaving', 'additional drivers');
      const data = await PolicyService.save(state.policyInfo.id, {
        additional_drivers: state.additionalDrivers,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'additional drivers'));
      await handleAfterSave(state, commit, data, ['addons', 'extraBenefits']);
    },

    async saveAddons({ commit, state }) {
      await commit('switchSaving', 'addons');
      const data = await PolicyService.save(state.policyInfo.id, {
        addons: state.addons,
      })
        .catch(handleWeirdHttpStatus(commit))
        .then(handleResponse(commit))
        .finally(() => commit('switchSaving', 'addons'));
      await handleAfterSave(state, commit, data, ['extraBenefits']);
    },
  },
};
