<template>
  <div v-if="!!validator && type" style="width:100%;">
    <!-- value_type is 'option' or 'shortcode|*' -->
    <div v-if="type === 'option' || type === 'boolean'">
      <ons-form-group
        :validator="validator"
        :label="label"
        :help="help"
        :isInlineLabel="type === 'boolean' && booleanVariant === 'radio'"
      >
        <div class="gp-form-smalllabel__container" v-if="subtitle">
          <p class="gp-form__sublabel--small text-g200">
            {{ subtitle }}
          </p>
        </div>
        <div
          class="gp-select__container"
          v-if="
            type === 'option' ||
              (type === 'boolean' && booleanVariant === 'default')
          "
        >
          <v-ons-select
            v-if="type === 'option'"
            v-model="validator.$model"
            :disabled="readonly"
            modifier="gp"
            @change="$emit('change', validator.$model)"
          >
            <option v-for="(v, i) in options" :key="i" :value="v.value">{{
              v.label
            }}</option>
          </v-ons-select>

          <v-ons-select
            v-if="type === 'boolean' && booleanVariant === 'default'"
            v-model="validator.$model"
            :disabled="readonly"
            modifier="gp"
            @change="$emit('change', validator.$model)"
          >
            <option value="n">Nope, Thanks!</option>
            <option value="y">Yes</option>
          </v-ons-select>
        </div>

        <div
          v-if="type === 'boolean' && booleanVariant === 'radio'"
          class="gp-radio__group"
        >
          <div class="gp-radio__container">
            <label class="gp-radio">
              <v-ons-radio
                :input-id="'radio-1'"
                :value="1"
                v-model="validator.$model"
              >
              </v-ons-radio>
            </label>
            <label for="radio-1" class="center">Yes</label>
          </div>
          <div class="gp-radio__container">
            <label class="gp-radio">
              <v-ons-radio
                :input-id="'radio-2'"
                :value="0"
                v-model="validator.$model"
              >
              </v-ons-radio>
            </label>
            <label for="radio-2" class="center">No</label>
          </div>
        </div>
      </ons-form-group>
    </div>

    <!-- value_type is 'date' -->
    <div v-else-if="type === 'date'">
      <ons-form-group :validator="validator" :label="label" :help="help">
        <div class="gp-form-smalllabel__container" v-if="subtitle">
          <p class="gp-form__sublabel--small text-g200">
            {{ subtitle }}
          </p>
        </div>
        <datepicker
          v-model="validator.$model"
          :disabled="readonly"
          :input-class="'gp-datepicker__input'"
          :calendar-class="'gp-datepicker__calendar'"
          :calendar-button="true"
          :calendar-button-icon="'fa fa-calendar'"
          :wrapper-class="'gp-datepicker'"
          :placeholder="label"
          :format="dateFormat"
          :disabled-dates="disabledDates"
          @input="handleDateChange"
        ></datepicker>
      </ons-form-group>
    </div>

    <!-- value_type is 'text' or 'number' -->
    <div v-else>
      <ons-form-group :validator="validator" :label="label" :help="help">
        <div class="gp-form-smalllabel__container" v-if="subtitle">
          <p class="gp-form__sublabel--small text-g200">
            {{ subtitle }}
          </p>
        </div>
        <v-ons-input
          v-model.trim.lazy="validator.$model"
          :type="type"
          class="gp-input"
          :class="{ upperinput: transform === 'uppercase' }"
          modifier="gp"
          :placeholder="label"
          :disabled="readonly"
          @keyup="$emit('keyup', validator.$model)"
          @input="handleChange"
        ></v-ons-input>
      </ons-form-group>
    </div>
  </div>
</template>

<script>
// Utilities
//import { mapState } from 'vuex';
//import _ from 'lodash';

// Components
import Datepicker from 'vuejs-datepicker';

export default {
  props: {
    label: String,
    subtitle: String,
    help: String,
    definition: Object,
    validator: Object,
    disabled: Boolean,
    shortcodes: Object,
    transform: {
      type: String,
      default: null,
    },
    // For date only
    disabledDates: {
      type: Object,
      default() {
        return { to: null };
      },
    },
    booleanVariant: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'radio'].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      type: null,
      readonly: false,
      options: [],
      dateFormat: 'yyyy-MM-dd',
    };
  },
  mounted() {
    const $vm = this;
    $vm.configure();
  },
  //beforeUpdate() {
  //  const $vm = this;
  //  if ($vm.definition.value_type !== $vm.type) {
  //    $vm.configure();
  //  }
  //},
  watch: {
    disabled() {
      const { disabled, definition, validator } = this;
      this.readonly =
        (definition.readonly && validator.$model) ||
        definition.value_type === 'forbidden' ||
        disabled;
    },
    shortcodes() {
      const $vm = this;
      if ($vm.type === 'option' && $vm.isShortcode()) {
        const category = $vm.getShortcodeCategory();
        const isChanged =
          JSON.stringify($vm.options) !==
          JSON.stringify($vm.shortcodes[category]);
        if (isChanged) {
          $vm.setShortcodeOptions();
        }
      }
    },
  },
  methods: {
    async configure() {
      const { definition, disabled, validator } = this;
      if (definition.value_type === 'option') {
        this.type = 'option';
        this.options = definition.options;
      } else if (definition.value_type === 'date') {
        this.type = 'date';
      } else if (this.isShortcode()) {
        // NOTE: Defer options to watcher to avoid race condition
        this.type = 'option';
        this.setShortcodeOptions();
      } else {
        this.type = definition.value_type;
      }

      this.readonly =
        (definition.readonly && validator.$model) ||
        definition.value_type === 'forbidden' ||
        disabled;
    },
    isShortcode() {
      return (
        this.definition.value_type &&
        !!this.definition.value_type.match(/^shortcode\|.+/)
      );
    },
    getShortcodeCategory() {
      const $vm = this;
      if ($vm.isShortcode) {
        const category = $vm.definition.value_type.split('|');
        return category[1];
      }
      return null;
    },
    setShortcodeOptions() {
      const $vm = this;
      let category = $vm.getShortcodeCategory();
      if ($vm.shortcodes[category]) {
        $vm.options = [];
        if (!$vm.definition.required) {
          $vm.options.push({
            label: '',
            value: '',
          });
        }
        const options = $vm.shortcodes[category].map(s => ({
          label: s.description,
          value: s.shortcode,
        }));
        $vm.options = $vm.options.concat(options);
      }
    },
    handleDateChange(date) {
      const { validator } = this;

      //validator.$model = date.toISOString();
      validator.$model = date;

      this.$emit('change', validator.$model);
    },
    handleChange(e) {
      this.validator.$touch();
      this.$emit('change', e.target.value);
    },
  },
  computed: {
    value() {
      return this.validator.$model;
    },
    //...mapState({
    //  // Shortcodes
    //  isShortcodesLoaded: state => state.shortcodes.isLoaded,
    //  // NOTE: Must use object.assign to allow vuejs watcher detection
    //  //shortcodes: state => Object.assign({}, state.shortcodes.categories),
    //}),
  },
};
</script>
