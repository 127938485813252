import Vue from 'vue';
import Vuex from 'vuex';

// Namespaced datastores
import policy from '@/store/policy';
import policies from '@/store/policies';
import shortcodes from '@/store/shortcodes';
import customer from '@/store/customer';
import customers from '@/store/customers';
import customer_options from '@/store/customer_options';
import vehicle from '@/store/vehicle';
import vehicles from '@/store/vehicles';
import presets from '@/store/presets';
import addons from '@/store/addons';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    policy,
    policies,
    shortcodes,
    customer,
    customers,
    customer_options,
    vehicle,
    vehicles,
    presets,
    addons,
  },
});
