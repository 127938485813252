<template>
  <div class="gp-list__item-container">
    <div class="container--flex">
      <div class="gp-list__item-section container--50">
        <div class="gp-list__item-label">{{ customer.name }}</div>
        <div class="gp-list__item">{{ customer.id_no }}</div>
        <div class="gp-list__item">{{ customer.mobile_no }}</div>
        <div class="gp-list__item-active">
          <strong>{{ status[customer.status_code] }}</strong>
        </div>
      </div>
      <div
        class="gp-list__item-ctas gp-list__item-section container--flex container--50"
      >
        <button
          class="button-gp--pill button-gp--pill__green"
          @click="$emit('list-vehicle', customer)"
        >
          Vehicle List
        </button>
        <div class="container--flex container--100">
          <div class="container--50 mr-8">
            <button
              @click="$emit('quote', customer)"
              class="button-gp--pill button-gp--pill__orange"
            >
              Get Quote
            </button>
          </div>
          <div class="container--50 ml-8">
            <button
              @click="$emit('view', customer)"
              class="button-gp--pill button-gp--pill__blue"
            >
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: Object,
  },
  data() {
    return {
      status: { A: 'Active', X: 'Inactive' },
    };
  },
};
</script>

<style lang="scss" scoped>
.gp-list__item-label {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.gp-list__item-container {
  padding: 8px;
  margin: 0 -8px;
}

.gp-list__item-section {
  padding: 0 8px;
}

.gp-list__item-ctas {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: space-between;
}
</style>
