<template>
  <div>
    <div class="quote-form-cotainer">
      <div class="gp-form__sublabel gp-form__text--uppercase">
        <strong>Motorist Personal Accident - MPA</strong>
      </div>
      <div>
        <v-ons-list-item modifier="gp">
          <dynamic-form-field
            v-if="mpaEbc.value_type"
            :label="mpaEbc.label"
            :validator="$v.extraBenefits.mpa"
            :definition="mpaEbc"
            :disabled="disabled"
            :help="helps.mpa"
            @change="val => handleChange('mpa', val)"
          />
        </v-ons-list-item>
      </div>
    </div>

    <div class="quote-form-cotainer">
      <div class="gp-form__sublabel gp-form__text--uppercase">
        <strong>Extra Benefit</strong>
      </div>

      <slot />

      <div v-if="otherEbc.length > 0">
        <v-ons-list-item
          v-for="config in otherEbc"
          modifier="gp"
          :key="config.key"
        >
          <dynamic-form-field
            :label="config.label"
            :validator="$v.extraBenefits[config.key]"
            :definition="config"
            :disabled="disabled"
            :help="helps[config.key]"
            @change="val => handleChange(config.key, val)"
          />
        </v-ons-list-item>
      </div>
    </div>
  </div>
</template>

<script>
// Page components
import DynamicFormField from '@/components/DynamicFormField.vue';
import { required, integer, minValue } from 'vuelidate/lib/validators';

// Utilites and config
import _ from 'lodash';

export default {
  props: {
    extraBenefitConfig: Array,
    defaultExtraBenefits: Object,
    disabled: Boolean,
  },
  components: {
    DynamicFormField,
  },
  data() {
    return {
      otherEbc: [],
      mpaEbc: {},
      helps: {},
      extraBenefits: {},
      //labels: {
      //  llop: 'LLP - Legal Liability to Passengers',
      //  llopn: 'LLOP - Legal Liability of Passengers',
      //  windscreen: 'Windscreen (15% of windscreen sum insured)',
      //  inconvenience_allowance: 'Inconvenience Allowance',
      //  spray_painting: 'Spray Painting',
      //  perils_inclusion:
      //    'Inclusion of Special Perils (0.50% of Vehicle Sum Insured)',
      //  mpa: 'MPA',
      //},
    };
  },
  mounted() {
    this.setValues();
    this.handleEbc();
    this.handleChange();
  },
  validations() {
    const { extraBenefitConfig } = this;
    const validators = { extraBenefits: {} };
    for (const ebc of extraBenefitConfig) {
      if (['string', 'number'].includes(ebc.value_type)) {
        validators.extraBenefits[ebc.key] = { required };
        if (ebc.value_type === 'number') {
          validators.extraBenefits[ebc.key].number = integer;
          validators.extraBenefits[ebc.key].minValue = minValue(0);
        }
      } else {
        validators.extraBenefits[ebc.key] = {};
      }
    }
    return validators;
  },
  watch: {
    //defaultExtraBenefits() {
    //  this.setValues();
    //},
    extraBenefitConfig() {
      this.handleEbc();
    },
  },
  methods: {
    handleEbc() {
      const { extraBenefitConfig, helps } = this;
      // Transform value_type to forbidden if undefined or null
      const ebc = extraBenefitConfig.map(ebc =>
        Object.assign({}, ebc, {
          value_type: ebc.value_type ? ebc.value_type : 'forbidden',
        })
      );
      // Separate mpa and other extra benefits
      this.mpaEbc = ebc.find(ebc => ebc.key === 'mpa');
      this.otherEbc = ebc.filter(ebc => ebc.key !== 'mpa');
      // Handle help descriptions
      for (const ebc of extraBenefitConfig) {
        if (!helps[ebc.key]) {
          this.helps[ebc.key] = '';
        }
        this.helps[ebc.key] = ebc.description;
        if (!ebc.description || ebc.description.trim() === '') {
          this.helps[ebc.key] = '(NO DESCRIPTION AVAILABLE)';
        }
      }
    },

    setValues() {
      const { defaultExtraBenefits, extraBenefitConfig } = this;
      // Transform boolean data
      this.extraBenefits = Object.assign(
        {},
        _.mapValues(defaultExtraBenefits, (v, k) => {
          if (typeof v === 'boolean') {
            return v === true ? 'y' : 'n';
          }
          if (v === '') {
            const conf = extraBenefitConfig.find(ebc => ebc.key === k);
            if (conf.value_type === 'number') {
              return 0;
            }
          }
          return v;
        })
      );
    },

    handleChange(updatedKey, newValue) {
      const { extraBenefits, extraBenefitConfig } = this;
      this.$v.$touch();
      this.$emit('update:validation', this.$v);
      this.$emit(
        'change',
        _.mapValues(extraBenefits, (val, key) => {
          // Switch boolean value_type
          const conf = extraBenefitConfig.find(k => k.key === key);
          const latestVal = key === updatedKey ? newValue : val;
          if (conf.value_type === 'boolean') {
            // Transform boolean data
            return latestVal === 'y';
          }
          return latestVal;
        })
      );
    },
  },
};
</script>
