// Utilities
//import _ from 'lodash';

// Services
import PolicyService from '@/services/policy';

const defaultState = () => ({
  loading: [],
  data: [],
  totalItems: 0,
  perPage: 10,
  page: 1,
  search: {
    all: '',
    status_code: [],
    expire_within_days: 0,
  },
  searchOptions: {
    status_code: [],
  },
  errors: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    switchLoading(state, loadingLabel) {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    data: (state, data = []) => (state.data = data),
    totalItems: (state, data = 0) => (state.totalItems = data),
    perPage: (state, data = 10) => (state.perPage = data),
    page: (state, data = 1) => (state.page = data),
    errors: (state, data) => (state.errors = data),
    search: (state, data) => Object.assign(state.search, data),
    searchOptions: (state, data) => Object.assign(state.searchOptions, data),
  },
  actions: {
    async load({ commit, state }) {
      commit('switchLoading', 'policies');
      const response = await PolicyService.getInsurancePolicies(
        state.page,
        state.perPage,
        state.search
      )
        .catch(err => commit('errors', [`${err.name}: ${err.message}`]))
        .finally(() => commit('switchLoading', 'policies'));
      commit('data', response.data.data);
      commit('totalItems', response.data.total_items);
      commit('perPage', response.data.length);
      commit('page', response.data.page);
    },

    async loadSearchOptions({ commit }) {
      commit('switchLoading', 'search options');
      const response = await PolicyService.getSearchOptions()
        .catch(err => commit('errors', [`${err.name}: ${err.message}`]))
        .finally(() => commit('switchLoading', 'search options'));
      commit('searchOptions', response.data);
    },
  },
};
