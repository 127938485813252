<template>
  <div>
    <div class="gp-form__sublabel gp-form__text--uppercase">
      <strong>ADD ON SERVICES</strong>
    </div>

    <div class="gp-form__group">
      <label class="gp-input__label">{{ addon.name }}</label>
      <div class="gp-select__container">
        <v-ons-select
          v-model="enabled"
          modifier="gp"
          :disabled="disabled"
          @change="handleAddonChange()"
        >
          <option value="n">Nope, Thanks!</option>
          <option value="y">Yes</option>
        </v-ons-select>
      </div>
    </div>

    <!-- addon fee items -->
    <div class="container--flex">
      <div
        v-for="(fee, i) in fees"
        class="mx-8 gp-addon"
        :style="{ width: `${90 / (fees.length + 1)}%` }"
        :key="fee.code"
      >
        <addon-fee-item
          :operator="i === 0 ? '' : '+'"
          :definition="feeDefs[i]"
          :fee="fee"
          :disabled="disabled || enabled === 'n'"
          v-bind:validator.sync="validators.fees[i]"
          @feeUpdated="handleFeeChange"
        />
      </div>
      <div
        class="mx-8 gp-addon"
        :style="{ width: `${90 / (fees.length + 1)}%` }"
      >
        <label class="gp-input__label">Total Amount (RM)</label>
        <v-ons-input
          v-model="total"
          type="number"
          class="gp-input"
          modifier="gp"
          disabled
        ></v-ons-input>
      </div>
    </div>

    <!-- addon note -->
    <div class="gp-form__group gp-addon">
      <div v-for="(note, i) in notes" :key="note.field">
        <addon-note
          :definition="noteDefs[i]"
          :note="note"
          :disabled="disabled || enabled === 'n'"
          v-bind:validator.sync="validators.notes[i]"
          @updated="handleNoteChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddonFeeItem from '@/components/AddonFeeItem.vue';
import AddonNote from '@/components/AddonNote.vue';
import _ from 'lodash';

export default {
  components: {
    AddonFeeItem,
    AddonNote,
  },
  props: {
    addon: Object,
    feeDefs: Array,
    noteDefs: Array,
    disabled: Boolean,
    selected: Object,
  },
  data() {
    return {
      enabled: 'n',
      fees: [],
      notes: [],
      total: 0,
      validators: {
        fees: [],
        notes: [],
      },
    };
  },
  mounted() {
    this.configure();
  },
  watch: {
    feeDefs() {
      this.configure();
    },
    selected() {
      this.configure();
    },
    validators: {
      deep: true,
      handler() {
        const { enabled, validators } = this;
        let isValid = true;
        if (enabled === 'y') {
          const isFeesValid = validators.fees.findIndex(n => !n) <= -1;
          const isNotesValid = validators.notes.findIndex(n => !n) <= -1;
          isValid = isFeesValid && isNotesValid;
        }
        this.$emit('update:isValid', isValid);
      },
    },
  },
  methods: {
    configure() {
      const { feeDefs, noteDefs, selected, calculateTotal } = this;
      // Clone feeDefs to fees
      if (selected) {
        this.enabled = 'y';
        this.fees = selected.fees.map(fee =>
          Object.assign({}, fee, {
            amount: _.isNil(fee.amount) ? 0 : fee.amount,
          })
        );
        this.notes = selected.notes.map(note =>
          Object.assign({}, note, {
            amount: _.isNil(note.value) ? 0 : note.value,
          })
        );
      } else {
        this.fees = feeDefs.map(fee =>
          Object.assign({}, fee, {
            amount: _.isNil(fee.amount) ? 0 : fee.amount,
          })
        );
        this.notes = noteDefs.map(note => ({
          field: note.field,
          value: null,
        }));
      }
      calculateTotal();
    },
    calculateTotal() {
      const { fees } = this;
      this.total = fees.reduce((acc, curr) => acc + curr.amount, 0);
    },
    handleAddonChange() {
      const { enabled } = this;
      this.$emit(enabled === 'y' ? 'enable' : 'disable');
    },
    handleFeeChange(fee) {
      const { addon, fees, calculateTotal } = this;
      this.fees = fees.map(f => {
        if (f.code === fee.code) {
          return Object.assign({}, fee);
        }
        return f;
      });
      calculateTotal();
      this.$emit('feeUpdated', { addon, fees });
    },
    handleNoteChange(note) {
      const { addon, notes } = this;
      this.notes = notes.map(n => {
        if (n.field === note.field) {
          return Object.assign({}, note);
        }
        return n;
      });
      this.$emit('noteUpdated', { addon, notes });
    },
  },
};
</script>
