/* eslint-disable */
export const VERSION = '1.0.9';

export const ASSETS_URL = 'https://dev.gonet.com.my/goprotect/api/';
export const API_BASE_URL = 'https://dev.gonet.com.my/goprotect/api/user';
export const PAYMENT_URL = 'https://dev.gonet.com.my/goprotect/api/pay/submit';
export const GONET_URL = 'http://mba.dev.gonet.digiheritage.com.my/';
export const EXPIRING_DAYS = 60;
export const GP_LOGO = `${ASSETS_URL}/goprotect.png`;
export const GONET_LOGO = `${ASSETS_URL}/gonet.png`;
export const ANDROID_LOGO_SRC = `${ASSETS_URL}/android.png`;
export const ANDROID_LOGO_CTA = 'https://play.google.com/store/apps/details?id=my.gonet.app.gopay';
export const IOS_LOGO_SRC = `${ASSETS_URL}/ios.png`;
export const IOS_LOGO_CTA = 'https://apps.apple.com/my/app/gonet/id1487935215';
export const CUSTOMER_SUPPORT_CONTACT_NO = '1-800-22-0123'

export const TIMB_LOGO = 'https://www.tuneprotect.com/motorusersguide/images/tuneprotect_logo.png';

/**
 * NOTE: DO NOT MODIFY WHATEVER BELOW THIS LINE
 * UNLESS YOU KNOW WHAT YOU ARE DOING!
 * */

/**
 * Vehicle configuration
 * */
export const VEHICLE = {
  // List of shortcode dependencies for vehicle service
  SHORTCODE_FIELDS: [
    { field: 'make_code', category: 'car_make' },
    { field: 'model_code', category: 'car_model' },
    { field: 'body_code', category: 'car_body' },
    { field: 'fuel_code', category: 'car_fuel' },
    { field: 'car_type_code', category: 'car_type' },
    { field: 'class_code', category: 'vehicle_class' },
    { field: 'assembler_code', category: 'vehicle_assembler' },
    { field: 'condition_code', category: 'vehicle_condition' },
    { field: 'use_state_code', category: 'state' },
    { field: 'unit_type_code', category: 'unit_type' },
  ],
};

/**
 * Customer configuration
 * */
export const CUSTOMER = {
  SHORTCODE_FIELDS: [
    { field: 'state_code', category: 'state' },
    { field: 'title_code', category: 'customer_title' },
    { field: 'gender_code', category: 'gender' },
    { field: 'marital_status_code', category: 'marital_status' },
    { field: 'ethnic_code', category: 'ethnic' },
    { field: 'religion_code', category: 'religion' },
    { field: 'occupation_code', category: 'occupation' },
  ],
};

/**
 * Policy configuration
 * */
export const POLICY = {
  // What kind of form to display, long or short, default would be long
  //VEHICLE_CLASS_FORM_TYPES: [
  //  { class_code: 'PTE', type: 'short' },
  //],
  VEHICLE_CLASS_CONFIG: [
    {
      class_code: 'PTE',
      formType: 'short', // Types of form on step 1 based on vehicle class
      disableCoverBackdate: true, // Whether to disable "cover from" backdate
    },
  ],
  CAR_TYPE_CONFIG: [
    {
      carTypeCode: 'PTE-FBU',
      defPermDriverCode: 'AA',
    },
  ],
  SHORTCODE_FIELDS: [
    { field: 'vehicleInfo.make_code', category: 'car_make' },
    { field: 'vehicleInfo.model_code', category: 'car_model' },
    { field: 'vehicleInfo.body_code', category: 'car_body' },
    { field: 'vehicleInfo.fuel_code', category: 'car_fuel' },
    { field: 'vehicleInfo.car_type_code', category: 'car_type' },
    { field: 'vehicleInfo.class_code', category: 'vehicle_class' },
    { field: 'vehicleInfo.assembler_code', category: 'vehicle_assembler' },
    { field: 'vehicleInfo.condition_code', category: 'vehicle_condition' },
    { field: 'vehicleInfo.use_state_code', category: 'state' },
    { field: 'vehicleInfo.unit_type_code', category: 'unit_type' },
    { field: 'policyInfo.coverage_type_code', category: 'coverage_type' },
    { field: 'policyInfo.safety_feature_code', category: 'safety_feature' },
    { field: 'policyInfo.anti_theft_code', category: 'anti_theft_feature' },
    { field: 'policyInfo.permitted_driver_code', category: 'permitted_driver' },
    { field: 'policyInfo.garage_code', category: 'garage' },
    { field: 'policyInfo.hpc_code', category: 'hire_purchase_company' },
    { field: 'policyHolderInfo.state_code', category: 'state', ignore_default: true },
    { field: 'policyHolderInfo.title_code', category: 'customer_title' },
    { field: 'policyHolderInfo.gender_code', category: 'gender' },
    { field: 'policyHolderInfo.marital_status_code', category: 'marital_status' },
    { field: 'policyHolderInfo.ethnic_code', category: 'ethnic' },
    { field: 'policyHolderInfo.religion_code', category: 'religion' },
    { field: 'policyHolderInfo.occupation_code', category: 'occupation' },
  ],

  // Default state
  DEFAULT_STATE: 'SABAH',
};

/**
 * Shortcodes configuration
 * */
export const SHORTCODES = () => [
  {
    category: 'vehicle_class',
    children: [
      { category: 'car_type', key: 'types' },
      { category: 'vehicle_condition', key: 'conditions' },
      { category: 'unit_type', key: 'units' },
    ],
  },
  {
    category: 'car_make',
    children: [
      { category: 'car_model', key: 'models' },
    ],
  },
  {
    category: 'car_type',
    children: [
      { category: 'permitted_driver', key: 'permitted_drivers' },
    ],
  },
];

/**
 * List of customer types
 * */
export const CUSTOMER_TYPES = [
  {
    label: 'INDIVIDUAL',
    value: 'individual',
    carTypeCodes: ['PTE-FPU', 'PTE-FTP', 'MCE-ALI', 'MCE-SRI'],
  },
  {
    label: 'CORPORATE',
    value: 'corporate',
    carTypeCodes: ['PTE-FBU'],
  },
];

/**
 * List of policy status and its configuration
 * code = policy status code
 * label = status code label/meaning
 * pillClassName = class name used for the status pill
 * */
export const POLICY_STATUS_LIST = [
  {
    code: '01',
    label: 'draft',
    statusClassName: 'color-gray-300',
    // Page to show on view, accepts "step-1", "payment", "payment-success"
    viewPage: 'step-1',
    // Notice to show on payment page if policy in the status
    notice: null,
    // Signal whether this policy status is paid
    isPaid: false,
  },
  {
    code: '02',
    label: 'pending quotation',
    statusClassName: 'color-orange-100',
    viewPage: 'step-1',
    notice: 'Policy is unable to retrieve quotation no. from vendor. ' +
      'Feel free to contact us for current status.',
    isPaid: false,
  },
  {
    code: '03',
    label: 'pending payment',
    statusClassName: 'color-orange-100',
    paymentEnabled: true,
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '04',
    label: 'pending approval',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Vehicle is currently waiting for approval from vendor or JPJ. ' +
      'Feel free to contact us for current status.',
    isPaid: false,
  },
  {
    code: '05',
    label: 'closed',
    statusClassName: 'color-green-100',
    viewPage: 'payment-success',
    notice: null,
    isPaid: true,
  },
  {
    code: '06',
    label: 'refunded',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: true,
  },
  {
    code: '07',
    label: 'rejected',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Vehicle has been rejected by JPJ or vendor.',
    isPaid: false,
  },
  {
    code: '08',
    label: 'confirmed',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '09',
    label: 'approved',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '10',
    label: 'pending confirmation',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '11',
    label: 'cancelled',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '12',
    label: 'voided',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '13',
    label: 'refer: pending approval',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Policy has been referred by vendor. ' +
      'Please contact us at 1800-22-0123 or 0168300065.',
    isPaid: false,
  },
  {
    code: '14',
    label: 'refer: approved',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '15',
    label: 'fpx pending approval',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '16',
    label: 'cc pending approval',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '17',
    label: 'pending cover note',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Policy is currently in the process of generating policy documents. ' +
      'Please allow up to 3 days to generate the documents. ' +
      'Feel free to contact us for the latest policy status.',
    isPaid: true,
  },
  {
    code: '18',
    label: 'failed',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
  },
  {
    code: '19',
    label: 'refer case: rejected',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Policy has been rejected by vendor. Feel free to contact us for rejection reasons.',
    isPaid: false,
  },
  {
    code: '20',
    label: 'payment success',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: 'Payment has already been made, but was unable to retrieve policy no. from vendor. ' +
      'Please allow up to 3 days to generate the policy no. ' +
      'Feel free to contact us for the latest policy status.',
    isPaid: true,
  },
  {
    code: '21',
    label: 'expired',
    statusClassName: 'color-orange-100',
    viewPage: 'payment',
    notice: null,
    isPaid: false,
    isExpired: true,
  },
];

