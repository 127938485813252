var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',{attrs:{"modifier":"gp"}},[_c('page-toolbar',{attrs:{"pageStack":_vm.pageStack}}),_c('div',{staticClass:"quote-form-cotainer"},[(_vm.shortcodes.vehicleInfo.state)?_c('state-flag',{attrs:{"selected":_vm.vehicleInfo.use_state_code,"options":_vm.shortcodes.vehicleInfo.state.map(function (s) { return ({
          label: s.description,
          value: s.shortcode,
        }); }),"state":_vm.vehicleInfo.use_state_code},on:{"update:state":function($event){return _vm.$set(_vm.vehicleInfo, "use_state_code", $event)}}},[_c('div',{staticClass:"gp-form__label"},[_vm._v("Step 4")]),_c('div',{staticClass:"gp-form__sublabel"},[_vm._v(" Vehicle's Details ")])]):_vm._e(),_c('div',[_c('div',{staticClass:"quote-form-cotainer"},[_c('vehicle-form',{attrs:{"variant":"minimal","vehicle":_vm.vehicleInfo,"schema":_vm.schema.vehicleInfo,"validators":_vm.validators.vehicleInfo,"disabled":_vm.locked,"disabledFields":[
            'class_code',
            'condition_code',
            'car_type_code',
            'use_state_code' ],"validation":_vm.validation,"shortcodes":_vm.shortcodes.vehicleInfo},on:{"change":_vm.handleChange,"update:validation":function($event){_vm.validation=$event}}}),(_vm.schema.policyInfo.hpc_code)?_c('dynamic-form-field',{key:("hpc_code-" + (_vm.schema.policyInfo.hpc_code
                ? _vm.schema.policyInfo.hpc_code.value_type
                : 0)),attrs:{"label":"Hire Purchase Company","validator":_vm.$v.policyInfo.hpc_code,"definition":_vm.schema.policyInfo.hpc_code,"disabled":_vm.locked,"shortcodes":_vm.shortcodes.policyInfo}}):_vm._e(),_c('div',{staticClass:"button-container"},[_c('v-ons-button',{staticClass:"primary",attrs:{"modifier":"gp","disabled":_vm.validation.$invalid || _vm.$v.$invalid},on:{"click":function($event){return _vm.gotoNextPage()}}},[_vm._v(" Next ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }