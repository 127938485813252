import Vue from 'vue';
import Paginate from 'vuejs-paginate';
import VueOnsen from 'vue-onsenui';
import Vuex from 'vuex';
import VueClipboard from 'vue-clipboard2';
import VueFriendlyIframe from 'vue-friendly-iframe';
import Vuelidate from 'vuelidate';
import vuelidateErrorExtractor from 'vuelidate-error-extractor';

import AppNavigator from '@/AppNavigator.vue';
import store from '@/store/';
import '@/registerServiceWorker';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import '@/assets/scss/main.scss';
import OnsFormGroup from '@/components/OnsFormGroup.vue';
import validators from '@/validators';

Vue.use(Vuex);
Vue.use(VueOnsen);
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueFriendlyIframe);

const veeConfig = {
  i18n: false,
  template: OnsFormGroup,
  // Define common validation messages.
  messages: {
    required: '{label} is required!',
    integer: '{label} must be a valid number!',
    email: '{label} is not a valid Email address.',
    decimal: '{label} is not a valid decimal number.',
  },
};
// Append custom validator messages
Object.keys(validators).forEach(
  key => (veeConfig.messages[key] = validators[key].message)
);
Vue.use(vuelidateErrorExtractor, veeConfig);

Vue.component('ons-form-group', OnsFormGroup);
Vue.component('paginate', Paginate);

Vue.config.productionTip = false;

new Vue({
  store,
  render: h => h(AppNavigator),
}).$mount('#app');
