import { SHORTCODES } from '@/lib/constants';
//import _ from 'lodash';

export function getChildren(category) {
  const shortcodeConf = SHORTCODES().find(s => s.category === category);
  return shortcodeConf ? shortcodeConf.children : [];
}

export function getChildrenShortcodes(shortcodes, parentCategory, parentValue) {
  const childrenConf = getChildren(parentCategory);
  const childrenShortcodes = {};
  // If no children is found do nothing
  if (childrenConf.length <= 0) {
    return childrenShortcodes;
  }
  // Get list of options available to the child
  const parentShortcode = shortcodes[parentCategory].find(
    s => s.shortcode === parentValue
  );
  // Loop through every single child and set shortcode options
  for (const childConf of childrenConf) {
    // Determine child shortcode field config
    const { category, key } = childConf;
    // Assign partial shortcode to child shortcode options first
    if (!parentShortcode || !parentShortcode[key]) {
      continue;
    }
    const childShortcodes = parentShortcode[key].slice();
    // Then loop through each child shortcodes and assign full shortcodes
    for (const i in childShortcodes) {
      const childShortcode = shortcodes[category].find(
        s => s.id === childShortcodes[i].id
      );
      if (childShortcode) {
        childShortcodes[i] = childShortcode;
      }
    }
    childrenShortcodes[category] = childShortcodes;
  }
  return childrenShortcodes;
}
