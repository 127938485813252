<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 2</div>
        <div class="gp-form__sublabel gp-form__sublabel--large">
          <strong>Select Insurer</strong>
        </div>
      </state-flag>
      <div>
        <div class="container--flex gp-form__sublabel gp-form__sublabel--large">
          <div class="container--50">NRIC / Company No.</div>
          <div class="container--50">
            :
            {{
              policyHolderInfo.id_no
                ? policyHolderInfo.id_no
                : policyHolderInfo.company_regno
            }}
          </div>
        </div>
        <div class="container--flex gp-form__sublabel gp-form__sublabel--large">
          <div class="container--50">Vehicle Reg No.</div>
          <div class="container--50">: {{ vehicleInfo.registration_no }}</div>
        </div>
        <div class="container--flex gp-form__sublabel gp-form__sublabel--large">
          <div class="container--50">NCD on Policy</div>
          <div class="container--50">
            : {{ Math.round(policyInfo.ncd.next * 100) }}%
          </div>
        </div>
      </div>

      <dynamic-form-field
        v-if="!!$v.policyInfo.coverage_type_code"
        label="Type of Cover"
        :validator="$v.policyInfo.coverage_type_code"
        :definition="schema.policyInfo.coverage_type_code"
        :disabled="locked"
        @change="handleCoverTypeChange"
        :shortcodes="shortcodes.policyInfo"
      />

      <cover-period
        v-if="$v.policyInfo.cover_from"
        :schema="schema.policyInfo"
        :validator="$v.policyInfo"
        :disabled="locked"
        :shortcodes="shortcodes.policyInfo"
        :backdate-minimum="coverBackdateMin"
        v-bind:cover_from.sync="$v.policyInfo.cover_from.$model"
        v-bind:cover_to.sync="$v.policyInfo.cover_to.$model"
      />

      <dynamic-form-field
        v-if="$v.policyInfo.agreed_value"
        label="Agreed Value"
        booleanVariant="radio"
        :validator="$v.policyInfo.agreed_value"
        :definition="schema.policyInfo.agreed_value"
        :disabled="locked"
        :shortcodes="shortcodes.policyInfo"
      />

      <div class="gp-form__sublabel gp-form__sublabel--small">
        Please select insurance company
      </div>

      <div>
        <div v-if="providers.length > 0">
          <InsuranceVendor
            v-for="(provider, index) in providers"
            :key="
              `${index}-${provider.provider_code}-${provider.total_amount_payable}`
            "
            :provider="provider"
            :sumInsured="
              schema.policyInfo.sum_insured.value_type === 'number'
                ? policyInfo.sum_insured
                : null
            "
            :disabled="$v.$invalid"
            @selected="submitPolicyUpdate"
          />
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import InsuranceVendor from '@/components/InsuranceVendor.vue';
import DynamicFormField from '@/components/DynamicFormField.vue';
import CoverPeriod from '@/components/CoverPeriod.vue';
import StateFlag from '@/components/StateFlag.vue';

// Views
import QuoteFormStep3 from '@/views/motor-insurance/QuoteFormStep3.vue';

// Utilities and config
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';

// Configs
import { POLICY } from '@/lib/constants';
const { VEHICLE_CLASS_CONFIG } = POLICY;

export default {
  mixins: [QuoteFormMixin],
  props: ['pageStack'],
  components: {
    InsuranceVendor,
    PageToolbar,
    DynamicFormField,
    CoverPeriod,
    StateFlag,
  },
  data() {
    return {
      coverBackdateMin: null,
      nextPage: QuoteFormStep3,
      confirmDispatchActions: ['policy/savePolicyInfo'],
    };
  },
  validations() {
    const { validators } = this;
    const v = {
      policyInfo: {
        coverage_type_code: _.get(validators, 'policyInfo.coverage_type_code'),
      },
    };
    if (_.has(validators, 'policyInfo.cover_from')) {
      v.policyInfo.cover_from = _.get(validators, 'policyInfo.cover_from');
    }
    if (_.has(validators, 'policyInfo.cover_to')) {
      v.policyInfo.cover_to = _.get(validators, 'policyInfo.cover_to');
    }
    if (_.has(validators, 'policyInfo.agreed_value')) {
      v.policyInfo.agreed_value = _.get(validators, 'policyInfo.agreed_value');
    }
    return v;
  },
  watch: {
    validators() {
      const { $v } = this;
      $v.$touch();
    },
    'policyInfo.cover_from'() {
      this.setCoverBackdateMin();
    },
  },
  methods: {
    ...mapMutations({
      commitPolicyInfo: 'policy/policyInfo',
    }),
    ...mapActions({
      savePolicyInfo: 'policy/savePolicyInfo',
      loadPremiumAmounts: 'policy/loadPremiumAmounts',
      updateShortcodes: 'policy/updateShortcodes',
    }),

    setCoverBackdateMin() {
      const { vendor_cover_from } = this.policyInfo;
      const { class_code } = this.vehicleInfo;
      const conf = VEHICLE_CLASS_CONFIG.find(c => c.class_code === class_code);
      let minCoverFrom = moment(vendor_cover_from, 'YYYY-MM-DD');
      // Use today's date if today's date is after vendor_cover_from date
      if (moment().isSameOrAfter(minCoverFrom)) {
        minCoverFrom = moment();
      }
      if (conf.disableCoverBackdate) {
        this.coverBackdateMin = minCoverFrom;
      }
    },

    handlePageShow() {
      const {
        locked,
        gotoNextPage,
        checkPricing,
        providers,
        setCoverBackdateMin,
      } = this;
      if (locked === true) {
        gotoNextPage();
      }
      if (_.isEmpty(providers)) {
        checkPricing();
      }
      setCoverBackdateMin();
    },

    async submitPolicyUpdate(selectedProvider) {
      const { locked, gotoNextPage, commitPolicyInfo, savePolicyInfo } = this;
      if (!locked) {
        commitPolicyInfo({
          provider_code: selectedProvider.provider_code,
        });
        await savePolicyInfo();
      }
      gotoNextPage();
    },

    async checkPricing() {
      const { loadPremiumAmounts } = this;
      await loadPremiumAmounts();
    },

    async handleCoverTypeChange() {
      const {
        savePolicyInfo,
        loadSchema,
        updateShortcodes,
        checkPricing,
      } = this;
      await savePolicyInfo();
      loadSchema();
      checkPricing();
      updateShortcodes({
        field: 'policyInfo.coverage_type_code',
      });
    },

    //async handleCoverFromChange(coverFrom) {
    //  const { updateShortcodes, $v, commitPolicyInfo } = this;
    //  await commitPolicyInfo({
    //    cover_from: moment(coverFrom),
    //    cover_to: moment(coverFrom)
    //      .add(1, 'y')
    //      .subtract(1, 'd'),
    //  });
    //  updateShortcodes({
    //    field: 'policyInfo.cover_from',
    //  });
    //  $v.policyInfo.$touch();
    //},
  },
  computed: mapState({
    providers: state => state.policy.premiumAmounts,
  }),
};
</script>

<style lang="scss" scoped>
.quote-form-cotainer {
  margin-top: 32px;
}
</style>
