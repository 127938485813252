<template>
  <div class="container--block my-8">
    <div class="gp-form__text--uppercase gp-input__label">
      Policy Excess
      <v-ons-icon
        icon="fa-question-circle"
        style="color: #fa5252"
        @click="showHelp($event)"
      ></v-ons-icon>
    </div>

    <div v-if="policyExcess.type === 'additional_drivers'">
      <v-ons-list-item modifier="gp" class="my-8">
        <div class="container--flex center flex-wrap--nowrap">
          <div class="flex-basis--60">
            <label class="gp-input__label">Policy Holder Name</label>
            <v-ons-input
              v-model="policyHolderInfo.name"
              placeholder="Policy Holder"
              type="text"
              class="gp-input"
              modifier="gp"
              readonly
            ></v-ons-input>
          </div>
          <div class="mx-8 flex-basis--20">
            <label class="gp-input__label">Age</label>
            <v-ons-input
              :value="policyHolderAge"
              type="number"
              class="gp-input"
              modifier="gp"
              readonly
            ></v-ons-input>
          </div>
          <div class="flex-basis--20">
            <label class="gp-input__label">RM</label>
            <v-ons-input
              value="0"
              type="number"
              class="gp-input"
              modifier="gp"
              readonly
            ></v-ons-input>
          </div>
        </div>
      </v-ons-list-item>

      <div class="gp-form__sublabel gp-form__text--uppercase my-16">
        ADD AUTHORISED DRIVER
      </div>
      <v-ons-list-item modifier="gp">
        <div class="container--flex flex-wrap--nowrap container--100">
          <div class="container--50">
            <label class="gp-input__label">Driver Name</label>
          </div>
          <div class="container--20 mx-8">
            <label class="gp-input__label">Age</label>
          </div>
          <div class="container--20">
            <label class="gp-input__label">RM</label>
          </div>
        </div>
      </v-ons-list-item>
      <v-ons-list-item
        modifier="gp"
        v-for="(driver, i) in additionalDrivers"
        v-bind:key="i"
      >
        <div
          class="container--flex container--100 flex-wrap--nowrap align-items--center"
        >
          <div class="container--40">
            <v-ons-input
              v-model="driver.driver_name"
              placeholder="Driver Name"
              type="text"
              class="gp-input gp-input--combi-large"
              modifier="gp"
              readonly
            ></v-ons-input>
          </div>
          <div class="mx-8 container--20">
            <v-ons-input
              v-model="driver.age"
              placeholder="Age"
              type="number"
              class="gp-input gp-input--combi-small"
              modifier="gp"
              readonly
            ></v-ons-input>
          </div>
          <div class="container--20">
            <v-ons-input
              type="number"
              class="gp-input gp-input--combi-small"
              modifier="gp"
              readonly
              :value="i > 0 ? policyExcess.each : 0"
            ></v-ons-input>
          </div>
          <div class="container--10" style="text-align:right">
            <ons-button
              style="padding:0"
              modifier="quiet"
              @click="handleEdit(i)"
            >
              <ons-icon
                size="25px"
                icon="md-edit"
                style="color: black"
              ></ons-icon>
            </ons-button>
          </div>
          <div class="container--10" style="text-align:right">
            <ons-button
              style="padding:0"
              modifier="quiet"
              @click="handleDelete(i)"
            >
              <ons-icon
                size="25px"
                icon="md-delete"
                style="color: black"
              ></ons-icon>
            </ons-button>
          </div>
        </div>
      </v-ons-list-item>
      <div class="button-container">
        <v-ons-button
          modifier="gp"
          @click="additionalDriverFormVisible = true"
          :disabled="disabled"
        >
          Add driver
        </v-ons-button>
      </div>
    </div>
    <!-- end policyExcess.type === 'additional_drivers'" -->

    <div v-else-if="policyExcess.type === 'all'" style="margin-top: -30px;">
      <v-ons-list-item modifier="gp">
        <div class="container--flex align-items--flex-end">
          <div class="gp-input__label container--75">
            {{
              `All Drivers (RM ${policyExcess.each} per ${policyExcess.unit})`
            }}
          </div>
          <div class="container--25">
            <label class="gp-input__label">RM</label>
            <v-ons-input
              v-model="policyExcess.each"
              type="number"
              class="gp-input gp-input--combi-small"
              modifier="gp"
              disabled
            ></v-ons-input>
          </div>
        </div>
      </v-ons-list-item>
    </div>
    <!-- end policyExcess.type === 'all'" -->

    <v-ons-dialog
      cancelable
      :visible.sync="additionalDriverFormVisible"
      modifier="gp"
    >
      <additional-driver-form
        @save="handleSave"
        :defaultValues="defaultDriverValues"
      />
    </v-ons-dialog>

    <v-ons-popover
      cancelable
      :visible.sync="helpVisible"
      :target="helpTarget"
      :mask-color="'rgba(0, 0, 0, 0.2)'"
    >
      <p style="text-align: center">{{ policyExcess.help }}</p>
    </v-ons-popover>
  </div>
</template>

<script>
// Page components
import AdditionalDriverForm from '@/components/AdditionalDriverForm.vue';

// Utilies
import moment from 'moment';
import _ from 'lodash';

export default {
  props: {
    policyHolderInfo: Object,
    additionalDrivers: Array,
    policyExcess: Object,
    disabled: Boolean,
  },
  data() {
    return {
      additionalDriverFormVisible: false,
      helpVisible: false,
      helpTarget: null,
      policyHolderAge: 0,
      defaultDriverValues: {
        id: null, // identifier for additional driver, used to identify update or insert
        name: '',
        idNo: '',
        relationship: '',
        age: 0,
        drivingYears: 0,
        occupation: '',
      },
    };
  },
  components: {
    AdditionalDriverForm,
  },
  mounted() {
    this.calculateAge();
  },
  watch: {
    policyHolderInfo() {
      this.calculateAge();
    },
  },
  methods: {
    calculateAge() {
      const { policyHolderInfo } = this;
      this.policyHolderAge = moment().diff(policyHolderInfo.dob, 'years');
    },
    handleSave(driver) {
      this.additionalDriverFormVisible = false;
      if (!_.isNil(driver.index)) {
        this.$emit('edit', driver);
      } else {
        this.$emit('add', driver);
      }
      this.defaultDriverValues = {
        index: null, // identifier for additional driver, used to identify update or insert
        name: '',
        idNo: '',
        relationship: '',
        age: 0,
        drivingYears: 0,
        occupation: '',
      };
    },
    handleDelete(index) {
      this.$emit('delete', index);
    },
    handleEdit(index) {
      const data = this.additionalDrivers[index];
      this.defaultDriverValues = {
        index,
        name: data.driver_name,
        idNo: data.id_no,
        relationship: data.relationship_code,
        age: data.age,
        drivingYears: data.driving_years,
        occupation: data.occupation_code,
      };
      this.additionalDriverFormVisible = true;
    },
    showHelp(event) {
      const $vm = this;
      $vm.helpTarget = event;
      $vm.helpVisible = true;
    },
  },
};
</script>
