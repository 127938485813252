<template>
  <v-ons-page modifier="gp home" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="button-group content-header">
      <div class="button-container">
        <v-ons-button modifier="quiet" @click="gotoCustomerView">
          <span class="gp-icon top customer-icon"></span>
          <span class="button-label">Customer</span>
        </v-ons-button>
      </div>
      <div class="button-container">
        <v-ons-button modifier="quiet" @click="gotoTransactionView('all')">
          <span class="gp-icon top transaction-icon"></span>
          <span class="button-label">Transaction</span>
        </v-ons-button>
      </div>
      <div class="button-container">
        <v-ons-button modifier="quiet" @click="gotoTransactionView('expiring')">
          <span class="gp-icon top expiring-icon"></span>
          <span class="button-label">Expiring</span>
        </v-ons-button>
      </div>
      <div class="button-container">
        <v-ons-button modifier="quiet" @click="gotoTransactionView('history')">
          <span class="gp-icon top history-icon"></span>
          <span class="button-label">History</span>
        </v-ons-button>
      </div>
    </div>

    <div class="content-body">
      <div>
        <v-ons-list-item modifier="gp">
          <div class="center">
            <label class="gp-input__label">Insurance Type</label>
            <div class="gp-select__container">
              <v-ons-select modifier="gp" v-model="selectedInsuranceType">
                <option
                  v-for="type in insuranceTypeOptions"
                  :key="`insurance-type-option-${type.value}`"
                  :value="type.value"
                  >{{ type.label }}</option
                >
              </v-ons-select>
            </div>
          </div>
        </v-ons-list-item>

        <!--
        <v-ons-list-item modifier="gp">
          <div class="center">
            <label class="gp-input__label">Coverage Type</label>
            <div class="gp-select__container">
              <v-ons-select
                modifier="gp"
                v-model="policyInfo.coverage_type_code"
                @change="handleChange('policyInfo.coverage_type_code')"
              >
                <option
                  v-for="(v, i) in coverTypeOptions"
                  :key="i"
                  :value="v.value"
                  >{{ v.label }}</option
                >
              </v-ons-select>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item modifier="gp">
          <div class="center">
            <label class="gp-input__label">Condition</label>
            <div class="gp-select__container">
              <v-ons-select
                modifier="gp"
                v-model="vehicleInfo.condition_code"
                @change="handleChange('vehicleInfo.condition_code')"
              >
                <option
                  v-for="(v, i) in conditionOptions"
                  :key="i"
                  :value="v.value"
                  >{{ v.label }}</option
                >
              </v-ons-select>
            </div>
          </div>
        </v-ons-list-item>
        -->
      </div>

      <div class="gp-vehicle-type">
        <button
          v-on:click="setActiveClass(index)"
          class="gp-vehicle-type__btn"
          v-for="(preset, index) in presets"
          :key="index"
        >
          <img
            v-if="activeVehicleIndex === index"
            :src="preset.icons.selected"
            :alt="preset.name"
          />
          <img v-else :src="preset.icons.default" :alt="preset.name" />
        </button>
      </div>
    </div>

    <v-ons-bottom-toolbar modifier="gp gp-v2">
      <v-ons-toolbar-button modifier="gp">
        <v-ons-button modifier="gp">Panel Workshop</v-ons-button>
      </v-ons-toolbar-button>
    </v-ons-bottom-toolbar>

    <v-ons-modal :visible="saving.length > 0 || loading.length > 0">
      <p v-if="saving.length > 0" style="text-align: center">
        <v-ons-icon icon="fa-spinner" spin></v-ons-icon>
        {{ `Saving ${saving[0]}...` }}
      </p>
      <p v-else style="text-align: center">
        <v-ons-icon icon="fa-spinner" spin></v-ons-icon>
        Loading {{ loading[0] }}...
      </p>
    </v-ons-modal>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';

// Views
import Customers from '@/views/client/Customers.vue';
import Transactions from '@/views/motor-insurance/Transactions.vue';
import Payment from '@/views/payment/Payment.vue';
// import PaymentSuccess from '@/views/payment/PaymentSuccess.vue';
import QuoteFormStep1 from '@/views/motor-insurance/QuoteFormStep1.vue';

// Config and utilities
import { mapState } from 'vuex';
//import _ from 'lodash';

export default {
  components: { PageToolbar },
  data() {
    return {
      actionHandled: [], // store handled action names
      activeVehicleIndex: null,
      coverTypeOptions: [],
      conditionOptions: [],
      selectedInsuranceType: 'GIM',
      insuranceTypeOptions: [
        { label: 'General Insurance - Motor', value: 'GIM' },
      ],
    };
  },
  created() {
    const { isShortcodesLoaded, isPresetsLoaded, $store } = this;
    if (!isShortcodesLoaded) {
      $store.dispatch('shortcodes/load');
    }
    if (!isPresetsLoaded) {
      $store.dispatch('presets/load');
    }
  },
  watch: {
    async isShortcodesLoaded() {
      const { isShortcodesLoaded, $store } = this;
      if (isShortcodesLoaded) {
        $store.dispatch('policy/rebuildShortcodes');
      }
    },
  },
  methods: {
    async handlePageShow() {
      const { actionHandler, $store } = this;
      await $store.commit('policy/reset');
      actionHandler();
    },

    handleChange(field) {
      const { $store } = this;
      $store.dispatch('policy/updateShortcodes', { field });
    },

    gotoQuoteFormStep1() {
      const { pageStack } = this;
      pageStack.push({
        extends: QuoteFormStep1,
      });
    },

    gotoCustomerView() {
      const { pageStack } = this;
      pageStack.push({
        extends: Customers,
      });
    },

    gotoTransactionView(type = 'all') {
      const { pageStack } = this;
      if (type === 'expiring') {
        pageStack.push({
          extends: Transactions,
          data: () => ({ expiringOnly: true }),
        });
      } else if (type === 'history') {
        pageStack.push({
          extends: Transactions,
          data: () => ({ historyOnly: true }),
        });
      } else {
        pageStack.push({
          extends: Transactions,
        });
      }
    },

    setActiveClass(index) {
      const { $store, presets, gotoQuoteFormStep1 } = this;
      const preset = presets[index];
      this.activeVehicleIndex = index;

      // Commit preset data
      $store.commit('policy/vehicleInfo', preset.vehicle_info);
      $store.commit('policy/policyInfo', preset.policy_info);
      $store.commit('policy/policyHolderInfo', preset.policy_holder_info);

      gotoQuoteFormStep1();
    },

    async handlePayment({ id, response }) {
      const { pageStack } = this;
      // Handle action data
      const paymentStatus = response.split('|');
      const payment = {
        id,
        statusCode: paymentStatus[0],
        status: paymentStatus[1],
      };
      // Conditionally push payment success or show payment page based on payment
      // success or failure
      //if (paymentStatus[0] === '00') {
      //  pageStack.push({
      //    extends: PaymentSuccess,
      //    data: () => ({
      //      policyId: id,
      //      payment,
      //    }),
      //  });
      //} else {
      //  pageStack.push({
      //    extends: Payment,
      //    data: () => ({
      //      policyId: id,
      //      payment,
      //    }),
      //  });
      //}
      pageStack.push({
        extends: Payment,
        data: () => ({
          policyId: id,
          payment,
        }),
      });
    },

    actionHandler() {
      const { actionHandled, handlePayment } = this;
      // Conditional action based on query string or data
      const qs = new URLSearchParams(window.location.search);
      let action = null;
      if (qs.get('action')) {
        // Query string action handler
        action = {};
        // Combine all qs key values into a single action data object
        for (const entry of qs.entries()) {
          if (entry[0] === 'action') {
            action['name'] = entry[1];
          } else {
            action[entry[0]] = entry[1];
          }
        }
      }

      // Handle actions
      if (action) {
        const actionNameHandleCount = actionHandled.filter(
          ah => ah === action.name
        ).length;
        // Handle payment page if it has not been handled yet
        // Payment should only be handled once
        if (action.name === 'payment' && actionNameHandleCount === 0) {
          handlePayment(action);
          this.actionHandled.push('payment');
        }
      }
    },
  },
  props: ['pageStack'],
  computed: mapState({
    saving: state =>
      Object.keys(state).reduce(
        (acc, m) => acc.concat(state[m].saving ? state[m].saving : []),
        []
      ),
    loading: state =>
      Object.keys(state).reduce(
        (acc, m) => acc.concat(state[m].loading ? state[m].loading : []),
        []
      ),
    // Shortcodes
    isShortcodesLoaded: state => state.shortcodes.isLoaded,
    // Policy
    policyInfo: state => state.policy.policyInfo,
    vehicleInfo: state => state.policy.vehicleInfo,
    // Presets
    isPresetsLoaded: state => state.presets.isLoaded,
    presets: state => state.presets.data,
  }),
};
</script>
