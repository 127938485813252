<template>
  <v-ons-toolbar modifier="gp">
    <div class="left">
      <v-ons-toolbar-button @click="handleBack" modifier="gp">
        <v-ons-icon icon="fa-chevron-left"></v-ons-icon>
      </v-ons-toolbar-button>
    </div>
    <div class="center">GoProtect</div>
    <div class="right">{{ appVersion }}</div>
  </v-ons-toolbar>
</template>

<script>
import { VERSION } from '@/lib/constants';
import { handleBackButton } from '@/lib/pageHelpers';

export default {
  props: { pageStack: Array },
  data() {
    return {
      appVersion: VERSION,
    };
  },
  methods: {
    handleBack(e) {
      e.preventDefault();
      handleBackButton(this);
    },
  },
};
</script>
