<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <div class="gp-form__label">Step 7</div>
      <div class="gp-form__sublabel">Quotation</div>

      <!-- Quotation -->
      <div>
        <quote-board
          :vendor-premium="premium"
          :addons="policyInfo.addons"
          :total-payable="policyInfo.total_payable"
        />
        <insured-details
          :policyHolderInfo="policyHolderInfo"
          :policyInfo="policyInfo"
          :vehicleInfo="vehicleInfo"
        />
        <addon-details :addons="addons" />
      </div>
      <!-- End Quotation -->

      <!-- Payment mode -->
      <div>
        <div class="gp-form__sublabel">Payment Mode</div>
        <v-ons-list-item modifier="gp">
          <div class="center container--block" style="width: 100%;">
            <div>
              <div class="gp-input__label">
                Credit Balance
                {{
                  gateway.gopay.balance ? `: RM ${gateway.gopay.balance}` : ''
                }}
              </div>
              <v-ons-button
                @click="renderPaymentPage('gopay')"
                modifier="gp"
                :disabled="!gateway.gopay.isEnabled"
              >
                GoPay
              </v-ons-button>
            </div>

            <div>
              <div class="gp-input__label">
                Internet Banking / Credit or Debit Card
              </div>
              <v-ons-button
                @click="renderPaymentPage('fpx')"
                modifier="gp"
                :disabled="!gateway.fpx.isEnabled"
              >
                FPX
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>
      </div>
      <!-- End Payment mode -->

      <!-- Payment link -->
      <div>
        <div class="my-8 gp-form__sublabel">FPX Payment Link</div>
        <div class="my-8">
          <v-ons-list-item modifier="gp">
            <div class="center container--100">
              <div class="gp-pre-container">
                <pre class="gp-pre"><code>{{ anonPaymentUrl }}</code></pre>
              </div>
            </div>
          </v-ons-list-item>
        </div>

        <div class="button-container">
          <v-ons-button
            modifier="gp"
            v-clipboard:copy="anonPaymentUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            :disabled="!isPaymentLinkEnabled"
          >
            Copy payment link
          </v-ons-button>
        </div>
      </div>
      <!-- End Payment link -->

      <!-- Toasters -->
      <v-ons-toast
        :visible.sync="copyErrored"
        animation="ascend"
        modifier="error"
      >
        Yikes, something is wrong please try again later.
        <button @click="copyErrored = false">ok</button>
      </v-ons-toast>

      <v-ons-toast
        :visible.sync="copySuccess"
        animation="ascend"
        modifier="success"
      >
        Payment link has been copied.
        <button @click="copySuccess = false">ok</button>
      </v-ons-toast>

      <!-- Call to actions -->
      <div class="button-container">
        <v-ons-button @click="saveQuotation" modifier="gp" class="secondary">
          Save Quotation
        </v-ons-button>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import QuoteBoard from '@/components/QuoteBoard.vue';
import InsuredDetails from '@/components/InsuredDetails.vue';
import AddonDetails from '@/components/AddonDetails.vue';
import PaymentSuccess from '@/views/payment/PaymentSuccess.vue';

// Utilities and config
import { mapState, mapMutations, mapActions } from 'vuex';
import { API_BASE_URL, PAYMENT_URL, POLICY_STATUS_LIST } from '@/lib/constants';

export default {
  props: ['pageStack'],
  components: {
    PageToolbar,
    QuoteBoard,
    InsuredDetails,
    AddonDetails,
  },
  data() {
    return {
      copyErrored: false,
      copySuccess: false,
      premium: {},
      policyId: null,
    };
  },
  async mounted() {
    const { payment, $ons, setPremium } = this;
    // Redirect back to payment page after payment is made
    if (payment && payment.statusCode != '00') {
      $ons.notification.toast(
        `Payment failed with the error: ${payment.status}`,
        {
          modifier: 'error',
          timeout: 3000,
        }
      );
    }
    setPremium();
  },
  watch: {
    policyInfo() {
      this.setPremium();
      this.handlePaymentSuccess();
    },
  },
  methods: {
    ...mapMutations({
      resetPolicy: 'policy/reset',
      commitPolicyInfo: 'policy/policyInfo',
    }),

    ...mapActions({
      loadPolicy: 'policy/loadData',
    }),

    handlePaymentSuccess() {
      const { policyInfo } = this;
      const conf = POLICY_STATUS_LIST.find(
        s => s.code === policyInfo.status_code
      );
      const isPaid = conf && conf.isPaid ? conf.isPaid : false;
      if (isPaid) {
        this.pageStack.pop();
        this.pageStack.push({
          extends: PaymentSuccess,
        });
      }
    },

    async handlePageShow() {
      const {
        policyId,
        resetPolicy,
        commitPolicyInfo,
        loadPolicy,
        checkQuotationValidity,
      } = this;

      // Load policy
      if (policyId) {
        await resetPolicy(); // reset data first
        await commitPolicyInfo({
          id: policyId,
        });
        await loadPolicy(); // then load policy
      }

      checkQuotationValidity();
    },

    checkQuotationValidity() {
      const { $ons, policyInfo } = this;
      // Check quotation validity
      const expiredPolicy = POLICY_STATUS_LIST.find(
        psl => psl.code === policyInfo.status_code
      );
      // Negetive if cover from is before today's date, must be positive number
      if (expiredPolicy.isExpired) {
        $ons.notification.alert(
          "Expired quotation, from date must be before today's date, please check your phone's date is correct."
        );
        this.pageStack.pop();
      }
    },

    setPremium() {
      const { policyInfo, premiumAmounts } = this;
      this.premium = premiumAmounts.find(
        pa => pa.provider_code === policyInfo.provider_code
      );
    },

    onCopy() {
      this.copySuccess = !this.copySuccess;
    },

    onError() {
      this.copyErrored = !this.copyErrored;
    },

    renderPaymentPage(method) {
      const { policyInfo } = this;
      window.location =
        `${API_BASE_URL}/payments/${method}/${policyInfo.reference_no}` +
        `?token=${window.localStorage.getItem('token')}`;
    },

    saveQuotation() {
      this.pageStack.pop();
    },
  },
  computed: {
    ...mapState({
      addons: state => state.policy.addons,
      policyInfo: state => state.policy.policyInfo,
      policyHolderInfo: state => state.policy.policyHolderInfo,
      vehicleInfo: state => state.policy.vehicleInfo,
      premiumAmounts: state => state.policy.premiumAmounts,
      gateway: state => state.policy.payment.gateway,
      isPaymentLinkEnabled: state => state.policy.payment.isPaymentLinkEnabled,
    }),
    anonPaymentUrl() {
      return `${PAYMENT_URL}/${this.policyInfo.reference_no}`;
    },
  },
};
</script>
