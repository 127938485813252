<template>
  <v-ons-page modifier="gp error">
    <page-toolbar :pageStack="pageStack" />
    <div class="message">{{ `Sorry, ${error}` }}</div>
    <div class="container--flex align-items--center direction--column">
      <div class="my-8 container--100">
        <v-ons-button @click="goBack" class="button--gp secondary" modifier="gp"
          >Cancel</v-ons-button
        >
      </div>
      <div class="my-8 container--100">
        <v-ons-button @click="gotoHome" modifier="gp">Home</v-ons-button>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import PageToolbar from '@/components/PageToolbar.vue';
export default {
  components: {
    PageToolbar,
  },
  props: ['pageStack'],
  data() {
    return {
      error: null,
    };
  },
  methods: {
    goBack() {
      this.pageStack.pop();
    },
    gotoHome() {
      const { pageStack } = this;
      this.pageStack.splice(1, pageStack.length - 1);
    },
  },
};
</script>
