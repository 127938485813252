// Utilities
import _ from 'lodash';
import moment from 'moment';
//import { required, integer } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';
import * as shortcodeHelpers from '@/lib/shortcodeHelpers';

// Services
import VehiclesService from '@/services/vehicles';

// Config
import { VEHICLE } from '@/lib/constants';
const { SHORTCODE_FIELDS } = VEHICLE;

const handleWeirdHttpStatus = commit => err => {
  if (
    err.response.data &&
    err.response.data.name &&
    err.response.data.message
  ) {
    commit('serverErrors', [
      `${err.response.data.name}: ${err.response.data.message}`,
    ]);
  } else {
    commit('serverErrors', [
      `${err.response.status}: ${err.response.statusText}`,
    ]);
  }
};

const defaultState = () => ({
  schema: {
    condition_code: {
      value_type: 'shortcode|vehicle_condition',
      options: null,
    },
    class_code: {
      value_type: 'shortcode|vehicle_class',
      options: null,
    },
  },
  validators: {
    vehicle: {
      condition_code: { required },
      class_code: { required },
    },
  },
  vehicle: {
    id: null,
    registration_no: '',
    voc_no: '',
    chassis_no: '',
    engine_no: '',
    make_code: '',
    model_code: '',
    engine_capacity: 0,
    motor_capacity: 0,
    unit_capacity: 0,
    load_capacity: 0,
    year: moment().format('YYYY'),
    seating_capacity: 4,
    body_code: '',
    fuel_code: '',
    car_type_code: '',
    class_code: '',
    assembler_code: '',
    condition_code: '',
    use_state_code: '',
    status_code: 'A',
    customer_id: null,
    unit_type_code: '',
    purchase_price: 0,
  },
  customer: {
    id: null,
  },
  shortcodes: {},
  isLocked: false,
  serverErrors: [],
  loading: [],
  saving: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      Object.assign(state, defaultState());
    },
    switchLoading(state, loadingLabel) {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    switchSaving(state, savingDataLabel) {
      const labelIndex = state.saving.findIndex(l => l === savingDataLabel);
      if (labelIndex > -1) {
        state.saving.splice(labelIndex, 1);
      } else {
        state.saving.push(savingDataLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.saving.length < 1) {
        state.saving = [];
      }
    },
    isLocked: (state, data) => (state.isLocked = data),
    vehicle: (state, data = []) => (state.vehicle = data),
    customerId: (state, id) => (state.vehicle.customer_id = id),
    customer: (state, data = []) => (state.customer = data),
    id: (state, id) => (state.vehicle.id = Number(id)),
    serverErrors: (state, data = []) => (state.serverErrors = data),
    schema: (state, schema = {}) => (state.schema = schema),
    validators: (state, validators = {}) => (state.validators = validators),
    shortcodes: (state, shortcodes) =>
      (state.shortcodes = Object.assign({}, state.shortcodes, shortcodes)),
  },
  actions: {
    updateShortcodeValues({ state, commit }) {
      const shortcodes = _.cloneDeep(state.shortcodes);
      const vehicleState = _.cloneDeep(state.vehicle);
      for (const sf of SHORTCODE_FIELDS) {
        const value = _.get(state, `vehicle.${sf.field}`);
        const options = _.get(shortcodes, sf.category);
        // Set only fields with invalid shortcode
        if (!options.find(s => s.shortcode === value)) {
          // Find default shortcode to use
          let defaultShortcode = options.find(s => s.is_default === true);
          // Set to first shortcode if no default value
          if (!defaultShortcode) {
            defaultShortcode = options[0];
          }
          // Update state
          vehicleState[sf.field] = defaultShortcode.shortcode;
        }
      }
      commit('vehicle', vehicleState);
    },

    async updateShortcodeOptions({ rootState, state, commit }, { field }) {
      const shortcodes = Object.assign({}, rootState.shortcodes.categories);

      // Lookup field's shortcodes configuration
      const sf = SHORTCODE_FIELDS.find(sf => sf.field === field);
      if (!sf) {
        return;
      }

      // Retrieve full shortcodes
      const childrenShortcodes = shortcodeHelpers.getChildrenShortcodes(
        shortcodes,
        sf.category,
        state.vehicle[field]
      );
      // Assign it based on config
      await commit('shortcodes', childrenShortcodes);
    },

    async updateShortcodes({ dispatch }, { field }) {
      await dispatch('updateShortcodeOptions', { field });
      dispatch('updateShortcodeValues');
    },

    async rebuildShortcodes({ rootState, commit, dispatch }) {
      const shortcodes = Object.assign({}, rootState.shortcodes.categories);

      // Init options by assigning all shortcodes
      const options = {};
      for (const sf of SHORTCODE_FIELDS) {
        options[sf.category] = shortcodes[sf.category];
      }
      await commit('shortcodes', options);

      // Then assign shortcode options by selected value
      // if value is empty, assign empty array to shortcode options
      for (const category in options) {
        const { field } = SHORTCODE_FIELDS.find(sf => sf.category === category);
        await dispatch('updateShortcodes', { field });
      }
    },

    async loadSchema({ commit, state }) {
      commit('switchLoading', 'schema');

      // Request schema
      const schema = await VehiclesService.schema(
        state.vehicle.customer_id,
        state.vehicle,
        state.vehicle.id
      )
        .then(d => d.data)
        .catch(handleWeirdHttpStatus(commit));
      // Parse schema
      const validators = { vehicle: {} };
      for (let field of Object.keys(schema)) {
        // Validators
        validators.vehicle[field] = {};
        if (schema[field].required) {
          validators.vehicle[field].required = required;
        }
      }
      commit('schema', schema);
      commit('validators', validators);
      commit('switchLoading', 'schema');
    },

    async loadData({ commit, state }) {
      if (state.vehicle.id) {
        commit('switchLoading', 'vehicle');
        const response = await VehiclesService.view(state.vehicle.id).catch(
          handleWeirdHttpStatus(commit)
        );
        commit('switchLoading', 'vehicle');
        if (response.data) {
          await commit('vehicle', response.data);
          await commit('customer', response.data.Customer);
          commit('isLocked', true);
        }
      }
    },

    async save({ commit, state }) {
      commit('switchSaving', 'vehicle');
      // Omit fields not in schema
      let ommittedFields = [];
      for (const f in state.vehicle) {
        const inSchema = _.has(state.schema, f);
        const isForbidden =
          state.schema[f] && state.schema[f].value_type === 'forbidden';
        if (!inSchema || isForbidden) {
          ommittedFields = _.concat(ommittedFields, f);
        }
      }
      const response = await VehiclesService.save(
        state.vehicle.customer_id,
        state.vehicle.id,
        _.omit(state.vehicle, ommittedFields)
      ).catch(handleWeirdHttpStatus(commit));
      commit('switchSaving', 'vehicle');
      if (response.data) {
        commit('vehicle', _.omit(response.data, ['Customer']));
        commit('customer', response.data.customer);
      }
    },
  },
};
