<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :page-stack="pageStack" />

    <div class="search-form-container">
      <div class="gp-form__label">Vehicle List</div>
      <vehicle-search-form :search-terms="searchTerms" @search="handleSearch" />
      <div class="search-form">
        <div v-if="!loading && totalPage > 1">
          <paginate
            v-model="currentPage"
            :page-count="totalPage"
            :container-class="'gp-pagination'"
            :page-class="'gp-pagination__item'"
            :active-class="'gp-pagination__item--active'"
            :prev-class="'gp-pagination__prev'"
            :next-class="'gp-pagination__next'"
            :click-handler="handleGotoPage"
          ></paginate>
        </div>

        <listing name="vehicle">
          <vehicle-list-item
            v-for="vehicle in vehicles"
            :key="vehicle.id"
            :vehicle="vehicle"
            :customer="customer"
            @quote="gotoQuoteFormStep1"
            @view="gotoUpsertVehicle"
          ></vehicle-list-item>
        </listing>
      </div>
    </div>
    <v-ons-bottom-toolbar modifier="gp">
      <v-ons-toolbar-button modifier="gp">
        <v-ons-button @click="gotoUpsertVehicle" modifier="gp"
          >Add more</v-ons-button
        >
      </v-ons-toolbar-button>
    </v-ons-bottom-toolbar>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import VehicleSearchForm from '@/components/VehicleSearchForm.vue';
import Listing from '@/components/Listing.vue';
import VehicleListItem from '@/components/VehicleListItem.vue';

// Views
import UpsertVehicle from '@/views/client/UpsertVehicle.vue';
import QuoteFormStep1 from '@/views/motor-insurance/QuoteFormStep1.vue';

// Utilities and config
import { mapState, mapMutations, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  props: ['pageStack'],
  data() {
    return {
      customer: {},
    };
  },
  components: {
    VehicleSearchForm,
    VehicleListItem,
    PageToolbar,
    Listing,
  },
  watch: {
    async customer() {
      const { customer, loadVehicles, updateSearch } = this;
      await updateSearch({ customer_id: customer.id });
      loadVehicles();
    },
  },
  methods: {
    ...mapMutations({
      updatePageNum: 'vehicles/page',
      updateSearch: 'vehicles/search',
    }),
    ...mapActions({
      loadVehicles: 'vehicles/load',
    }),

    async handlePageShow() {
      const { customer, loadVehicles, updateSearch } = this;
      await updateSearch({ customer_id: customer.id });
      loadVehicles();
    },

    async handleSearch(searchTerms) {
      const { customer, updateSearch, loadVehicles, updatePageNum } = this;
      await updateSearch({
        customer_id: customer.id,
        ...searchTerms,
      });
      await updatePageNum(1);
      loadVehicles();
    },

    async handleGotoPage(pageNum) {
      const { updatePageNum, loadVehicles } = this;
      await updatePageNum(pageNum);
      loadVehicles();
    },

    async gotoUpsertVehicle(vehicle) {
      const { pageStack, customer } = this;
      pageStack.push({
        extends: UpsertVehicle,
        data() {
          return {
            customer_id: customer.id,
            id: vehicle ? vehicle.id : null,
          };
        },
      });
    },

    gotoQuoteFormStep1(vehicle) {
      const { pageStack } = this;

      // Forward policy data to step 1
      pageStack.push({
        extends: QuoteFormStep1,
        data() {
          return { loadVehicleId: vehicle.id };
        },
      });
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(pageNum) {
        const { updatePageNum } = this;
        updatePageNum(pageNum);
      },
    },
    ...mapState({
      vehicles: state => state.vehicles.data,
      totalPage: state =>
        Math.ceil(state.vehicles.totalItems / state.vehicles.perPage),
      page: state => state.vehicles.page,
      searchTerms: state => state.vehicles.search,
      errors: state => state.vehicles.errors,
      loading: state =>
        _.concat(state.shortcodes.loading, state.vehicles.loading),
      selectedVehicle: state => state.vehicle.vehicle,
    }),
  },
};
</script>
