<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :allowSelection="true"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 1</div>
        <div class="gp-form__sublabel">
          Get Quote
        </div>
      </state-flag>

      <div>
        <dynamic-form-field
          v-if="schema.vehicleInfo.class_code && !!$v.vehicleInfo.class_code"
          label="Vehicle Class"
          :validator="$v.vehicleInfo.class_code"
          :definition="schema.vehicleInfo.class_code"
          :disabled="locked"
          :key="
            `class_code-${
              schema.vehicleInfo.class_code
                ? schema.vehicleInfo.class_code.value_type
                : 0
            }`
          "
          @change="handleChange('vehicleInfo.class_code')"
          :shortcodes="shortcodes.vehicleInfo"
        />
        <dynamic-form-field
          v-if="
            schema.vehicleInfo.condition_code && !!$v.vehicleInfo.condition_code
          "
          label="Vehicle Condition"
          :validator="$v.vehicleInfo.condition_code"
          :definition="schema.vehicleInfo.condition_code"
          :disabled="locked"
          :key="
            `condition_code-${
              schema.vehicleInfo.condition_code
                ? schema.vehicleInfo.condition_code.value_type
                : 0
            }`
          "
          @change="handleChange('vehicleInfo.condition_code')"
          :shortcodes="shortcodes.vehicleInfo"
        />
        <dynamic-form-field
          v-if="
            schema.vehicleInfo.car_type_code && !!$v.vehicleInfo.car_type_code
          "
          label="Vehicle Type"
          :validator="$v.vehicleInfo.car_type_code"
          :definition="schema.vehicleInfo.car_type_code"
          :key="
            `car_type_code-${
              schema.vehicleInfo.car_type_code
                ? schema.vehicleInfo.car_type_code.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('vehicleInfo.car_type_code')"
          :shortcodes="shortcodes.vehicleInfo"
        />
        <dynamic-form-field
          v-if="schema.vehicleInfo.chassis_no && !!$v.vehicleInfo.chassis_no"
          label="Chassis No."
          :validator="$v.vehicleInfo.chassis_no"
          :definition="schema.vehicleInfo.chassis_no"
          :key="
            `chassis_no-${
              schema.vehicleInfo.chassis_no
                ? schema.vehicleInfo.chassis_no.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('vehicleInfo.chassis_no')"
          :shortcodes="shortcodes.vehicleInfo"
        />
        <dynamic-form-field
          v-if="
            schema.policyHolderInfo.customer_type &&
              !!$v.policyHolderInfo.customer_type
          "
          label="Customer Type"
          :validator="$v.policyHolderInfo.customer_type"
          :definition="schema.policyHolderInfo.customer_type"
          :key="
            `customer-type-${
              schema.policyHolderInfo.customer_type
                ? schema.policyHolderInfo.customer_type.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('policyHolderInfo.customer_type')"
          :shortcodes="shortcodes.policyHolderInfo"
        />
        <dynamic-form-field
          v-if="schema.policyHolderInfo.id_no && !!$v.policyHolderInfo.id_no"
          label="NRIC"
          :validator="$v.policyHolderInfo.id_no"
          :definition="schema.policyHolderInfo.id_no"
          :key="
            `id_no-${
              schema.policyHolderInfo.id_no
                ? schema.policyHolderInfo.id_no.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('policyHolderInfo.id_no')"
          :shortcodes="shortcodes.policyHolderInfo"
        />
        <dynamic-form-field
          v-if="
            schema.policyHolderInfo.company_regno &&
              !!$v.policyHolderInfo.company_regno
          "
          label="Company Reg. No"
          :validator="$v.policyHolderInfo.company_regno"
          :definition="schema.policyHolderInfo.company_regno"
          :key="
            `id_no-${
              schema.policyHolderInfo.company_regno
                ? schema.policyHolderInfo.company_regno.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('policyHolderInfo.company_regno')"
          :shortcodes="shortcodes.policyHolderInfo"
        />
        <dynamic-form-field
          v-if="
            schema.vehicleInfo.registration_no &&
              !!$v.vehicleInfo.registration_no
          "
          label="Vehicle No."
          transform="uppercase"
          :validator="$v.vehicleInfo.registration_no"
          :definition="schema.vehicleInfo.registration_no"
          :key="
            `registration_no-${
              schema.vehicleInfo.registration_no
                ? schema.vehicleInfo.registration_no.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('vehicleInfo.registration_no')"
          :shortcodes="shortcodes.vehicleInfo"
        />
        <!-- engine/motor/load/unit capacity -->
        <div
          v-if="loadPolicyId || loadVehicleId"
          class="container--flex align-items--flex-start form-item__container justify--between"
        >
          <dynamic-form-field
            v-if="
              schema.vehicleInfo.engine_capacity &&
                !!$v.vehicleInfo.engine_capacity
            "
            label="Engine / Electrical Capacity"
            :validator="$v.vehicleInfo.engine_capacity"
            :definition="schema.vehicleInfo.engine_capacity"
            :key="
              `engine_capacity-${
                schema.vehicleInfo.engine_capacity
                  ? schema.vehicleInfo.engine_capacity.value_type
                  : 0
              }`
            "
            :disabled="locked"
            @change="handleChange('vehicleInfo.engine_capacity')"
            :shortcodes="shortcodes.vehicleInfo"
          />
          <dynamic-form-field
            v-if="
              schema.vehicleInfo.motor_capacity &&
                !!$v.vehicleInfo.motor_capacity
            "
            label="Engine / Electrical Capacity"
            :validator="$v.vehicleInfo.motor_capacity"
            :definition="schema.vehicleInfo.motor_capacity"
            :key="
              `motor_capacity-${
                schema.vehicleInfo.motor_capacity
                  ? schema.vehicleInfo.motor_capacity.value_type
                  : 0
              }`
            "
            :disabled="locked"
            @change="handleChange('vehicleInfo.motor_capacity')"
            :shortcodes="shortcodes.vehicleInfo"
          />
          <dynamic-form-field
            v-if="
              schema.vehicleInfo.load_capacity && !!$v.vehicleInfo.load_capacity
            "
            label="Load Capacity"
            :validator="$v.vehicleInfo.load_capacity"
            :definition="schema.vehicleInfo.load_capacity"
            :key="
              `load_capacity-${
                schema.vehicleInfo.load_capacity
                  ? schema.vehicleInfo.load_capacity.value_type
                  : 0
              }`
            "
            :disabled="locked"
            @change="handleChange('vehicleInfo.load_capacity')"
            :shortcodes="shortcodes.vehicleInfo"
          />
          <dynamic-form-field
            v-if="
              schema.vehicleInfo.unit_capacity && !!$v.vehicleInfo.unit_capacity
            "
            label="Unit Capacity"
            :validator="$v.vehicleInfo.unit_capacity"
            :definition="schema.vehicleInfo.unit_capacity"
            :key="
              `unit_capacity-${
                schema.vehicleInfo.unit_capacity
                  ? schema.vehicleInfo.unit_capacity.value_type
                  : 0
              }`
            "
            :disabled="locked"
            @change="handleChange('vehicleInfo.unit_capacity')"
            :shortcodes="shortcodes.vehicleInfo"
          />
          <div class="container--25 ml-8">
            <dynamic-form-field
              v-if="
                schema.vehicleInfo.unit_type_code &&
                  !!$v.vehicleInfo.unit_type_code
              "
              label="Unit Type"
              :validator="$v.vehicleInfo.unit_type_code"
              :definition="schema.vehicleInfo.unit_type_code"
              :key="
                `unit_type_code-${
                  schema.vehicleInfo.unit_type_code
                    ? schema.vehicleInfo.unit_type_code.value_type
                    : 0
                }`
              "
              :disabled="locked"
              @change="handleChange('vehicleInfo.unit_type_code')"
              :shortcodes="shortcodes.vehicleInfo"
            />
          </div>
        </div>
        <!-- end of engine/motor/load/unit capacity -->

        <cover-period
          v-if="$v.policyInfo.cover_from"
          :schema="schema.policyInfo"
          :validator="$v.policyInfo"
          :disabled="locked"
          :shortcodes="shortcodes.policyInfo"
          v-bind:cover_from.sync="$v.policyInfo.cover_from.$model"
          v-bind:cover_to.sync="$v.policyInfo.cover_to.$model"
        />
        <dynamic-form-field
          v-if="
            schema.policyInfo.coverage_type_code &&
              !!$v.policyInfo.coverage_type_code
          "
          label="Type of Cover"
          :validator="$v.policyInfo.coverage_type_code"
          :definition="schema.policyInfo.coverage_type_code"
          :key="
            `coverage_type_code-${
              schema.policyInfo.coverage_type_code
                ? schema.policyInfo.coverage_type_code.value_type
                : 0
            }`
          "
          :disabled="locked"
          @change="handleChange('policyInfo.coverage_type_code')"
          :shortcodes="shortcodes.policyInfo"
        />

        <!-- sum insured and ism -->
        <div
          class="container--flex form-item__container justify--between"
          v-if="schema.policyInfo.sum_insured && !!$v.policyInfo.sum_insured"
        >
          <dynamic-form-field
            label="Sum Insured"
            :validator="$v.policyInfo.sum_insured"
            :definition="schema.policyInfo.sum_insured"
            :disabled="
              locked || schema.policyInfo.sum_insured.value_type !== 'number'
            "
            :shortcodes="shortcodes.policyInfo"
            @change="handleChange('policyInfo.sum_insured')"
          />

          <div class="button-container ml-8">
            <v-ons-button
              :disabled="
                locked || schema.policyInfo.sum_insured.value_type !== 'number'
              "
              @click="getISM"
              modifier="gp"
              class="container--flex align-items--center"
            >
              ISM Check
            </v-ons-button>
          </div>
        </div>
        <!-- end of sum insured and ism -->

        <!-- transfer ncd -->
        <div
          class="gp-form__group"
          v-if="
            schema.policyInfo.prev_vehicle_regno &&
              !!$v.policyInfo.prev_vehicle_regno
          "
        >
          <div class="gp-form__label">Existing or Transfer NCD</div>
          <div class="container--flex form-item__container justify--between">
            <dynamic-form-field
              label="Vehicle Reg No."
              :validator="$v.policyInfo.prev_vehicle_regno"
              :definition="schema.policyInfo.prev_vehicle_regno"
              :disabled="locked"
              @change="handleChange('policyInfo.prev_vehicle_regno')"
              :shortcodes="shortcodes.policyInfo"
            />

            <div class="button-container ml-8">
              <v-ons-button
                class="container--flex align-items--center"
                :disabled="isLoadingNCD"
                @click="checkNCD"
                modifier="gp"
                >{{ isLoadingNCD ? 'Checking...' : 'NCD Check' }}</v-ons-button
              >
            </div>
          </div>
          <div>
            <label class="gp-input__label">NCD on Policy (%)</label>
            <v-ons-input
              v-model="prevVehicleNcd"
              disabled
              type="number"
              class="gp-input"
              modifier="gp"
              placeholder="NCD on Policy (%)"
            ></v-ons-input>
          </div>
        </div>
        <!-- end of transfer ncd -->

        <!-- Same button different functionality -->
        <div class="button-container" v-if="formType === 'long'">
          <v-ons-button
            class="button--gp primary"
            modifier="gp"
            :disabled="$v.$invalid || !isReady()"
            @click="handleNext"
          >
            Confirm
          </v-ons-button>
        </div>
        <div class="button-container" v-else>
          <v-ons-button
            class="button--gp primary"
            modifier="gp"
            :disabled="$v.$invalid || !isReady()"
            @click="getISM"
          >
            Get Quote
          </v-ons-button>
        </div>
      </div>

      <!-- variant selection form dialog -->
      <v-ons-dialog
        v-if="marketValues.length > 0"
        cancelable
        :visible.sync="variantDialogVisible"
        modifier="gp"
      >
        <variant-selection-form
          :policy="policyInfo"
          :variants="marketValues"
          :vehicle="vehicleInfo"
          :schema="schema.vehicleInfo"
          :disabled="locked"
          :shortcodes="shortcodes.vehicleInfo"
          :validators="validators.vehicleInfo"
          @change="handleVsfChange"
          @next="handleVariantSelected"
        />
      </v-ons-dialog>
      <!-- end variant selection form dialog -->

      <!-- vehicle selection form dialog -->
      <v-ons-dialog
        cancelable
        :visible.sync="vehicleSelectionVisible"
        modifier="gp"
      >
        <vehicle-selection-form
          v-bind:customer_id.sync="selectedCustomerId"
          @next="handleVehicleSelected"
          key="quote-1-vehicle-selection-form"
        >
          <template #gotoCustomerCreate>
            <v-ons-button
              modifier="gp"
              class="button__gp primary"
              @click="gotoCustomerCreate"
            >
              Create Customer
            </v-ons-button>
          </template>
          <!--
          <template #gotoVehicleCreate>
            <v-ons-button
              modifier="gp"
              @click="gotoVehicleCreate"
              :disabled="!selectedCustomerId"
            >
              Create Vehicle
            </v-ons-button>
          </template>
          -->
        </vehicle-selection-form>
      </v-ons-dialog>
      <!-- end vehicle selection form dialog -->
    </div>
  </v-ons-page>
</template>

<script>
// Views
import QuoteFormStep2 from '@/views/motor-insurance/QuoteFormStep2.vue';
import UpsertCustomer from '@/views/client/UpsertCustomer.vue';
//import UpsertVehicle from '@/views/client/UpsertVehicle.vue';

// Page components
import VariantSelectionForm from '@/components/VariantSelectionForm.vue';
import VehicleSelectionForm from '@/components/VehicleSelectionForm.vue';
import PageToolbar from '@/components/PageToolbar.vue';
import DynamicFormField from '@/components/DynamicFormField.vue';
import CoverPeriod from '@/components/CoverPeriod.vue';
import StateFlag from '@/components/StateFlag.vue';

// Utilites and config
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';
import ErrorHandlerMixin from '@/views/ErrorHandlerMixin';
import { POLICY, CUSTOMER_TYPES } from '@/lib/constants';
const { VEHICLE_CLASS_CONFIG, CAR_TYPE_CONFIG } = POLICY;

export default {
  mixins: [QuoteFormMixin, ErrorHandlerMixin],
  props: {
    pageStack: Array,
  },
  components: {
    VariantSelectionForm,
    VehicleSelectionForm,
    PageToolbar,
    DynamicFormField,
    CoverPeriod,
    StateFlag,
  },
  data() {
    return {
      variantDialogVisible: false,
      vehicleSelectionVisible: false,
      loadVehicleId: null,
      loadPolicyId: null,
      isLoadingNCD: false,
      nextPage: QuoteFormStep2,
      confirmDispatchActions: [
        'policy/draft',
        //'policy/rebuildShortcodes',
        'policy/loadSchema',
      ],
      selectedCustomerId: '',
      formType: 'long',
    };
  },
  validations() {
    const { validators, vehicleInfo } = this;
    const v = {
      policyInfo: {},
      policyHolderInfo: {},
      vehicleInfo: {},
    };

    // Determine the short or long form versions
    const conf = VEHICLE_CLASS_CONFIG.find(
      fdp => fdp.class_code === vehicleInfo.class_code
    );
    this.formType = conf ? conf.formType : 'long';

    if (!_.isEmpty(validators.policyHolderInfo)) {
      v.policyHolderInfo = {
        id_no: _.get(validators, 'policyHolderInfo.id_no'),
        company_regno: _.get(validators, 'policyHolderInfo.company_regno'),
      };
      // Remove forms for short version form
      if (this.formType === 'short') {
        v.policyHolderInfo = Object.assign({}, v.policyHolderInfo, {
          customer_type: _.get(validators, 'policyHolderInfo.customer_type'),
        });
      }
      // Remove undefined / null validators
      v.policyHolderInfo = _.omitBy(v.policyHolderInfo, _.isNil);
    }
    if (!_.isEmpty(validators.vehicleInfo)) {
      v.vehicleInfo = {
        class_code: _.get(validators, 'vehicleInfo.class_code'),
        condition_code: _.get(validators, 'vehicleInfo.condition_code'),
        car_type_code: _.get(validators, 'vehicleInfo.car_type_code'),
        registration_no: _.get(validators, 'vehicleInfo.registration_no'),
        chassis_no: _.get(validators, 'vehicleInfo.chassis_no'),
        engine_capacity: _.get(validators, 'vehicleInfo.engine_capacity'),
        motor_capacity: _.get(validators, 'vehicleInfo.motor_capacity'),
        load_capacity: _.get(validators, 'vehicleInfo.load_capacity'),
        unit_capacity: _.get(validators, 'vehicleInfo.unit_capacity'),
        unit_type_code: _.get(validators, 'vehicleInfo.unit_type_code'),
      };
      // Remove forms for short version form
      if (this.formType === 'short') {
        v.vehicleInfo = _.omit(v.vehicleInfo, [
          'class_code',
          'chassis_no',
          'engine_capacity',
          'motor_capacity',
          'load_capacity',
          'unit_capacity',
          'unit_type_code',
        ]);
      }
      // Remove undefined / null validators
      v.vehicleInfo = _.omitBy(v.vehicleInfo, _.isNil);
    }
    if (!_.isEmpty(validators.policyInfo)) {
      v.policyInfo = {
        cover_from: _.get(validators, 'policyInfo.cover_from'),
        cover_to: _.get(validators, 'policyInfo.cover_to'),
        coverage_type_code: _.get(validators, 'policyInfo.coverage_type_code'),
        sum_insured: _.get(validators, 'policyInfo.sum_insured'),
        //variant_id: _.get(validators, 'policyInfo.variant_id'),
        prev_vehicle_regno: _.get(validators, 'policyInfo.prev_vehicle_regno'),
      };
      // Remove forms for short version form
      if (this.formType === 'short') {
        v.policyInfo = _.omit(v.policyInfo, [
          'cover_from',
          'cover_to',
          'coverage_type_code',
          'sum_insured',
          //'variant_id',
          'prev_vehicle_regno',
        ]);
      }
      // Remove undefined / null validators
      v.policyInfo = _.omitBy(v.policyInfo, _.isNil);
    }
    return v;
  },

  watch: {
    policyId() {
      const { policyId } = this;
      if (policyId) {
        this.loadVehicleId = null;
        this.loadPolicyId = policyId;
      }
    },
  },

  methods: {
    ...mapMutations({
      saveVehicleId: 'vehicle/id',
      resetPolicy: 'policy/reset',
      commitPolicyHolderInfo: 'policy/policyHolderInfo',
      commitVehicleInfo: 'policy/vehicleInfo',
      commitPolicyInfo: 'policy/policyInfo',
    }),

    ...mapActions({
      loadIsm: 'policy/loadISM',
      loadNcd: 'policy/checkVehicleNcd',
      loadVehicleData: 'vehicle/loadData',
      loadPolicyData: 'policy/loadData',
      loadCustomerOptions: 'customer_options/load',
      rebuildShortcodes: 'policy/rebuildShortcodes',
      updateShortcodes: 'policy/updateShortcodes',
      renewPolicy: 'policy/renew',
    }),

    async handlePageShow() {
      const {
        loadVehicleId,
        loadPolicyId,
        loadSchema,
        saveVehicleId,
        commitPolicyInfo,
        commitVehicleInfo,
        commitPolicyHolderInfo,
        resetPolicy,
        loadVehicleData,
        loadPolicyData,
        loadCustomerOptions,
        rebuildShortcodes,
        renewPolicyId,
        renewPolicy,
      } = this;
      if (loadVehicleId) {
        // Retrieve vehicle data
        await saveVehicleId(loadVehicleId);
        await loadVehicleData();
        // Set policy data
        await resetPolicy();
        await commitPolicyHolderInfo(this.loadedVehicle.Customer);
        await commitVehicleInfo(this.loadedVehicle);
      } else if (loadPolicyId) {
        await resetPolicy(); // reset data first
        await commitPolicyInfo({ id: loadPolicyId });
        await loadPolicyData(); // then load policy
        this.confirmDispatchActions = ['policy/saveAllInfo'];
      } else if (renewPolicyId) {
        await renewPolicy({ id: renewPolicyId });
      }
      loadCustomerOptions();
      loadSchema();
      rebuildShortcodes();
    },

    async handleVehicleSelected(vehicleId) {
      const { loadVehicleData, saveVehicleId, rebuildShortcodes } = this;
      this.loadVehicleId = vehicleId;
      // Retrieve vehicle data
      await saveVehicleId(vehicleId);
      await loadVehicleData();
      rebuildShortcodes();
      this.vehicleSelectionVisible = false;
    },

    gotoCustomerCreate() {
      this.vehicleSelectionVisible = false;
      this.pageStack.push({ extends: UpsertCustomer });
    },

    //gotoVehicleCreate() {
    //  const { selectedCustomerId, pageStack } = this;
    //  this.vehicleSelectionVisible = false;
    //  pageStack.push({
    //    extends: UpsertVehicle,
    //    data() {
    //      return { customer_id: selectedCustomerId };
    //    },
    //  });
    //},

    async handleChange(field) {
      const {
        validate,
        updateShortcodes,
        loadSchema,
        commitPolicyHolderInfo,
        commitPolicyInfo,
        policyHolderInfo,
        policyInfo,
        vehicleInfo,
      } = this;

      // Fields that require schema reload
      const reloadSchemaFields = [
        'vehicleInfo.class_code',
        'vehicleInfo.condition_code',
        'policyInfo.coverage_type_code',
        'policyHolderInfo.customer_type',
        'vehicleInfo.unit_type_code',
      ];
      if (reloadSchemaFields.indexOf(field) >= 0) {
        loadSchema();
      }

      // Update customer type if car type is changed
      for (const c of CUSTOMER_TYPES) {
        const custTypeConf = c.carTypeCodes.find(
          ctc => ctc === vehicleInfo.car_type_code
        );
        if (custTypeConf) {
          await commitPolicyHolderInfo({
            ...policyHolderInfo,
            customer_type: c.value,
          });
        }
      }

      // Update permitted_driver_code if car type changed
      if (field === vehicleInfo.car_type_code) {
        const policyConf = CAR_TYPE_CONFIG.find(
          ctc => ctc.carTypeCode === vehicleInfo.car_type_code
        );
        if (policyConf) {
          await commitPolicyInfo({
            ...policyInfo,
            permitted_driver_code: policyConf.defPermittedDriverCode,
          });
        }
      }

      // Post handler
      updateShortcodes({ field });
      validate();
    },

    async handleVariantSelected() {
      const { validate, handleNext, formType } = this;
      validate();
      this.variantDialogVisible = false;
      if (formType !== 'long') {
        handleNext();
      }
    },

    async checkNCD() {
      const { loadNcd } = this;

      this.isLoadingNCD = true;
      await loadNcd();
      this.isLoadingNCD = false;
    },

    async getISM() {
      const {
        loadIsm,
        loadPolicyId,
        loadVehicleId,
        rebuildShortcodes,
        commitPolicyInfo,
        $ons,
      } = this;
      await loadIsm();
      rebuildShortcodes();
      // Reset variant id and show dialog
      await commitPolicyInfo({
        sum_insured: 0,
        variant_id: null,
      });
      if (!_.isEmpty(this.marketValues)) {
        if (_.isEmpty(this.errors)) {
          this.variantDialogVisible = true;
        }
      } else {
        // Show vehicle selection form
        if (!loadPolicyId && !loadVehicleId) {
          $ons.notification.alert(
            'No vehicle record found, please select a vehicle to continue'
          );
          this.vehicleSelectionVisible = true;
        } else {
          $ons.notification.alert(
            'No market value found, please enter your sum insured manually'
          );
        }
      }
    },

    async handleVsfChange(changed) {
      const { commitVehicleInfo, commitPolicyInfo } = this;
      const [changedObj, changedField] = changed.field.split('.');
      if (changedObj === 'vehicle') {
        await commitVehicleInfo({
          [changedField]: changed.value,
        });
        if (changed.value) {
          this.variantDialogVisible = false;
          this.getISM();
        }
      } else if (changedObj === 'policy') {
        commitPolicyInfo({
          [changedField]: changed.value,
        });
      }
    },

    handleNext() {
      this.gotoNextPage();
    },

    isReady() {
      const { policyInfo, policyHolderInfo, vehicleInfo } = this.schema;
      if (
        _.isEmpty(policyInfo, true) ||
        _.isEmpty(policyHolderInfo, true) ||
        _.isEmpty(vehicleInfo, true)
      ) {
        return false;
      }
      return true;
    },
  },
  computed: mapState({
    // Used to detect change in loaded vehicle
    loadedVehicle: state => state.vehicle.vehicle,
    prevVehicleNcd: state => {
      let ncd = state.policy.policyInfo.ncd;
      if (
        state.policy.policyInfo.prev_vehicle_regno !==
        state.policy.vehicleInfo.registration_no
      ) {
        ncd = state.policy.prevVehicleNcd;
      }
      if (!ncd || !ncd.current) {
        return 0;
      }
      return Math.round(ncd.current * 100);
    },
    // For variant selection
    marketValues: state => state.policy.marketValues,
    // used to detect change in policy id
    policyId: state => state.policy.policyInfo.id,
  }),
};
</script>
