<template>
  <div class="gp-insured-details">
    <div class="gp-insured-details__group">
      <p class="gp-insured-details__title">Insured Details</p>
      <div class="gp-insured-details__body">
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Name</p>
            <p>:</p>
          </div>
          <p>{{ policyHolderInfo.name }}</p>
        </div>
        <div
          v-if="policyHolderInfo.company_regno"
          class="gp-insured-details__item"
        >
          <div class="gp-insured-details__item-label">
            <p>Company Registration No.</p>
            <p>:</p>
          </div>
          <p>{{ policyHolderInfo.company_regno }}</p>
        </div>
        <div v-if="policyHolderInfo.id_no" class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>NRIC / Passport No.</p>
            <p>:</p>
          </div>
          <p>{{ policyHolderInfo.id_no }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Mobile No.</p>
            <p>:</p>
          </div>
          <p>{{ policyHolderInfo.mobile_no }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Email</p>
            <p>:</p>
          </div>
          <p>{{ policyHolderInfo.email }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Address</p>
            <p>:</p>
          </div>
          <p>
            {{ policyHolderInfo.address1 }},
            {{ policyHolderInfo.address2 && `${policyHolderInfo.address2},` }}
            {{ policyHolderInfo.address3 && `${policyHolderInfo.address3},` }}
            {{ policyHolderInfo.postcode }}, {{ policyHolderInfo.city }},
            {{ policyHolderInfo.state }}
            MALAYSIA
          </p>
        </div>
      </div>
    </div>

    <div class="gp-insured-details__group">
      <p class="gp-insured-details__title"></p>
      <div class="gp-insured-details__body">
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Vehicle Reg No.</p>
            <p>:</p>
          </div>
          <p>{{ vehicleInfo.registration_no }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Make</p>
            <p>:</p>
          </div>
          <p>{{ vehicleInfo.make_code }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Model</p>
            <p>:</p>
          </div>
          <p>{{ vehicleInfo.model_code }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Coverage Type</p>
            <p>:</p>
          </div>
          <p>{{ policyInfo.coverage_type_code }}</p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Period of Cover</p>
            <p>:</p>
          </div>
          <p>
            {{ moment(policyInfo.cover_from).format('YYYY-MM-DD') }} -
            {{ moment(policyInfo.cover_to).format('YYYY-MM-DD') }}
          </p>
        </div>
        <div class="gp-insured-details__item">
          <div class="gp-insured-details__item-label">
            <p>Sum Insured</p>
            <p>:</p>
          </div>
          <p>{{ policyInfo.sum_insured.toFixed(2) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    policyHolderInfo: Object,
    vehicleInfo: Object,
    policyInfo: Object,
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
