<template>
  <div>
    <div class="step-header--gp">
      <div class="step-header--gp__header">
        <slot></slot>
      </div>
      <div class="step-header--gp__flag">
        <img
          class="step-header--gp__flag-action__image"
          :src="selectedSrc"
          alt="location-name"
        />

        <div class="step-header--gp__flag-action__label">
          <div class="step-header--gp__flag-action__label-text">
            {{ selectedLabel }}
          </div>
        </div>

        <v-ons-select
          v-if="allowSelection"
          class="step-header--gp__flag-action__select"
          @change="e => handleChange(e.target.value)"
        >
          <option
            v-for="state in options"
            :value="state.value"
            :key="state.value"
            >{{ state.label }}</option
          >
        </v-ons-select>
      </div>
    </div>
  </div>
</template>

<script>
import { ASSETS_URL } from '@/lib/constants';
import { POLICY } from '@/lib/constants';
const { DEFAULT_STATE } = POLICY;

export default {
  props: {
    selected: {
      type: String,
      required: true,
      default: DEFAULT_STATE,
    },
    options: {
      type: Array,
      required: true,
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSrc: null,
      selectedLabel: '',
    };
  },
  mounted() {
    this.handleChange(this.selected);
  },
  watch: {
    selected() {
      this.handleChange(this.selected);
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('update:state', value);
      const selectedOption = this.options.find(o => o.value === value);
      if (!selectedOption) {
        return;
      }
      this.selectedSrc = `${ASSETS_URL}/images/state_flags/${value}.png`;
      this.selectedLabel = selectedOption.label;
    },
  },
};
</script>
