// Utilities
//import _ from 'lodash';

// Services
import CustomersService from '@/services/customers';

const defaultState = () => ({
  loading: [],
  data: [],
  totalItems: 0,
  perPage: 10,
  page: 1,
  search: {
    all: '',
    status_code: 'A',
  },
  errors: [],
});

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset(state) {
      const options = [].concat(state.options);
      // Prevent options from being assigned
      Object.assign(state, defaultState(), { options });
    },
    switchLoading(state, loadingLabel = '') {
      const labelIndex = state.loading.findIndex(l => l === loadingLabel);
      if (labelIndex > -1) {
        state.loading.splice(labelIndex, 1);
      } else {
        state.loading.push(loadingLabel);
      }
      // NOTE: Workaround for Vue ob object
      if (state.loading.length < 1) {
        state.loading = [];
      }
    },
    data: (state, data = []) => (state.data = data),
    totalItems: (state, data = 0) => (state.totalItems = data),
    perPage: (state, data = 10) => (state.perPage = data),
    page: (state, data = 1) => (state.page = data),
    errors: (state, data) => (state.errors = data),
    search: (state, data) => Object.assign(state.search, data),
  },
  actions: {
    async load({ commit, state }) {
      commit('switchLoading', 'customers');
      const response = await CustomersService.get(
        state.page,
        state.perPage,
        state.search
      ).catch(err => commit('errors', [`${err.name}: ${err.message}`]));
      commit('switchLoading', 'customers');
      commit('data', response.data.data);
      commit('totalItems', response.data.total_items);
      commit('perPage', response.data.length);
      commit('page', response.data.page);
    },
  },
};
