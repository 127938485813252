import axios from '@/lib/axios';

function list() {
  return axios.gpAPI({
    method: 'GET',
    url: '/addons',
  });
}

export default { list };
