//import { GONET_URL } from '@/lib/constants';

//async function _handlePwaBackButton($vm) {
//  if ($vm.pageStack.length > 1) {
//    $vm.pageStack.pop();
//  } else {
//    window.location.href = GONET_URL;
//    //const exit = await $vm.$ons.notification.confirm(
//    //  'Do you want to close the app?'
//    //);
//    //if (exit === 1) {
//    //  navigator.app.exitApp();
//    //}
//  }
//}

function _handleNativeAppBackButton() {
  window.ReactNativeWebView.postMessage('WINDOW_CLOSED');
}

export function handleBackButton($vm) {
  if ($vm.pageStack.length > 1) {
    $vm.pageStack.pop();
  } else {
    _handleNativeAppBackButton();
    //const isNative = _.has(window, 'ReactNativeWebView.postMessage');
    //if (isNative) {
    //  _handleNativeAppBackButton();
    //} else {
    //  _handlePwaBackButton($vm);
    //}
  }
}
