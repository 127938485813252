import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  props: ['pageStack'],
  data() {
    return {
      isFieldsValid: false,
      nextPage: null, // next page component
      ignoreLock: false, // If true, dispatch action regardless of locks on next page
      // List of dispatch actions on next page
      confirmDispatchActions: [
        'policy/savePolicyInfo',
        'policy/saveVehicleInfo',
        'policy/savePolicyHolderInfo',
        'policy/saveExtraBenefits',
        'policy/saveAdditionalDrivers',
      ],
      // Hook function to execute before next page is called
      nextPageHook: {
        before: null,
      },
    };
  },

  created() {
    const { $store, isShortcodesLoaded } = this;
    if (!isShortcodesLoaded) {
      $store.dispatch('shortcodes/load');
    }
  },
  mounted() {
    this.validate();
  },

  watch: {
    // Re-validation watchers
    policyInfo() {
      if (_.has(this, '$v.policyInfo')) {
        this.validate();
      }
    },
    policyHolderInfo() {
      if (_.has(this, '$v.policyHolderInfo')) {
        this.validate();
      }
    },
    vehicleInfo() {
      if (_.has(this, '$v.vehicleInfo')) {
        this.validate();
      }
    },
    validators() {
      this.validate();
    },
  },

  methods: {
    async validate() {
      const { $v } = this;
      if ($v) {
        $v.$touch();
        this.isFieldsValid = !$v.$invalid;
      }
    },

    async gotoNextPage(data = null) {
      const {
        ignoreLock,
        pageStack,
        $store,
        locked,
        errors,
        nextPage,
        confirmDispatchActions,
        nextPageHook,
      } = this;
      // NOTE: Must wait for data update to finish before pushing to next page
      // to prevent validation errors
      if (!locked || ignoreLock) {
        for (const action of confirmDispatchActions) {
          await $store.dispatch(action);
        }
      }
      if (_.isEmpty(errors)) {
        const pageOpts = { extends: nextPage };
        if (data) {
          pageOpts.data = function() {
            return data;
          };
        }

        // Based on nextPageHook.before, decide whether should proceed
        // to next page or not, if it is not defined, proceed as usual
        if (nextPageHook.before) {
          if (typeof nextPageHook.before != 'function') {
            throw new Error('nextPageHook.before is not a function');
          }
          const shouldProceed = await nextPageHook.before.bind(this)(errors);
          if (shouldProceed) {
            // NOTE: Temporary workaraound to wait for previous push to finish
            // in case there is a page push or pop
            setTimeout(() => pageStack.push(pageOpts), 500);
          }
        } else {
          pageStack.push(pageOpts);
        }
      }
    },

    loadSchema() {
      this.$store.dispatch('policy/loadSchema');
    },
  },

  computed: mapState({
    // Detect API errors
    errors: state => state.policy.errors,
    // Used to load shortcodes if not loaded
    isShortcodesLoaded: state => state.shortcodes.loaded,
    // Dynamic schema
    schema: state => state.policy.schema,
    validators: state => state.policy.validators,
    // Fields used in the forms
    policyInfo: state => state.policy.policyInfo,
    vehicleInfo: state => state.policy.vehicleInfo,
    policyHolderInfo: state => state.policy.policyHolderInfo,
    locked: state => state.policy.isLocked,
    // Shortcode options
    shortcodes: state => state.policy.shortcodes,
  }),
};
