<template>
  <div class="gp-form__group" :class="{ error: hasErrors }" ref="formGroup">
    <div :class="{ 'gp-form__group-inline': isInlineLabel }">
      <label class="gp-input__label">
        {{ label }}
        <v-ons-icon
          v-if="help"
          icon="fa-question-circle"
          style="color: #fa5252"
          @click="showHelp($event)"
        ></v-ons-icon>
      </label>
      <div class="gp-input__group">
        <slot />
      </div>
    </div>
    <div class="gp-form__error-container" v-if="hasErrors">
      <div
        class="gp-form__error"
        v-for="error in activeErrorMessages"
        v-bind:key="error"
      >
        {{ error }}
      </div>
    </div>
    <v-ons-popover
      cancelable
      :visible.sync="helpVisible"
      :target="helpTarget"
      :mask-color="'rgba(0, 0, 0, 0.2)'"
    >
      <p style="text-align: center">{{ help }}</p>
    </v-ons-popover>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor';

export default {
  mixins: [singleErrorExtractorMixin],
  props: {
    validator: Object,
    label: String,
    help: String,
    isInlineLabel: Boolean,
  },
  data() {
    return {
      helpVisible: false,
      helpTarget: null,
    };
  },
  methods: {
    showHelp(event) {
      const $vm = this;
      $vm.helpTarget = event;
      $vm.helpVisible = true;
    },
  },
};
</script>
