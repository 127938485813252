<template>
  <v-ons-page modifier="gp">
    <page-toolbar :pageStack="pageStack" />

    <div class="quote-form-cotainer">
      <state-flag
        v-if="shortcodes.vehicleInfo.state"
        :selected="vehicleInfo.use_state_code"
        :options="
          shortcodes.vehicleInfo.state.map(s => ({
            label: s.description,
            value: s.shortcode,
          }))
        "
        :state.sync="vehicleInfo.use_state_code"
      >
        <div class="gp-form__label">Step 4</div>
        <div class="gp-form__sublabel">
          Vehicle's Details
        </div>
      </state-flag>

      <div>
        <div class="quote-form-cotainer">
          <vehicle-form
            variant="minimal"
            :vehicle="vehicleInfo"
            :schema="schema.vehicleInfo"
            :validators="validators.vehicleInfo"
            :disabled="locked"
            :disabledFields="[
              'class_code',
              'condition_code',
              'car_type_code',
              'use_state_code',
            ]"
            @change="handleChange"
            v-bind:validation.sync="validation"
            :shortcodes="shortcodes.vehicleInfo"
          />
          <dynamic-form-field
            v-if="schema.policyInfo.hpc_code"
            label="Hire Purchase Company"
            :validator="$v.policyInfo.hpc_code"
            :definition="schema.policyInfo.hpc_code"
            :key="
              `hpc_code-${
                schema.policyInfo.hpc_code
                  ? schema.policyInfo.hpc_code.value_type
                  : 0
              }`
            "
            :disabled="locked"
            :shortcodes="shortcodes.policyInfo"
          />

          <!-- Call to actions -->
          <div class="button-container">
            <v-ons-button
              @click="gotoNextPage()"
              modifier="gp"
              :disabled="validation.$invalid || $v.$invalid"
              class="primary"
            >
              Next
            </v-ons-button>
          </div>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import VehicleForm from '@/components/VehicleForm.vue';
import StateFlag from '@/components/StateFlag.vue';
import DynamicFormField from '@/components/DynamicFormField.vue';

// Views
import QuoteFormStep5 from '@/views/motor-insurance/QuoteFormStep5.vue';

// Utilities and config
import _ from 'lodash';
import QuoteFormMixin from '@/views/motor-insurance/QuoteFormMixin';
import { mapActions, mapMutations } from 'vuex';

export default {
  mixins: [QuoteFormMixin],
  props: ['pageStack'],
  data() {
    return {
      nextPage: QuoteFormStep5,
      confirmDispatchActions: ['policy/saveAllInfo'],
      validation: {},
    };
  },
  validations() {
    return {
      policyInfo: {
        hpc_code: _.has(this.validators, 'policyInfo.hpc_code')
          ? _.get(this.validators, 'policyInfo.hpc_code')
          : {},
      },
    };
  },
  components: {
    PageToolbar,
    VehicleForm,
    StateFlag,
    DynamicFormField,
  },
  methods: {
    ...mapActions({
      saveVehicleInfo: 'policy/saveVehicleInfo',
      updateShortcodes: 'policy/updateShortcodes',
    }),
    ...mapMutations({
      commitPolicyInfo: 'policy/policyInfo',
      commitVehicleInfo: 'policy/vehicleInfo',
    }),

    async handleChange(field) {
      const {
        commitVehicleInfo,
        saveVehicleInfo,
        updateShortcodes,
        loadSchema,
        validation,
      } = this;
      await commitVehicleInfo(validation.$model);
      const reloadSchemaFields = [
        'condition_code',
        'class_code',
        'unit_type_code',
      ];
      if (_.indexOf(reloadSchemaFields, field) > -1) {
        await saveVehicleInfo();
        loadSchema();
      }
      updateShortcodes({
        field: `vehicleInfo.${field}`,
      });
    },
  },
};
</script>
