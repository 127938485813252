<template>
  <v-ons-page modifier="gp" @show="handlePageShow">
    <page-toolbar :pageStack="pageStack" />

    <div class="search-form-container">
      <div class="gp-form__label">Transactions</div>
      <transaction-search-form
        :key="JSON.stringify(searchTerms) + JSON.stringify(searchOptions)"
        :init-search-terms="
          Object.assign({}, searchTerms, {
            status_code:
              searchTerms.status_code.length > 0
                ? searchTerms.status_code[0]
                : '',
          })
        "
        :showCoverPeriod="expiringOnly || historyOnly"
        :showStatus="!expiringOnly && !historyOnly"
        :searchOptions="searchOptions"
        @search="handleSearch"
      />
      <div class="search-form">
        <div v-if="!loading && totalPage > 1">
          <paginate
            v-model="currentPage"
            :pageCount="totalPage"
            :containerClass="'gp-pagination'"
            :page-class="'gp-pagination__item'"
            :active-class="'gp-pagination__item--active'"
            :prev-class="'gp-pagination__prev'"
            :next-class="'gp-pagination__next'"
            :click-handler="handleGotoPage"
          ></paginate>
        </div>

        <section v-if="errors.length > 0">
          <p class="gp-body--secondary gp-body--alert">
            We're sorry, we're not able to retrieve this information at the
            moment, please try back later...
          </p>
        </section>

        <listing name="transaction">
          <transaction-list-item
            v-for="transaction in transactions"
            :key="transaction.id"
            :transaction="transaction"
            @view="gotoViewTransaction"
            @renew="gotoRenewTransaction"
          />
        </listing>
      </div>
    </div>

    <!-- bottom toolbar -->
    <v-ons-bottom-toolbar modifier="gp">
      <v-ons-toolbar-button modifier="gp">
        <v-ons-button @click="showVehicleSelectionDialog" modifier="gp"
          >Get Quote</v-ons-button
        >
      </v-ons-toolbar-button>
    </v-ons-bottom-toolbar>
    <!-- end bottom toolbar -->

    <!-- vehicle selection form dialog -->
    <v-ons-dialog cancelable :visible.sync="showVehicleSelection" modifier="gp">
      <vehicle-selection-form @next="gotoCreatePolicy">
        <template #cancel>
          <v-ons-button modifier="gp" @click="closeVehicleSelectionDialog">
            Cancel
          </v-ons-button>
        </template>
      </vehicle-selection-form>
    </v-ons-dialog>
    <!-- end vehicle selection form dialog -->
  </v-ons-page>
</template>

<script>
// Page components
import PageToolbar from '@/components/PageToolbar.vue';
import TransactionSearchForm from '@/components/TransactionSearchForm.vue';
import VehicleSelectionForm from '@/components/VehicleSelectionForm.vue';
import TransactionListItem from '@/components/TransactionListItem.vue';
import Listing from '@/components/Listing.vue';

// Views
import QuoteFormStep1 from '@/views/motor-insurance/QuoteFormStep1.vue';
import Payment from '@/views/payment/Payment.vue';
//import PaymentSuccess from '@/views/payment/PaymentSuccess.vue';

// Utilities and config
import { mapState, mapActions, mapMutations } from 'vuex';
//import _ from 'lodash';
import { EXPIRING_DAYS, POLICY_STATUS_LIST } from '@/lib/constants';

export default {
  props: ['pageStack'],
  data: () => ({
    showVehicleSelection: false,
    expiringOnly: false,
    historyOnly: false,
  }),
  components: {
    TransactionSearchForm,
    VehicleSelectionForm,
    PageToolbar,
    TransactionListItem,
    Listing,
  },
  methods: {
    ...mapMutations({
      updatePageNum: 'policies/page',
      updateSearch: 'policies/search',
    }),

    ...mapActions({
      loadData: 'policies/load',
      loadCustomerOptions: 'customer_options/load',
      loadSearchOptions: 'policies/loadSearchOptions',
    }),

    async handlePageShow() {
      const {
        expiringOnly,
        historyOnly,
        loadData,
        updateSearch,
        updatePageNum,
        loadCustomerOptions,
        loadSearchOptions,
      } = this;
      if (expiringOnly) {
        await updateSearch({
          status_code: ['05'],
          expire_within_days: EXPIRING_DAYS,
        });
      } else if (historyOnly) {
        await updateSearch({
          status_code: ['05'],
          expire_within_days: 0,
        });
      } else {
        await updateSearch({
          status_code: [],
        });
      }
      updatePageNum(1);
      loadData();
      loadCustomerOptions();
      loadSearchOptions();
    },

    showVehicleSelectionDialog() {
      const $vm = this;
      $vm.showVehicleSelection = true;
    },

    closeVehicleSelectionDialog() {
      const $vm = this;
      $vm.showVehicleSelection = false;
    },

    async gotoCreatePolicy(vehicleId) {
      const $vm = this;
      $vm.showVehicleSelection = false;

      // Forward policy data to step 1
      $vm.pageStack.push({
        extends: QuoteFormStep1,
        data() {
          return {
            loadVehicleId: vehicleId,
          };
        },
      });
    },

    async handleGotoPage(pageNum) {
      const { loadData, updatePageNum } = this;
      updatePageNum(pageNum);
      loadData();
    },

    async handleSearch(search) {
      const { loadData, updatePageNum, updateSearch } = this;
      await updatePageNum(1);
      updateSearch(
        Object.assign({}, search, {
          status_code: [search.status_code],
        })
      );
      loadData();
    },

    async gotoViewTransaction(trx) {
      const { pageStack } = this;

      const statusConf = POLICY_STATUS_LIST.find(
        psl => psl.code === trx.status_code
      );
      if (['payment', 'payment-success'].indexOf(statusConf.viewPage) > -1) {
        pageStack.push({
          extends: Payment,
          data() {
            return {
              policyId: trx.id,
            };
          },
        });
        //} else if (statusConf.viewPage === 'payment-success') {
        //  pageStack.push({
        //    extends: PaymentSuccess,
        //    data: () => ({
        //      policyId: trx.id,
        //    }),
        //  });
      } else {
        pageStack.push({
          extends: QuoteFormStep1,
          data() {
            return { loadPolicyId: trx.id };
          },
        });
      }
    },

    async gotoRenewTransaction(trx) {
      this.pageStack.push({
        extends: QuoteFormStep1,
        data() {
          return { renewPolicyId: trx.id };
        },
      });
    },
  },
  computed: {
    selectedVehicle: state => state.vehicle.vehicle,
    selectedCustomer: state => state.vehicle.customer,
    currentPage: {
      get() {
        return this.page;
      },
      set(pageNum) {
        const { updatePageNum } = this;
        updatePageNum(pageNum);
      },
    },
    ...mapState({
      loading: state => state.policies.loading.length > 0,
      totalPage: state =>
        Math.ceil(state.policies.totalItems / state.policies.perPage),
      page: state => state.policies.page,
      transactions: state => state.policies.data,
      errors: state => state.policies.errors,
      searchTerms: state => state.policies.search,
      searchOptions: state => state.policies.searchOptions,
    }),
  },
};
</script>
