<template>
  <div class="gp-transaction-item">
    <div class="container--flex">
      <div class="gp-transaction-item__section-details">
        <div class="gp-transaction-item__text-small">
          {{ transaction.reference_no ? transaction.reference_no : 'N/A' }}
        </div>
        <div class="gp-transaction-item__text-small">
          {{ `Date: ${formattedCreatedAt}` }}
        </div>
        <div class="gp-transaction-item__text">
          <strong>{{ transaction.vehicle_info.registration_no }}</strong>
        </div>
        <div class="gp-transaction-item__text">
          {{ transaction.policy_holder_info.name }}
        </div>
        <div class="gp-transaction-item__text-small">
          {{ `${transaction.cover_from} - ${transaction.cover_to}` }}
        </div>
        <div
          class="gp-transaction-item__status"
          :class="statusVariant.statusClassName"
        >
          {{ statusVariant.label }}
        </div>
      </div>

      <div class="gp-transaction-item__vendor-img align-self--center">
        <img :src="vendorLogo" alt />
      </div>

      <div
        class="
          gp-list__item-ctas
          gp-list__item-section
          container--flex
          gp-list__item-ctas--flex-column
          gp-transaction-item__section-buttons
          justify--evenly
        "
      >
        <button
          @click="$emit('view', transaction)"
          class="button-gp--pill button-gp--pill__blue"
        >
          View
        </button>
        <button
          v-if="isRenewable"
          @click="$emit('renew', transaction)"
          class="button-gp--pill button-gp--pill__orange"
        >
          Renew
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { POLICY_STATUS_LIST, TIMB_LOGO, EXPIRING_DAYS } from '@/lib/constants';

export default {
  props: ['transaction'],
  data() {
    return {
      vendorLogo: TIMB_LOGO,
      isRenewable: false,
    };
  },
  mounted() {
    this.updateIsRenewable();
  },
  watch: {
    transaction() {
      this.updateIsRenewable();
    },
  },
  methods: {
    updateIsRenewable() {
      const daysValid = moment(this.transaction.cover_to).diff(
        moment(),
        'days'
      );
      this.isRenewable = daysValid <= EXPIRING_DAYS;
    },
  },
  computed: {
    formattedCreatedAt() {
      const _formatedCreatedAt = moment(this.transaction.createdAt).format(
        'DD-MM-YYYY'
      );

      return _formatedCreatedAt;
    },
    statusVariant() {
      const daysValid = moment(this.transaction.cover_to).diff(
        moment(),
        'days'
      );
      if (daysValid <= 0) {
        return {
          label: 'expired',
          statusClassName: 'color-red-100',
        };
      }
      if (daysValid <= EXPIRING_DAYS) {
        return {
          label: `expires in ${daysValid} days`,
          statusClassName: 'color-red-100',
        };
      }
      return POLICY_STATUS_LIST.find(
        status => status.code === this.transaction.status_code
      );
    },
  },
};
</script>
