/**
 * Custom validators for vuelidate with error message
 * @author: Skynorth Digital, LLP
 * @since: 0.8.0
 * */
import moment from 'moment';

export const minDrivingYears = {
  validator: value => value >= 1,
  message:
    'Minimum driving experience has to be at least 1 year (put 1 if you have less than 1 year driving experience)',
};

export const minDrivingAge = {
  validator: value => value >= 15,
  message: 'Invalid driving age, minimum driving age in Malaysia is 15',
};

export const validYear = {
  validator: value => moment(value, 'YYYY', true).isValid(),
  message: 'Invalid year',
};

export const minAtCurrentYear = {
  validator: value =>
    moment(value + '-01-01', 'YYYY-MM-DD').diff(moment(), 'year') >= 0,
  message: 'Year entered has to be later than current year',
};

export const maxAtCurrentYear = {
  validator: value => Number(value) - Number(moment().format('YYYY')) <= 0,
  message: 'Year entered has to be earlier that current year',
};

export const validIcNo = {
  validator: value => {
    const newIcRegex = /^(([[1-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})$/;
    const oldIcRegex = /^[0-9]{7}$/;
    return value
      ? !!value.match(newIcRegex) || !!value.match(oldIcRegex)
      : false;
  },
  message: 'Invalid NRIC',
};

export default {
  minDrivingYears,
  minDrivingAge,
  validYear,
  minAtCurrentYear,
  maxAtCurrentYear,
  validIcNo,
};
