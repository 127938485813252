<template>
  <div>
    <ons-form-group :validator="$v.driver.name" label="Driver Name">
      <div class="gp-select__container">
        <v-ons-input
          v-model="$v.driver.name.$model"
          float
          class="gp-input"
          modifier="gp"
        ></v-ons-input>
      </div>
    </ons-form-group>

    <ons-form-group :validator="$v.driver.idNo" label="NRIC No.">
      <div class="gp-select__container">
        <v-ons-input
          v-model="$v.driver.idNo.$model"
          float
          class="gp-input"
          modifier="gp"
        ></v-ons-input>
      </div>
    </ons-form-group>

    <ons-form-group :validator="$v.driver.relationship" label="Relationship">
      <div class="gp-select__container">
        <v-ons-select v-model="$v.driver.relationship.$model" modifier="gp">
          <option
            v-for="v in relationshipList"
            :value="v.shortcode"
            :key="v.shortcode"
          >
            {{ v.description }}
          </option>
        </v-ons-select>
      </div>
    </ons-form-group>

    <ons-form-group :validator="$v.driver.age" label="Age">
      <div class="gp-select__container">
        <v-ons-input
          type="number"
          v-model="$v.driver.age.$model"
          float
          class="gp-input"
          modifier="gp"
        ></v-ons-input>
      </div>
    </ons-form-group>

    <ons-form-group
      :validator="$v.driver.drivingYears"
      label="Driving Experience (Years)"
    >
      <div class="gp-select__container">
        <v-ons-input
          type="number"
          v-model="$v.driver.drivingYears.$model"
          float
          class="gp-input"
          modifier="gp"
        ></v-ons-input>
      </div>
    </ons-form-group>

    <ons-form-group :validator="$v.driver.occupation" label="Occupation">
      <div class="gp-select__container">
        <v-ons-select v-model="$v.driver.occupation.$model" modifier="gp">
          <option
            v-for="v in occupationList"
            :value="v.shortcode"
            :key="v.shortcode"
          >
            {{ v.description }}
          </option>
        </v-ons-select>
      </div>
    </ons-form-group>

    <div class="button-container">
      <v-ons-button
        modifier="gp"
        @click="handleSave"
        :disabled="$v.driver.$invalid"
      >
        Save
      </v-ons-button>
    </div>
  </div>
</template>

<script>
//import _ from 'lodash';
import { required, integer } from 'vuelidate/lib/validators';
import { minDrivingAge, minDrivingYears } from '@/validators';
import { mapState } from 'vuex';

export default {
  props: {
    defaultValues: {
      default() {
        return {
          name: '',
          idNo: '',
          relationship: '',
          age: 0,
          drivingYears: 0,
          occupation: '',
        };
      },
    },
  },
  data: () => ({
    driver: {
      name: '',
      idNo: '',
      relationship: '',
      age: 0,
      drivingYears: 0,
      occupation: '',
    },
  }),
  mounted() {
    this.$v.driver.$touch();
  },
  validations() {
    return {
      driver: {
        name: { required },
        idNo: { required },
        relationship: { required },
        age: {
          required,
          integer,
          minDrivingAge: minDrivingAge.validator,
        },
        drivingYears: {
          required,
          integer,
          minDrivingYears: minDrivingYears.validator,
        },
        occupation: { required },
      },
    };
  },
  watch: {
    defaultValues() {
      // Always sync default values whenever props.defaultValues is changed
      this.driver = this.defaultValues;
    },
  },
  methods: {
    handleSave() {
      this.$emit('save', this.driver);
    },
  },
  computed: mapState({
    occupationList: state => state.shortcodes.categories.occupation,
    relationshipList: state => state.shortcodes.categories.relationship,
  }),
};
</script>
