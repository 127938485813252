import { render, staticRenderFns } from "./QuoteFormStep2.vue?vue&type=template&id=2d88a4be&scoped=true&"
import script from "./QuoteFormStep2.vue?vue&type=script&lang=js&"
export * from "./QuoteFormStep2.vue?vue&type=script&lang=js&"
import style0 from "./QuoteFormStep2.vue?vue&type=style&index=0&id=2d88a4be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d88a4be",
  null
  
)

export default component.exports