<template>
  <div>
    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label"
          >Name / NRIC / Passport / Company No.</label
        >
        <v-ons-input
          float
          v-model="searchTerms.all"
          class="gp-input"
          modifier="gp"
          placeholder="NRIC No"
        ></v-ons-input>
      </div>
    </v-ons-list-item>

    <v-ons-list-item modifier="gp">
      <div class="center">
        <label class="gp-input__label">Status</label>
        <div class="gp-select__container">
          <v-ons-select v-model="searchTerms.status_code" modifier="gp">
            <option
              v-for="status in statusOptions"
              :value="status.value"
              :key="status.value"
              >{{ status.text }}</option
            >
          </v-ons-select>
        </div>
      </div>
    </v-ons-list-item>

    <div class="button-container">
      <v-ons-button @click="$emit('search', searchTerms)" modifier="gp">
        Search
      </v-ons-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchTerms: {
      type: Object,
      default: () => ({
        all: '',
        status_code: 'A',
      }),
    },
  },
  data() {
    return {
      statusOptions: [
        { text: 'Active', value: 'A' },
        { text: 'Inactive', value: 'X' },
      ],
    };
  },
};
</script>
