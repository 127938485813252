import axios from '@/lib/axios';
import { API_BASE_URL } from '@/lib/constants';

async function submitPayment(method, refNo) {
  return await axios.gpAPI({
    method: 'PUT',
    baseURL: API_BASE_URL,
    url: `/payments/${method}/${refNo}`,
  });
}

async function getCreditBalance(refNo) {
  return await axios.gpAPI({
    method: 'GET',
    baseURL: API_BASE_URL,
    url: `/payments/credit_balance/${refNo}`,
  });
}

export default {
  submitPayment,
  getCreditBalance,
};
